import AppAlert from "./App.Alert";
import "./App.css";
import AppRouter from "./router";
import { Provider } from "react-redux";
import store from "./redux";
//import AppMeeting from "./App.meeting"
import AssembleReg from "./context/assembleReg";
import PatientDetailsContext from "./context/consultant";
import AppAuth from "./App.auth";
import Appstatecontext from "./context/appstatecontext";
import AppDialog from "./App.dialog";
import AppBackdrop from "./App.backdrop";
import AppTheme from "./App.theme";
import AppMeeting from "./App.meeting";
import AppErrorboundaries from "./App.Errorboundaries";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Appdrawer } from "./App.drawer";
function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AppErrorboundaries>
          <AppTheme>
            <AppAuth>
              <AppAlert>
                <AppBackdrop>
                  <Appdrawer>

                    <AppAlert>
                      <AppBackdrop>
                        <AppDialog>
                          <AppMeeting>
                            <AssembleReg>
                              <PatientDetailsContext>
                                <Appstatecontext>
                                  <AppRouter />
                                </Appstatecontext>
                              </PatientDetailsContext>
                            </AssembleReg>
                          </AppMeeting>
                        </AppDialog>
                      </AppBackdrop>
                    </AppAlert>
                  </Appdrawer>
                </AppBackdrop>
              </AppAlert>
            </AppAuth>
          </AppTheme>
        </AppErrorboundaries>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
