/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import { Row, Col, Text, Div } from "qdm-component-library";
import LabOrder from "./labOrderFlow/labOrder";
// import { Grid } from "@material-ui/core";
import { Dialysis } from "./dialysis/dialysis";
import ProgressNotes from "./progressNotes";
import TreatmentPlan from "./treatmentPlan/treatmentPlan";
import NursingProcedure from "./nursingProcedure/nursingProcedure";
import VitalSigns from "./vitalSigns";
import Allergy from "./allergyFlows/allergy";
import Diagnosis from "./diagnosisFlow/diagnosis";
import CurrentMedication from "../../../src/components/currentMedication";
import PackageOrder from "./packageOrder/packageOrder";
import ReferalRegOut from "./referalRegOut/referalRegOut";
import ChiefComplaint from "./cheifComplaintsFlow/cheifComplaints";
import Radiology from "./radiologyFlow/radiology";
import MedicationOrderFrom from "./medicationFlows/medicationOrderFrom";
import DoccumentationList from "./documentation/index";
import {Grid} from "@mui/material";
import { FnExecutorBasedOnFLow, WhichFlow } from "../../utils";
import jwtDecode from "jwt-decode";

export const ActionItems = (props) => {
  let flow= FnExecutorBasedOnFLow({
          AppoitmentFlowFn: () => {
         return false
          },
          DialysisFlowFn: async () => {
         return true
          },
        });
let whichUser = WhichFlow();
console.log(whichUser,"whichUser");

const userInfo = localStorage?.getItem("UserInfo");
const jwtToken = localStorage?.getItem("JwtToken");
const decodedUserinfo =
  userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
const _facility_ = decodedUserinfo?.facilityid ?? "";
const practitionerid = decodedUserinfo?.practitionerid ?? "";
const roleid = decodedUserinfo?.roleid ?? "";
const rolename = decodedUserinfo?.rolename?.toLowerCase() ?? "";
console.log(rolename,"rolename");

  return (
    <Row inLineStyles={{ paddingLeft: "20px 20px 20px 20px" }}>
      {!props.showTitle && (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ padding: "12px" }}
        >
          <Grid container style={{ gap: "10px" }}>
            <Grid>
              <Text inLineStyles={{ fontWeight: 600, fontSize: "18px" }}>
                ACTION ITEMS
              </Text>
            </Grid>
            {whichUser === "pc" ? (
              rolename === "nurse" ? (
                <>
                  <VitalSigns
                    Encounterdata={props?.patientgetData}
                    onRegistration={false}
                  />
                  <Allergy
                    saveAllergy={props?.saveAllergy}
                    onEditChart={props?.onEditChart}
                    handleClose={"props.handleClose"}
                    Encounterdata={props?.patientgetData}
                    onRegistration={false}
                  />
                  <ChiefComplaint
                    saveCheifComp={props?.saveCheifComp}
                    onEditChart={props?.onEditChart}
                    handleClose={"props.handleClose"}
                    Encounterdata={props?.patientgetData}
                    onRegistration={false}
                  />
                  <CurrentMedication
                    isEdit={props?.isEdit}
                    oneditClick={props?.oneditClick}
                    handleSave={props?.handleSave}
                    currentcancel={props?.currentcancel}
                    handlecurrentmedicationval={
                      props?.handlecurrentmedicationval
                    }
                    currentMedicationValue={props?.currentMedicationValue}
                    allNotes={props?.allNotes}
                    encData={props?.editEncData}
                    patientgetData={props.patientgetData}
                  />
                  <PackageOrder
                    savePackageOrder={props?.savePackageOrder}
                    onEditChart={props?.onEditChart}
                    patientgetData={props.patientgetData}
                    encData={props?.editEncData}
                  />
                  <NursingProcedure
                    saveNursingProcedure={props?.saveNursingProcedure}
                    onEditChart={props?.onEditChart}
                    patientgetData={props.patientgetData}
                    encData={props?.editEncData}
                  />
                  <DoccumentationList
                    parent_id={"patient_details"}
                    {...props}
                    onCancel={props?.onCancel}
                    saveDoccumentation={props?.saveDoccumentation}
                    patientgetData={props?.patientgetData2}
                    patientID={props?.patientID}
                    encounterID={props?.encounterID}
                    info={props?.info}
                    pID={props?.pID}
                    fetchUser={props?.fetchUser}
                    editData={props?.editData}
                    personDetails={props?.personDetails}
                  />

                  <Dialysis
                    dialysisData={props?.dialysisData}
                    patientgetData={props.patientgetData}
                    getDialysisData={props?.getDialysisData}
                    orgdetails={props?.orgdetails}
                    saveTreatment={props?.saveTreatment}
                    treatmentPlan={props?.treatmentplan}
                    setTreatmentplan={props?.setTreatmentplan}
                    location={props?.locationdata}
                    encData={props?.editEncData}
                  />
                  <ProgressNotes
                    patientgetData={props.patientgetData}
                    progressNotesData={props?.progressNotesData}
                    encData={props?.editEncData}
                  />
                </>
              ) : (
                <>
                  <Diagnosis
                    parent_id={"patientchart"}
                    saveDiagnosis={props?.saveDiagnosis}
                    onEditChart={props?.onEditDiagnosis}
                    handleClose={"props.handleClose"}
                    Encounterdata={props?.patientgetData}
                    onRegistration={false}
                  />
                  <CurrentMedication
                    isEdit={props?.isEdit}
                    oneditClick={props?.oneditClick}
                    handleSave={props?.handleSave}
                    currentcancel={props?.currentcancel}
                    handlecurrentmedicationval={
                      props?.handlecurrentmedicationval
                    }
                    currentMedicationValue={props?.currentMedicationValue}
                    allNotes={props?.allNotes}
                    encData={props?.editEncData}
                    patientgetData={props.patientgetData}
                  />
                  <LabOrder
                    saveLabOrders={props?.saveLabOrders}
                    onEditChart={props?.onEditChart}
                    patientgetData={props.patientgetData}
                    encData={props?.editEncData}
                  />
                  <MedicationOrderFrom
                    parent_id={"patient_details"}
                    onCancel={props?.onCancel}
                    fetchUser={props?.fetchUser}
                    patientgetData={props?.patientgetData}
                    propsData={props?.propsData}
                    onEditChart={props?.onEditChart}
                    handleClose={props.handleClose}
                    encData={props?.editEncData}
                    encounterID={props?.encounterID}
                    pID={props?.pID}
                  />
                  <Radiology
                    saveRadiology={props?.saveRadiology}
                    onEditChart={props?.onEditChart}
                    handleClose={"props.handleClose"}
                    Encounterdata={props?.patientgetData}
                    onRegistration={false}
                  />
                  <PackageOrder
                    savePackageOrder={props?.savePackageOrder}
                    onEditChart={props?.onEditChart}
                    patientgetData={props.patientgetData}
                    encData={props?.editEncData}
                  />
                  <DoccumentationList
                    parent_id={"patient_details"}
                    {...props}
                    onCancel={props?.onCancel}
                    saveDoccumentation={props?.saveDoccumentation}
                    patientgetData={props?.patientgetData2}
                    patientID={props?.patientID}
                    encounterID={props?.encounterID}
                    info={props?.info}
                    pID={props?.pID}
                    fetchUser={props?.fetchUser}
                    editData={props?.editData}
                    personDetails={props?.personDetails}
                  />
                  <TreatmentPlan
                    dialysisData={props?.dialysisData}
                    patientgetData={props?.patientgetData}
                    getDialysisData={props?.getDialysisData}
                    orgdetails={props?.orgdetails}
                    treatmentplan={props?.treatmentplan}
                    location={props?.locationdata}
                    setTreatmentplan={props?.setTreatmentplan}
                    history={true}
                    encData={props?.editEncData}
                  />
                  <ProgressNotes
                    patientgetData={props.patientgetData}
                    progressNotesData={props?.progressNotesData}
                    encData={props?.editEncData}
                  />
                  <ReferalRegOut
                    dialysisData={props?.dialysisData}
                    patientgetData={props?.patientgetData}
                    getDialysisData={props?.getDialysisData}
                    orgdetails={props?.orgdetails}
                    treatmentplan={props?.treatmentplan}
                    location={props?.locationdata}
                    setTreatmentplan={props?.setTreatmentplan}
                    history={true}
                    referralId={props?.referralId}
                    encData={props?.editEncData}
                  />
                </>
              )
            ) : (
              <Grid container style={{ gap: "10px" }}>
                <ReferalRegOut
                  dialysisData={props?.dialysisData}
                  patientgetData={props?.patientgetData}
                  getDialysisData={props?.getDialysisData}
                  orgdetails={props?.orgdetails}
                  treatmentplan={props?.treatmentplan}
                  location={props?.locationdata}
                  setTreatmentplan={props?.setTreatmentplan}
                  history={true}
                  referralId={props?.referralId}
                  encData={props?.editEncData}
                />
                <TreatmentPlan
                  dialysisData={props?.dialysisData}
                  patientgetData={props?.patientgetData}
                  getDialysisData={props?.getDialysisData}
                  orgdetails={props?.orgdetails}
                  treatmentplan={props?.treatmentplan}
                  location={props?.locationdata}
                  setTreatmentplan={props?.setTreatmentplan}
                  history={true}
                  encData={props?.editEncData}
                />
                <CurrentMedication
                  isEdit={props?.isEdit}
                  oneditClick={props?.oneditClick}
                  handleSave={props?.handleSave}
                  currentcancel={props?.currentcancel}
                  handlecurrentmedicationval={props?.handlecurrentmedicationval}
                  currentMedicationValue={props?.currentMedicationValue}
                  allNotes={props?.allNotes}
                  encData={props?.editEncData}
                  patientgetData={props.patientgetData}
                />
                <ProgressNotes
                  patientgetData={props.patientgetData}
                  progressNotesData={props?.progressNotesData}
                  encData={props?.editEncData}
                />
                <Dialysis
                  dialysisData={props?.dialysisData}
                  patientgetData={props.patientgetData}
                  getDialysisData={props?.getDialysisData}
                  orgdetails={props?.orgdetails}
                  saveTreatment={props?.saveTreatment}
                  treatmentPlan={props?.treatmentplan}
                  setTreatmentplan={props?.setTreatmentplan}
                  location={props?.locationdata}
                  encData={props?.editEncData}
                />
                <LabOrder
                  saveLabOrders={props?.saveLabOrders}
                  onEditChart={props?.onEditChart}
                  patientgetData={props.patientgetData}
                  encData={props?.editEncData}
                />
                <NursingProcedure
                  saveNursingProcedure={props?.saveNursingProcedure}
                  onEditChart={props?.onEditChart}
                  patientgetData={props.patientgetData}
                  encData={props?.editEncData}
                />
                <PackageOrder
                  savePackageOrder={props?.savePackageOrder}
                  onEditChart={props?.onEditChart}
                  patientgetData={props.patientgetData}
                  encData={props?.editEncData}
                />
                <VitalSigns
                  Encounterdata={props?.patientgetData}
                  onRegistration={false}
                />
                <Allergy
                  saveAllergy={props?.saveAllergy}
                  onEditChart={props?.onEditChart}
                  handleClose={"props.handleClose"}
                  Encounterdata={props?.patientgetData}
                  onRegistration={false}
                />
                <Diagnosis
                  parent_id={"patientchart"}
                  saveDiagnosis={props?.saveDiagnosis}
                  onEditChart={props?.onEditDiagnosis}
                  handleClose={"props.handleClose"}
                  Encounterdata={props?.patientgetData}
                  onRegistration={false}
                />
              </Grid>
            )}
          </Grid>
        </Col>
      )}
    </Row>
  );
};
