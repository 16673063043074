export const keyClockLogin = `${process.env.REACT_APP_AUTH_URL_PROTOCAL}://${process.env.REACT_APP_AUTH_URL_HOST}:${process.env.REACT_APP_AUTH_URL_PORT}`;
//export const baseURL = `${process.env.REACT_APP_BASE_URL_PROTOCAL}://${process.env.REACT_APP_BASE_URL_HOST}:${process.env.REACT_APP_BASE_URL_PORT}`;
export const baseURL = `${process.env.REACT_APP_BASE_URL_PROTOCAL}://${process.env.REACT_APP_BASE_URL_HOST}`;
export const FDBURL = `${process.env.REACT_APP_AUTH_URL_PROTOCAL}://${process.env.REACT_APP_FDB_URL}`;
export const CLMMURL = `${process.env.REACT_APP_AUTH_URL_PROTOCAL}://${process.env.REACT_APP_CLMM_URL}`;
export const IDMURL = `${process.env.REACT_APP_TOKEN_URL}`;
export const REGISTRATION_URL = `${process.env.REACT_APP_REGISTRATION_URL}`;
export const appointmentURL = process.env.REACT_APP_APPOINTMENT_SERVICE;
export const cpoeURL = process.env.REACT_APP_CPOE_BASE_URL;
export const jasperURL = process.env.REACT_APP_JASPER_REPORT_URL;
export const reactAppQueueList = process.env.REACT_APP_QUEUE_LIST;
export const commonApiURL = process.env.REACT_APP_AIQOE_COMMON_API_URL;
export const billingURL = process.env.REACT_APP_BILLING_SERVICE_URL;
export const panelreport = process.env.REACT_APP_REPORTS_URL;
export const dialysisUserRegistrationURL=process.env.REACT_APP_DIALYSIS_USER_REGISTRATION_URL
export const dialysisUserSignInURL=process.env.REACT_APP_DIALYSIS_SIGN_IN_URL

export const urls = {
  readQdmQueries: `${baseURL}/api/read_qdmqueries`,
  readDocuments: `${baseURL}/api/read_documents`,
  signIn: `${keyClockLogin}/api/v1/orgusersignin`,
  tokenGeneration: `${IDMURL}/IDMJWTTokencreation`,
  idm_login_keycloackuser: `${IDMURL}/login_keycloackuser`,
  practitionerLogin: `${IDMURL}/PractitionerLogin`,
  upsertDocument: `${baseURL}/api/upsert_document`,
  saveVisit: `${REGISTRATION_URL}/api/SaveVisit`,
  savePatient: `${REGISTRATION_URL}/api/SavePatient`,
  saveFianance: `${REGISTRATION_URL}/api/SaveFinanceRegistration`,
  appointment_search_symptom_speciality_doctor: `${appointmentURL}/api/globalSearchForSpecialityDoctor`,
  appointment_search_hospital: `${appointmentURL}/api/globalSearchForFacility`,
  bookAppointment: `${appointmentURL}/api/bookAppointment`,
  multiBookApptmntRecurrence: `${appointmentURL}/api/previewSchedule`,
  insertOrder: `${cpoeURL}/insertOrder`,
  deleteOrder: `${cpoeURL}/deleteOrder`,
  cpoeSaveDialysis: `${cpoeURL}/saveDialysisVitals`,
  drugDuplicationAlert: `${FDBURL}/api/drugDuplicationAlert`,
  clinicalScreening: `${FDBURL}/api/clinicalScreening`,
  orderStartDate: `${CLMMURL}/api/ScheduleGeneration/GetOrderStartDate`,
  insertMedicationOrder: `${cpoeURL}/insertMedicationOrder`,
  deleteMedicationOrder: `${cpoeURL}/deleteMedicationOrder`,
  jasperReport: `${jasperURL}/api/generateReport`,
  followUpInDoctorSignOff: `${appointmentURL}/api/signoffapi`,
  saveDiagnosis: `${cpoeURL}/saveDiagnosis`,
  delete_document: `${baseURL}/api/delete_document`,
  saveChiefComplaints: `${cpoeURL}/saveChiefComplaints`,
  saveDoccs: `${cpoeURL}/saveClinicalDocuments`,
  saveLabResult: `${cpoeURL}/saveOrderResult`,
  queueTemplate: process.env.REACT_APP_QUEUE_TEMPLATE,
  saveClinicalNotes: `${cpoeURL}/saveClinicalNotes`,
  saveReferralRegistration: `${REGISTRATION_URL}/api/saveReferral`,
  saveTreatmentPlan: `${cpoeURL}/saveTreatmentPlan`,
  nurseAdminstration: `${cpoeURL}/nurseAdminstration`,
  saveOrderSample: `${cpoeURL}/saveOrderSample`,
  transactionDelete: `${commonApiURL}/transactionDelete`,
  getOpenlData: `${commonApiURL}/getOpenlData`,
  setOpenlData: `${commonApiURL}/setOpenlData`,
  patientAdvanceSave: `${billingURL}/patientAdvanceSave`,
  saveOrderStateInfo: `${cpoeURL}/saveOrderStateInfo`,
  referalRegistrationOut:`${REGISTRATION_URL}/api/saveReferralOut`,
  cancelReceipt: `${billingURL}/cancelReceipt`,
  advanceRefund: `${billingURL}/advanceRefund`,
  savePayments:`${billingURL}/savePayments`,
  patientAdvanceSave:`${billingURL}/patientAdvanceSave`,
  insertPaymentDetails:`${billingURL}/insertPaymentDetails`,
  saveBill:`${billingURL}/saveBill`,
  loadBillDetails:`${billingURL}/loadBillDetails`,
  panelreports:`${panelreport}/api/patientLoadPanel`,
  getReportQuery:`${panelreport}/api//getReportQuery`,
  saveBulkBooking: `${appointmentURL}/api/bulkBooking`,
  patientLoadMonthlyAttendance: `${panelreport}/api/patientLoadMonthlyAttendance`,
  getScreenDefaultValue:process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
  helpPdfReadURL:process.env.REACT_APP_HELP_PDF_READ_URL,
  cancelBill: `${billingURL}/cancelBill`,
  getScreenDefaultValue: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
  saveCpgEmr: `${cpoeURL}/saveEMRDetails`,
  preAss_signIN:`${dialysisUserSignInURL}/signupUserLogin`,
  preAss_signUp:`${dialysisUserRegistrationURL}/api/saveSignupUser `
};
