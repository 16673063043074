import React from 'react'
import { FnExecutorBasedOnFLow } from '../../utils';
import Dashboard from '../dashboard';
import Queue from '../Queue/queue';

function Dashboards() {
  return (
    <>
       {FnExecutorBasedOnFLow({AppoitmentFlowFn:()=>{
           return <Dashboard/>
          },DialysisFlowFn:()=>{
            return <Queue/>
          }})}
    </>
  )
}

export default Dashboards
