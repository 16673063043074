import React, { useEffect, useState } from "react";
import {
  Div,
  Card,
  Text,
  Modal,
  Divider,
  Row,
  Icons,
  H6,
  Col,
  Image,
} from "qdm-component-library";
import { RequestorDetails, OrderStatus } from "../../../components";
import { Popover, makeStyles, Button, Grid, CardContent, Tooltip, IconButton, Typography, Dialog } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FnExecutorBasedOnFLow, getOrderName, makeName, utcTOLocal } from "../../../utils";
import { UIColor } from "../../../themes/theme";
import icons8trash from "../../../assets/img/svg/icons8-trash_.svg";
import icons8edit from "../../../assets/img/svg/icons8-edit_.svg"
import TreatmentPlanForm from "../treatmentPlan/treatmentPlanForm";
import { treatmentPlan } from "../vitalsJson";
import treatmentChart from "../../../assets/img/svg/treatmentChart.svg"
import preDialysis from "../../../assets/img/svg/dialysis.svg"
import baseline from "../../../assets/img/svg/baseline.svg"
import hour1 from "../../../assets/img/svg/blue hour 1.svg"
import hour2 from "../../../assets/img/svg/blue hour 3.svg"
import hour3 from "../../../assets/img/svg/blue hour 2.svg"
import postDialysis from "../../../assets/img/svg/postdialysisgrey.svg"
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import printIcon from "../../../assets/img/svg/print-icon.svg"




const statusColors = {
  "On-Hold": { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  Draft: { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  Discontinue: { color: "#d60000", background: UIColor.lineBorderFill.color },
  Continue: {
    color: UIColor.primaryColor.color,
    background: UIColor.primaryColor.color,
  },
  Active: { color: UIColor.success.color, background: "#fff" },
  Hold: { color: "#5B12BB", background: "#E3D7F3" },
  Complete: { color: UIColor.success.color, background: "hsl(105, 62%, 90%)" },
  Cancel: { color: UIColor.error.color, background: "#FFE1E0" },
  // "Cancel": { color: UIColor.error.color, background: "#FFE1E0" }
};
const useStyles = makeStyles({
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
});
function jsonconverter(data = []) {
  function removeSpaces(str = "") {
    return str.replace(/\s/g, "");
  }
  let result = {};
  data.map((v) => {
    result[removeSpaces(v?.vitalscode)] = {
      obsinternalid: v?._id,
      obscode: removeSpaces(v?.vitalscode),
      obsvalue: "",
      shortdesc: v?.shortdesc,
      isabnormal: true,
      abnormalmsg: '"Min"',
      type: v?.type,
      obsuom: v?.units_allowed?.[0]?._id,
      time: Date.now(),
      dropdownvalues: v?.dropdownvalues,
      groupname: v?.groupname,
      mandatory: v?.mandatory,
      validation_max: v?.VitalsCodesReferenceRanges?.[0]?.validation_max,
      validation_min: v?.VitalsCodesReferenceRanges?.[0]?.validation_min,
      default_unit: v?.default_unit,
    };
  });
  return result;
}

export const TreatmentProcedureList = (props) => {  
  const styles = {
    textColor: {
      fontFamily: "pc_regular",
      fontSize: 12,
      paddingLeft: 6,
      color: UIColor.tertiaryText.color,
    },
    textColor1: {
      fontFamily: "pc_regular",
      fontSize: 12,
      color: UIColor.tertiaryText.color,
    },
    userInfo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      marginRight: "16px",
    },
    editAllergyIcon: {
      padding: "6px 10px",
      background: UIColor.greyBackground.color,
      borderRadius: "50px",
      cursor: "pointer",
    },
    userIcon: {
      width: 25,
      height: 25,
      borderRadius: 50,
      backgroundColor: "#E2EEFB",
      marginRight: 15,
      padding: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalCard: {
      width: 600,
      padding: 20,
    },
    modalHeading: {
      fontSize: 16,
      color: UIColor.primaryText.color,

      marginBottom: 10,
    },
    modalSubTitle: {
      fontSize: 14,
      color: "#242424",

      marginBottom: 20,
    },
    outlinedButton: {
      borderColor: "#DEDEDE",
      color: UIColor.primaryText.color,
      backgroundColor: "#fff",
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_regular !important",
      display: "block",
    },
    outlinedDelButton: {
      borderColor: UIColor.error.color,
      color: UIColor.error.color,
      backgroundColor: "#fff",
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_regular !important",
      display: "block",
      whiteSpace:"nowrap",
    },
    containedButton: {
      backgroundColor: UIColor.error.color,
      borderColor: UIColor.error.color,
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_semibold !important",

      color: "#fff",
    },
    modal: {
      backgroundColor: "#fff",
      boxShadow: "0px 4px 24px #00000029",
      border: `1px solid ${UIColor.lineBorderFill.color}`,
      borderRadius: 8,
      overflow: "hidden",
    },
    dot: {
      width: 5,
      height: 5,
      borderRadius: "50%",
      backgroundColor: "#6B6B6B",
      margin: "0px 2px",
      display: "inline-block",
    },
    wrapper: {
      width: 15,
      height: 15,
      borderRadius: "50%",
      backgroundColor: UIColor.lineBorderFill.color,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 12,
    },
    statustextColor: {
      color: UIColor.success.color,
      fontSize: 12,
      fontWeight: "bold",
      paddingLeft: 6,
    },
  };
  const dispatch = useDispatch();
  const [dailogComp, setDailogComp] = useState(<></>);
  const [treatmentPlanDatas, setTreatmentPlanDatas] = React.useState([]);
  const [openTp, setOpenTp] = React.useState(false);
  const [dialysisData,setDialysisData]=useState([]);
  const classes = useStyles(props);
  var treatmentPlanJSON = jsonconverter(
    treatmentPlan?.results ? treatmentPlan?.results : []
  );
  const treatmentplan = useSelector(
    (state) => state?.patientDetails?.treatmentplanread
  );
  const treatmentPlanData = treatmentplan?.data ?? treatmentPlanDatas??[]
  const encounter = useSelector(
    (state) => state?.chiefCompilenceMastersSlice?.encounter_Details
  );
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  const orderStatus = useSelector(
    (state) => state?.signOffSlice.getRecordStatus
  );
  const isFirstVisit =
    encounter?.data?.type.toLocaleLowerCase() === "first visit";
  const statusVal = orderStatus?.data?.find?.(
    (d) => d?.label === props?.status
  );
  const status = statusVal?.label || "";
  const requestorDetails = {
    name: props.patientgetData?.name ?? "",
    pidNumber: props.patientgetData?.mrn ?? "",
    role: props.patientgetData?.speciality ?? "",
    hospital: props.patientgetData?.facilityName ?? "",
    profile_pic:
      "https://thumbs.dreamstime.com/b/portrait-charming-imposing-kid-private-school-boy-feel-glad-ready-work-worker-want-be-cool-professional-look-hear-wear-blazer-152393107.jpg",
  };
  const [modal, setModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [show, hide] = useState(false);

  const handleModal = (val) => {
    setModal(val);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // let arr = [];
  // for (let i = 0; i < props?.val?.recurrentData?.endsAfter?.endsAfter; i++) {
  //   arr.push(i);
  // }
  const handleCloseTp = () => {
    setOpenTp(false);
  };

  const { parent_id } = props;
  const handleDialogTp = async (json, statekey, edit, editstate) => {
    setOpenTp(true);
    setDailogComp(
      <TreatmentPlanForm
        data={json}
        statekey={statekey}
        handlecancel={handleCloseTp}
        edit={edit}
        editstate={editstate}
        // isErr={isErr}
        // errorField={errorField}
        // setErrorFields={setErrorFields}
        practid={props?.patientgetData?.practitioner_details?.[0]?.resouceid}
        setOpen={setOpenTp}
        // setTreatmentPlanData={setTreatmentPlanData}
        treatmentPlanData={treatmentPlanData ?? props?.setTreatmentplan}
        patientid={props?.location?.state?.patientId ??props?.patientId ?? props?.location?.state?.pId}
        encounterid={props?.treatmentPlan?.encounterid ?? props?.location?.state?.encounter_id}
        ids={props}
        ticketId = {props?.location?.state?.ticketId}
        setTreatmentplan={props?.setTreatmentplan}
        setTreatmentPlanDatas={setTreatmentPlanDatas}
      />
    );
  };

  let flow= FnExecutorBasedOnFLow({
    AppoitmentFlowFn: () => {
   return false
    },
    DialysisFlowFn: async () => {
   return true
    },
  });
  return (
    <>
      <Row
        id={`${parent_id}_list_parent_div`}
        // className={!props.signOff && "allergyListHover"}
        
      >
        <Col
          id={`${parent_id}_list_parent_col`}
          xs={12}
          sm={12}
          md={8}
          lg={3}
          xl={3}
        >
          {props?.treatment_order && (
            <H6
              id={`${parent_id}_list_title_h6`}
              inLineStyles={{
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                color: UIColor.secondaryButtonColor.color,
              }}
            >
              <Text
                id={`${parent_id}_list_title_text`}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Div
                  id={`${parent_id}_list_${props?.treatment_order.replaceAll(
                    " ",
                    "_"
                  )}_div`}
                  inLineStyles={{
                    color: UIColor.secondaryButtonColor.color,
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "pc_regular",
                  }}
                >
                  {props?.treatment_order}
                  {/* {props?.recurrentData?.endsAfter?.endsAfter > 0 ? ( */}
                  {props?.data?.childRecurrent?.length > 0 && (
                    <span
                      id={`${parent_id}_list_icons_span`}
                      // onClick={() => hide(!show)}
                    >
                      <Icons
                        id={`${parent_id}_list_icons`}
                        fontIcon="exchange"
                        style={{ marginLeft: 5, marginRight: 5 }}
                      />
                      {props?.data?.childRecurrent?.length ?? 0}
                      {/* {props?.recurrentData?.endsAfter?.endsAfter} */}
                    </span>
                  )}
                  {/* ) : null} */}
                </Div>
              </Text>
            </H6>
          )}
          {props?.priority && (
            <Text
              id={`${parent_id}_list_${props?.priority?.replaceAll(
                " ",
                "_"
              )}_text`}
              inLineStyles={styles.textColor1}
            >
              {props?.priority}
            </Text>
          )}
          {props?.nature && (
            <Text
              id={`${parent_id}_list_${props?.nature?.replaceAll(
                " ",
                "_"
              )}_text`}
              inLineStyles={styles.textColor}
            >
              {" - "}
              {props?.nature}
            </Text>
          )}
          {props?.dateTime && (
            <Text
              id={`${parent_id}_list_date-time_text`}
              inLineStyles={styles.textColor}
            >
              {" -  On "}
              {props?.dateTime}
            </Text>
          )}
          {status && (
            <Text
              id={`${parent_id}_list_${props?.status}_text`}
              onClick={(e) => {
                if (props?.val?.statushistory?.length > 1) {
                  props.handleOpenStatus &&
                    props.handleOpenStatus(
                      e,
                      props.val,
                      props.IndexValue,
                      "treatment",
                      statusVal
                    );
                }
              }}
              inLineStyles={{
                ...styles.statusColor,
                color: statusColors[status]?.color,
                cursor:
                  props?.val?.statushistory?.length >= 1 ? "pointer" : "unset",
              }}
            >
              {" •"} {status}
            </Text>
          )}
          {/* {
            <Text inLineStyles={{ color: "#9b9b9b", margin: "0px 5px" }}>
              {" | "}
            </Text>
          } */}
          {/* {<Text inLineStyles={styles.textColor}>{"ID 385322"}</Text>}
          {<Text inLineStyles={styles.textColor}>{" - 24 May 12.30"}</Text>} */}
          {/* {<Text inLineStyles={styles.textColor}>{" - Order Pending "}</Text>} */}
        </Col>
          <Col
            id={`${parent_id}_list_on_col`}
            xs={12}
            sm={12}
            md={4}
            lg={6}
            xl={6}
          >
          {flow &&(
           <Grid item style={{marginLeft:"10px"}} container spacing={1} >
          <Grid item>
          <Tooltip title="Treatment Chart">
          <IconButton style={props?.dialysisData?.component?.[0]?.treatmentChart?.[0] ? { backgroundColor: '#EAECFF' } : {}} onClick={()=>props?.handleDialog(props?.treatmentChartJSON,"treatmentChart",props?.dialysisData?.component?.[0]?.treatmentChart?.[0] ? true: false,props?.dialysisData?.component?.[0]?.treatmentChart?.[0],"",props?.orderline,props?._id,props?.dialysisData,props?.historyView)}color={props?.dialysisData?.component?.[0]?.treatmentChart?.[0]? "primary":""} >
          <img src={treatmentChart} style={{width:"24px",height:"24px"}} alt="Treatment Chart"/>
            </IconButton>
          </Tooltip>
          </Grid>
      
          <Grid item>
          <Tooltip title="Pre Dialysis">
            <IconButton style={props?.dialysisData?.component?.[0]?.predialysis?.[0] ? { backgroundColor: '#EAECFF' } : {}} onClick={()=>props?.handleDialog(props?.predialysisJSON,"predialysis",props?.dialysisData?.component?.[0]?.predialysis?.[0] ? true: false,props?.dialysisData?.component?.[0]?.predialysis?.[0],"",props?.orderline,props?._id,props?.dialysisData,props?.historyView)}color={props?.dialysisData?.component?.[0]?.predialysis?.[0] ? "primary":""} disabled={!props?.dialysisData?.component?.[0]?.treatmentChart?.[0]} >
            <img src={preDialysis} style={{width:"24px",height:"24px"}} alt ="Predialysis"/>
            </IconButton>
          </Tooltip>{" "}
          </Grid>
          
          <Grid item>
          <Tooltip title="Baseline">
            <IconButton style={props?.dialysisData?.component?.[0]?.intradialysis?.[0] ? { backgroundColor: '#EAECFF' } : {}} onClick={()=>props?.handleDialog(props?.intradialysisJSON,"intradialysis",props?.dialysisData?.component?.[0]?.intradialysis?.[0] ? true: false,props?.dialysisData?.component?.[0]?.intradialysis?.[0],"Baseline",props?.orderline,props?._id,props?.dialysisData,props?.historyView)} color={props?.dialysisData?.component?.[0]?.intradialysis?.[0] ? "primary":""}disabled={!props?.dialysisData?.component?.[0]?.predialysis?.[0]}>
            <img src={baseline} style={{width:"24px",height:"24px"}} alt="Baseline"/>
            </IconButton>
          </Tooltip>
          </Grid>
          <Grid item>
          <Tooltip title="1st Hour">
            <IconButton style={props?.dialysisData?.component?.[0]?.intradialysis?.[1] ? { backgroundColor: '#EAECFF' } : {}} onClick={()=>props?.handleDialog(props?.intradialysisJSON,"intradialysis",props?.dialysisData?.component?.[0]?.intradialysis?.[1] ? true: false,props?.dialysisData?.component?.[0]?.intradialysis?.[1],"1st Hour",props?.orderline,props?._id,props?.dialysisData,props?.historyView)} disabled={!props?.dialysisData?.component?.[0]?.intradialysis?.[0]} >
              <img src={hour1} style={{width:"24px",height:"24px"}} alt="1st Hour"/>
            </IconButton>
            </Tooltip>
         
          </Grid>
          <Grid item>
          <Tooltip title="2nd Hour">
            <IconButton  style={props?.dialysisData?.component?.[0]?.intradialysis?.[2] ? { backgroundColor: '#EAECFF' } : {}} onClick={()=>props?.handleDialog(props?.intradialysisJSON,"intradialysis",props?.dialysisData?.component?.[0]?.intradialysis?.[2] ? true: false,props?.dialysisData?.component?.[0]?.intradialysis?.[2],"2nd Hour",props?.orderline,props?._id,props?.dialysisData,props?.historyView)} disabled={!props?.dialysisData?.component?.[0]?.intradialysis?.[1]}>
            <img src={hour2} style={{width:"24px",height:"24px"}} alt="2nd Hour"/>
            </IconButton>
          </Tooltip>{" "}
          </Grid>
          <Grid item>
          <Tooltip title="3rd Hour">
            <IconButton style={props?.dialysisData?.component?.[0]?.intradialysis?.[3] ? { backgroundColor: '#EAECFF' } : {}} onClick={()=>props?.handleDialog(props?.intradialysisJSON,"intradialysis",props?.dialysisData?.component?.[0]?.intradialysis?.[3] ? true: false,props?.dialysisData?.component?.[0]?.intradialysis?.[3],"3rd Hour",props?.orderline,props?._id,props?.dialysisData,props?.historyView)} disabled={!props?.dialysisData?.component?.[0]?.intradialysis?.[2]}>
            <img src={hour3} style={{width:"24px",height:"24px"}} alt="3rd Hour"/>
            </IconButton>
          </Tooltip>{" "}
         
          </Grid>
          <Grid item>
          <Tooltip title="Post Dialysis">
            <IconButton style={props?.dialysisData?.component?.[0]?.postdialysis?.[0] ? { backgroundColor: '#EAECFF' } : {}} onClick={()=>props?.handleDialog(props?.postdialysisJSON,"postdialysis",props?.dialysisData?.component?.[0]?.postdialysis?.[0] ? true: false,props?.dialysisData?.component?.[0]?.postdialysis?.[0],"",props?.orderline,props?._id,props?.dialysisData,props?.historyView)} color={props?.dialysisData?.component?.[0]?.postdialysis?.[0] ? "primary":""}disabled={!props?.dialysisData?.component?.[0]?.intradialysis?.[2]}>
            <img src={postDialysis} style={{width:"24px",height:"24px"}}  alt="Post Dialysis"/>
            </IconButton>
          </Tooltip>
         
          </Grid>
        {/* <Grid item>
        <Tooltip title="Print Pdf">
          <IconButton  onClick={()=>props?.printChart()}>
          <img src={printIcon} style={{width:"24px",height:"24px"}}  alt="Post Dialysis"/>
          </IconButton>
        </Tooltip>
       
        </Grid> */}
        </Grid>
  )}
          </Col>
          <Col
          id={`${parent_id}_list_on_col`}
          xs={12}
          sm={12}
          md={4}
          lg={3}
          xl={3}
        >
        {props?.historyView ? (<>   <div
          id={`${parent_id}_list_on_sub_div`}
          style={styles.userInfo}
         
        >
          <Text
            id={`${parent_id}_list_${props?.val?.createdAt}_text`}
            inLineStyles={styles.textColor}
            style={{ marginBottom: 8 }}
          >
            {"On "}
            {props?.val?.createdAt
              ? utcTOLocal(props?.val?.createdAt, "DD MMM, YYYY")
              : props.getData()}
          </Text>
          <Text
            id={`${parent_id}_list_${props?.val?.userName?.replaceAll(
              " ",
              "_"
            )}_text`}
            inLineStyles={styles.textColor}
          >
            {getOrderName(
              props?.val,
              loggedUserInfo?.data?.completeName || ""
            ) ??
              props?.val?.accessionNum ??
              props?.val?.userName ??
              (props?.val?.requestorDetails?.practitionerId
                ? makeName(
                    props?.val?.requestorDetails?.practitionerId || {}
                  ) + " (You)"
                : props?.val?.userName + " (You)")}
          </Text>
          {/* {((props.signOff) && (props?.data?.childRecurrent?.length > 0)) && <Text
            // aria-describedby={id}
            onClick={() => hide(!show)}
          // inLineStyles={
          //   open
          //     ? styles.userIcon
          //     : { ...styles.userIcon, backgroundColor: "transparent" }
          // }
          >
            {show ? <div style={{
              borderRadius: '50%',
              border: '1px solid #dadada',
              width: '30px',
              height: '30px',
              padding: '2px',
              marginLeft: '12px',
              cursor: "pointer"
            }}><ExpandLessIcon /></div> : <div style={{
              borderRadius: '50%',
              border: '1px solid #dadada',
              width: '30px',
              height: '30px',
              padding: '2px',
              marginLeft: '12px',
              cursor: "pointer"
            }}><ExpandMoreIcon /></div>}
          </Text>} */}
        </div></>):(<><Div
          id={`${parent_id}_list_on_div`}
          // className={!props.signOff && "userInfoEdit"}
          className={"userInfoEdit"}
          style={{ right: open ? 10 : props?.treatment_order === "HD Treatment"  ? -160 : -208 }}
        >
          <div
            id={`${parent_id}_list_on_sub_div`}
            style={styles.userInfo}
            className={"userHide"}
          >
            <Text
              id={`${parent_id}_list_${props?.val?.createdAt}_text`}
              inLineStyles={styles.textColor}
              style={{ marginBottom: 8 }}
            >
              {"On "}
              {props?.val?.createdAt
                ? utcTOLocal(props?.val?.createdAt, "DD MMM, YYYY")
                : props.getData()}
            </Text>
            <Text
              id={`${parent_id}_list_${props?.val?.userName?.replaceAll(
                " ",
                "_"
              )}_text`}
              inLineStyles={styles.textColor}
            >
              {getOrderName(
                props?.val,
                loggedUserInfo?.data?.completeName || ""
              ) ??
                props?.val?.accessionNum ??
                props?.val?.userName ??
                (props?.val?.requestorDetails?.practitionerId
                  ? makeName(
                      props?.val?.requestorDetails?.practitionerId || {}
                    ) + " (You)"
                  : props?.val?.userName + " (You)")}
            </Text>
            {/* {((props.signOff) && (props?.data?.childRecurrent?.length > 0)) && <Text
              // aria-describedby={id}
              onClick={() => hide(!show)}
            // inLineStyles={
            //   open
            //     ? styles.userIcon
            //     : { ...styles.userIcon, backgroundColor: "transparent" }
            // }
            >
              {show ? <div style={{
                borderRadius: '50%',
                border: '1px solid #dadada',
                width: '30px',
                height: '30px',
                padding: '2px',
                marginLeft: '12px',
                cursor: "pointer"
              }}><ExpandLessIcon /></div> : <div style={{
                borderRadius: '50%',
                border: '1px solid #dadada',
                width: '30px',
                height: '30px',
                padding: '2px',
                marginLeft: '12px',
                cursor: "pointer"
              }}><ExpandMoreIcon /></div>}
            </Text>} */}
          </div>

          {!props.signOff && props?.data?.childRecurrent?.length > 0 && (
            <Text
              // aria-describedby={id}
              onClick={() => hide(!show)}
              // inLineStyles={
              //   open
              //     ? styles.userIcon
              //     : { ...styles.userIcon, backgroundColor: "transparent" }
              // }
            >
              {show ? (
                <div
                  style={{
                    borderRadius: "50%",
                    border: "1px solid #dadada",
                    width: "30px",
                    height: "30px",
                    padding: "2px",
                    marginLeft: "12px",
                    cursor: "pointer",
                  }}
                >
                  <ExpandLessIcon />
                </div>
              ) : (
                <div
                  style={{
                    borderRadius: "50%",
                    border: "1px solid #dadada",
                    width: "30px",
                    height: "30px",
                    padding: "2px",
                    marginLeft: "12px",
                    cursor: "pointer",
                  }}
                >
                  <ExpandMoreIcon />
                </div>
              )}
            </Text>
          )}
        

          {props.signOff && props?.data?.childRecurrent?.length > 0 && (
            <Text
              // aria-describedby={id}
              onClick={() => hide(!show)}
              // inLineStyles={
              //   open
              //     ? styles.userIcon
              //     : { ...styles.userIcon, backgroundColor: "transparent" }
              // }
            >
              {show ? (
                <div
                  style={{
                    borderRadius: "50%",
                    border: "1px solid #dadada",
                    width: "30px",
                    height: "30px",
                    padding: "2px",
                    marginLeft: "12px",
                    cursor: "pointer",
                  }}
                >
                  <ExpandLessIcon />
                </div>
              ) : (
                <div
                  style={{
                    borderRadius: "50%",
                    border: "1px solid #dadada",
                    width: "30px",
                    height: "30px",
                    padding: "2px",
                    marginLeft: "12px",
                    cursor: "pointer",
                  }}
                >
                  <ExpandMoreIcon />
                </div>
              )}
            </Text>
          )}

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            
             {(!props.signOff && props?.treatment_order !== "HD Treatment") ? (
              <Text
                id={`${parent_id}_list_delete_text`}
                inLineStyles={{ ...styles.editAllergyIcon, marginLeft: 10 }}
                onClick={() => handleModal(true)}
              >
                {/* <Icons
              id={`${parent_id}_list_delete_icons`}
              fontIcon="trash" style={{ color: UIColor.error.color }} /> */}
                <Image
                  src={icons8trash}
                  alt="delete_icon"
                  // style={{ width: 100, height: 35 }}
                />
              </Text>
            ):null} 

            {!props.signOff && !props?.data?.childRecurrent?.length > 0 && (
              <Text
                id={`${parent_id}_list_edit_text`}
                inLineStyles={{ ...styles.editAllergyIcon, marginLeft: 10 }}
                onClick={() => {
                  props?.editFun(props.IndexValue, null, null, props.data);
                  props?.onEditChart(
                    props?.data?.[0],
                    "treatment_procedure_form"
                  );
                }}
              >
                {/* <Icons
                id={`${parent_id}_list_edit_icons`}
                fontIcon={"pencil"}
                onClick={() => {
                  props.editFun(props.IndexValue, null, null, props.data);
                  props.onEditChart(props?.data?.[0], 'treatment_procedure_form');
                }}
              /> */}
                <Image
                  src={icons8edit}
                  alt="edit_icon"
                  // style={{ width: 100, height: 35 }}
                />
              </Text>
            )}
             {!props.signOff && !props?.data?.childRecurrent?.length > 0 && (
             
        <Text
                id={`${parent_id}_list_edit_text`}
                inLineStyles={{ ...styles.editAllergyIcon, marginLeft: 10 }}
                onClick={()=>props?.printChart()}
              >
                {/* <Icons
                id={`${parent_id}_list_edit_icons`}
                fontIcon={"pencil"}
                onClick={() => {
                  props.editFun(props.IndexValue, null, null, props.data);
                  props.onEditChart(props?.data?.[0], 'treatment_procedure_form');
                }}
              /> */}
                <Image
                  src={printIcon}
                  alt="edit_icon"
                  // style={{ width: 100, height: 35 }}
                />
              </Text>
            )}
             
          </div>

          <div
            id={`${parent_id}_list_delete_edit_div`}
            style={{ display: "flex", alignItems: "center", marginLeft: 64 }}
          >
            {/* <Text
              // aria-describedby={id}
              onClick={() => hide(!show)}
              // inLineStyles={
              //   open
              //     ? styles.userIcon
              //     : { ...styles.userIcon, backgroundColor: "transparent" }
              // }
            >
              {show ? "▾" : "▴"}
            </Text> */}
            {/* {!props.signOff && (
              <>
                <Div
                  id={`${parent_id}_list_delete_div`}
                  style={{
                    height: 20,
                    width: 1,
                    backgroundColor: "rgb(224, 224, 224)",
                  }}
                />
                <Text
                  id={`${parent_id}_list_delete_text`}
                  inLineStyles={{ ...styles.editAllergyIcon, marginLeft: 10 }}
                  onClick={() => handleModal(true)}
                >
                  <Icons
                    id={`${parent_id}_list_delete_icons`}
                    fontIcon="trash" style={{ color: UIColor.error.color }} />
                </Text>
                <Text
                  id={`${parent_id}_list_edit_text`}
                  inLineStyles={{ ...styles.editAllergyIcon, marginLeft: 5 }}
                  onClick={() => {
                    props.editFun(props.IndexValue, null, null, props.data);
                    // props.editFun(props.IndexValue, null, props.data.editData, props.data);
                    props.onEditChart(props?.val, "treatment_procedure_form");
                  }}
                // onClick={() => handleEdit()}
                >
                  <Icons
                    id={`${parent_id}_list_edit_icons`}
                    fontIcon={"pencil"} />
                </Text>
              </>
            )} */}
          </div>
          <Modal
            id={`${parent_id}_list_modal`}
            open={modal}
            onClose={() => handleModal(false)}
            width={600}
            inLineStyles={styles.modal}
          >
            <Card
              id={`${parent_id}_list_parent_card`}
              style={styles.modalCard}
            >
              <H6
                id={`${parent_id}_list_remove_this_h6`}
                className="pc_semibold"
                style={styles.modalHeading}
              >
                Are you sure want to Delete ?
              </H6>
              <H6
                id={`${parent_id}_list_the_order_h6`}
                className="pc_regular"
                style={styles.modalSubTitle}
              >
                Choose whether you want to Delete an Entire Series ( or ) Current Order
              </H6>
              <Div style={{display:"flex",justifyContent:"flex-end",gap:"10px"}}>
              <Button
                id={`${parent_id}_list_No_button`}
                variant="outlined"
                
                onClick={() => handleModal(false)}
                style={styles.outlinedButton}
              >
                No
              </Button>
              <Button
                id={`${parent_id}_list_Yes_remove_button`}
                variant="contained"
                
                onClick={async () => {
                  handleModal(false);
                  await props.deleteList(props.IndexValue, props?.orderline
                  ,false);
                }}
                style={styles.outlinedDelButton}
              >
                 Current Order
              </Button>
              <Button
                id={`${parent_id}_list_Yes_remove_button`}
                variant="contained"
                
                onClick={async () => {
                  handleModal(false);
                  await props.deleteList(props.IndexValue, props?.orderline,true);
                }}
                style={styles.containedButton}
              >
                 Entire Series
              </Button>
              </Div>
            </Card>
          </Modal>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            classes={{ paper: classes.muiPopovers }}
          >
            <RequestorDetails
              parent_id={"treatment_procedure"}
              requestorDetails={requestorDetails}
            />
          </Popover>
        </Div></>)}
          
        </Col>
      </Row>
      {show && (
        <>
          <Divider
            id={`${parent_id}_list_bottom_divider`}
            style={{ margin: "5px 0px" }}
          />
          <div
            id={`${parent_id}_list_orderstatus_div`}
            style={{
              overflowX: "auto",
              display: "flex",
              width: props.signOff ? "100%" : "65vw",
            }}
          >
            {/* {props?.recurrentData?.every?.value &&
              arr.map((v, i) => {
                return ( */}
            <OrderStatus
              recurrentDatas={props?.recurrentData}
              parent_id={"treatment_orderlist"}
              date={props?.recurrentData?.startsOn?.dateTime}
              // time={"222"}
              data={props.data}
              IndexValue={props.IndexValue}
              editFun={props.editFun}
              onEditChart={props.onEditChart}
              Cedit={props.Cedit}
              Istreatment={true}
              updatefuncy={props.updatefuncy}
              id={"ID 03421"}
              signOff={props.signOff}
              status={"Order Pending"}
              saveTreatment={props?.saveTreatment}
            />
            {/* );
              })} */}
          </div>
     
        </>
      )}
      <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={openTp}
      onClose={()=>setOpenTp(false)}
      aria-labelledby="max-width-dialog-title"
    >
      {dailogComp}
    </Dialog>
    </>
  );
};
