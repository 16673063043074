import { format, fromUnixTime, getUnixTime } from "date-fns";
import {
  getFilteredData,
  getUtcTime,
  makeNameForPract,
} from "../../../../utils";
import moment from "moment";

export const createJson = (state, isFormTypeIntravenous, editData) => {
  let payload = {};
  if (!isFormTypeIntravenous) {
    payload = {
      name: { ...state?.drugCode },
      formCode: state?.drugCode?.FormCode ?? "",
      unitValue: state?.dosageUOM,
      doseMeasurementValue: state?.dosageValue,
      routeCode: state?.route,
      PRNYN: state?.prn,
      prnComment: state?.remarks,
      drugCodeValue: state?.drugCode?.value,
      orderdescriptionName: state?.drugCode?.label,
    };
  } else {
    payload = {
      name: { ...state?.drugCode },
      formCode: state?.drugCode?.FormCode ?? "",
      unitValue: state?.ivVolumeUOM,
      doseMeasurementValue: state?.ivVolumeValue,
      routeCode: state?.route,
      PRNYN: state?.prn,
      prnComment: state?.remarks,
      drugCodeValue: state?.drugCode?.value,
      orderdescriptionName: state?.drugCode?.label,
      // ivInfusionRateValue: state?.infusionRateValue,
      // ivInfusionRateUOM: state?.infusionRateUOM,
      // Additive Type
      addtictive: state?.additiveItems,
    };
  }

  payload = {
    ...payload,
    editId: editData?._id ?? "",
    // status: "",
    statusreason: "",
    statusperiod: getUnixTime(new Date()),
    PrescriptionDate: getUnixTime(new Date()),
    unitsTypes: state?.frequency,
    dosetime: state?.durationValue ? parseInt(state?.durationValue) : 0,
    doseOption: state?.durationUOM,
    occurStartDateTime: state?.startDate
      ? getUnixTime(new Date(state?.startDate))
      : 0,
    occurEndDateTime: state?.endDate
      ? getUnixTime(new Date(state?.endDate))
      : 0,
    priority: state?.priority ?? "",
    requestor: state?.requestorDetail,
    presNature: state?.nature,
    presMode: state?.mode,
    diagnosis: state?.selectDiagnosis?.map((val) => val?.value) ?? [],
    indication: state?.indication,
    notes: state?.notes,
    drug_category: state?.drugCategory,
    dispensing_pharmacy: state?.dispensingPharmacy,
    patientInstruction: state?.patientInstruction,
    patientInstructiondesc: state?.patientInstructionValue,
    SlidingScaleDose: state?.SlidingScaleDose,
    isfirststatorder: state?.isfirststatorder ?? false,

    // order default property
    ivInfusionRateValue: state?.infusionRateValue,
    ivInfusionRateUOM: state?.infusionRateUOM,
    ivInfusionTime: state?.ivInfusionTime,
    ivInfusionTimeUom: state?.ivInfusionTimeUom,
    prescriptionType: state?.prescriptionType?.value ?? "",
  };

  return payload;
};

export const getStatusHistory = (status, EditData, personId, state) => {
  let history =
    EditData?.statushistory?.length > 0
      ? JSON.parse(JSON.stringify(EditData?.statushistory))
      : [];
  history = history?.map((val) => {
    let Pid = val?.PersonID?.[0]?._id;
    val.PersonID = Pid;
    delete val?.period;
    return val;
  });
  let oldStatus = history?.[history?.length - 1]?.new_status ?? "";
  if (status !== oldStatus) {
    history.push({
      PersonID: personId,
      old_status: oldStatus,
      status_reason: state?.statusReason ?? "",
      toperiod: getUtcTime(),
      fromperiod: history?.[history?.length - 1]?.fromperiod ?? 0,
      new_status: status,
    });
  }
  return history;
};

export const getResponseGenerate = (response, options, dateFormat, props) => {
  return response?.map((v) => {
    const requestorData = v?.requestor?.[0] ?? {};
    const response = v ?? {};
    return {
      _id: response?._id ?? "",
      _key: response?._key ?? "",
      // response?.drug_category?.shortdesc
      prescriptionType: {
        value: response?.prescriptionType?._id ?? "",
        label: response?.prescriptionType?.display ?? "",
        id: response?.prescriptionType?.id ?? "",
      },
      requestorDetail: {
        organization: requestorData?.organization?._id
          ? {
              label: requestorData?.organization?.name ?? "",
              value: requestorData?.organization?._id ?? "",
              id: requestorData?.organization?.id ?? "",
            }
          : props?.organization ?? {},

        // response?.requestor?.length > 0
        //   ? options?.Organization_masters?.data?.filter(
        //     (value) =>
        //       value?.value === response?.requestor?.[0].organization
        //   )?.[0]
        //   : null
        practitionerRole: requestorData?.practRole?._id
          ? {
              label: requestorData?.practRole?.display ?? "",
              value: requestorData?.practRole?._id ?? "",
              id: requestorData?.practRole?.id ?? "",
            }
          : props?.practitionerRoleId,
        // : response?.requestor?.length > 0
        //   ? options?.PractitionerRole_masters?.data?.filter(
        //     (value) => value?.value === response?.requestor?.[0].practRole
        //   )?.[0]
        //   : null
        orderBy: requestorData?.practId?.[0]?._id
          ? {
              value: requestorData?.practId?.[0]?._id ?? "",
              label:
                makeNameForPract(requestorData?.practId?.[0].name?.[0]) ?? "",
              id: requestorData?.practId?.[0]?.id ?? "",
            }
          : props?.orderBy,
        // : response?.requestor?.length > 0
        //   ? options?.Practitioner_masters?.data?.filter(
        //     (value) =>
        //       value?.value === response?.requestor?.[0].practId?._id
        //   )?.[0]
        //   : null
        practitionerId:
          requestorData?.practId?.[0]?._id ?? props?.practitionerCode ?? "",
      },
      practitionerCode: requestorData?.practId?.[0]?.practitioner_id ?? null,
      drugType: {
        label: response?.drugType?.shortdesc ?? "",
        value: response?.drugType?._id ?? "",
      },
      isFormTypeIntravenous: response?.drugType?.shortdesc === "IV",

      // IV POPULATION STARTS
      ...(response?.drugType?.shortdesc === "IV"
        ? {
            orderDescription:
              response?.orderdescription ?? response?.drugCode?.LongDesc ?? "",
            route: {
              value: response?.routeCode?._id ?? "",
              label: response?.routeCode?.shortdesc ?? "",
              id: response?.routeCode?.id,
            },
            // route: getFilteredData(
            //   "routeCode",
            //   "DrugRoutes_masters",
            //   response,
            //   options
            // ),
            baseSolution: {
              label:
                response?.orderdescription ??
                response?.drugCode?.LongDesc ??
                "",
              value: response?.drugCode?._id ?? "",
            },
            ivStrength:
              (response?.drugCode?.StrengthValuesPossible?.[0]?.StrengthValue ||
                "") +
              " " +
              (response?.drugCode?.StrengthValuesPossible?.[0]
                ?.StrengthVolumeUoM?.UOM || ""),

            //   NEED TO CLARIFY
            ivVolumeValue: response?.dosageValue,
            ivVolumeUOM: {
              ...response?.dosageUOM,
              label: response?.dosageUOM?.UOM,
              value: response?.dosageUOM?._id ?? "",
            },
            //   NEED TO CLARIFY

            infusionRateValue: response?.ivInfusionRateValue,
            infusionRateUOM: getFilteredData(
              "ivInfusionRateUOM",
              "InfusionRateUoM_masters",
              response,
              options
            ),
            ivInfusionTime: response?.ivInfusionTime,
            ivInfusionTimeUom: getFilteredData(
              "ivInfusionTimeUom",
              "InfusionRateUoM_masters",
              response,
              options
            ),
            totalVolumeValue: response?.dosageValue,
            totalVolumeUOM: {
              ...response?.dosageUOM,
              label: response?.dosageUOM?.UOM,
              value: response?.dosageUOM?._id ?? "",
            },
            additiveItems: response?.addtictive?.map((value) => ({
              ...value,
              addoseuom: {
                label: value?.addoseuom?.UOM ?? "",
                value: value?.addoseuom?._id ?? "",
              },
              addrugcode: {
                label: value?.addrugcode?.ShortDesc ?? "",
                value: value?.addrugcode?._id,
              },
            })),

            drugCode: {
              label:
                response?.orderdescription ??
                response?.drugCode?.LongDesc ??
                "",
              value: response?.drugCode?._id,
              FormData: response?.FormCode || response?.formCode,
              formCode:
                response?.drugCode?.FormCode || response?.drugCode?.formCode,
              FormCode:
                response?.drugCode?.FormCode || response?.drugCode?.formCode,
              drugTypeId: response?.drugType?._id ?? "",
            },
            // IV POPULATION ENDS
          }
        : {
            // Unit Dose POPULATION STARTS
            drugCode: {
              label:
                response?.orderdescription ||
                response?.drugCode?.LongDesc ||
                "",
              value: response?.drugCode?._id ?? "",
              FormData: response?.FormCode || response?.formCode,
              formCode:
                response?.drugCode?.FormCode || response?.drugCode?.formCode,
              FormCode:
                response?.drugCode?.FormCode || response?.drugCode?.formCode,
              drugTypeId: response?.drugType?._id ?? "",
            },
            dosageValue: response?.dosageValue,
            dosageUOM: {
              ...response?.dosageUOM,
              label: response?.dosageUOM?.shortdesc ?? "",
              value: response?.dosageUOM?._id ?? "",
            },
            strengthValue:
              response?.drugCode?.StrengthValuesPossible?.[0]?.StrengthValue,
            strengthUOM: {
              ...response?.drugCode?.StrengthValuesPossible?.[0]
                ?.StrengthVolumeUoM,
              label:
                response?.drugCode?.StrengthValuesPossible?.[0]
                  ?.StrengthVolumeUoM?.shortdesc ?? "",
              value:
                response?.drugCode?.StrengthValuesPossible?.[0]
                  ?.StrengthVolumeUoM?._id ?? "",
            },
            dosageForm: response?.FormCode || response?.formCode,
            route: {
              value: response?.routeCode?._id ?? "",
              label: response?.routeCode?.shortdesc ?? "",
              id: response?.routeCode?.id,
            },
            // route: getFilteredData(
            //   "routeCode",
            //   "DrugRoutes_masters",
            //   response,
            //   options
            // ),
            // Unit Dose POPULATION ENDS
          }),
      //   COMMON POPULATION STARTS
      prn: response?.PRNYN ?? false,
      remarks: response?.prnComment,
      SlidingScaleDose: response?.SlidingScaleDose,
      SlidingScaleDose_prn: response?.SlidingScaleDose ? true : false,
      selectDiagnosis: response?.diagnosis?.map((val) => {
        return { label: val?.diagcode?.icdname, value: val?._id };
      }),
      indication: {
        label: response?.indication?.display,
        value: response?.indication?._id ?? "",
      },
      patientInstruction: {
        value:
          response?.patientInstruction?.[0]?._id ??
          response?.patientInstruction?._id ??
          "",
        label:
          response?.patientInstruction?.[0]?.code ??
          response?.patientInstruction?.code ??
          "",
        display:
          response?.patientInstruction?.[0]?.display ??
          response?.patientInstruction?.display ??
          "",
        id:
          response?.patientInstruction?.[0]?.id ??
          response?.patientInstruction?.id ??
          "",
      },
      patientInstructionValue: response?.patientInstructiondesc ?? "",
      startDate: response?.occurStartDateTime ? moment.unix(response?.occurStartDateTime).format("YYYY-MM-DD") : "",
        // response?.occurStartDateTime
        // ? format(fromUnixTime(response?.occurStartDateTime), dateFormat)
        //   :
        // null,
      endDate: response?.occurEndDateTime ? moment.unix(response?.occurEndDateTime).format("YYYY-MM-DD") : "",
        // response?.occurEndDateTime
        // ? format(fromUnixTime(response?.occurEndDateTime), dateFormat)
        //   :
        // null,
      priority: {
        value: response?.priority?.[0]?._id ?? "",
        masterId: response?.priority?.[0]?._id ?? "",
        label: response?.priority?.[0]?.display ?? "",
        id: response?.priority?.[0]?.id ?? "",
      },
      drugCategory: {
        value: response?.drug_category?._id ?? "",
        label: response?.drug_category?.shortdesc ?? "",
        id: response?.drug_category?.id,
      },
      // drugCategory: getFilteredData(
      //   "drug_category",
      //   "DrugCategory_masters",
      //   response,
      //   options
      // ),
      nature: {
        value: response?.presNature?._id ?? "",
        label:
          response?.presNature?.display ||
          response?.presNature?.coding?.[0]?.display ||
          "",
        id: response?.presNature?.id,
      },
      // nature: getFilteredData(
      //   "presNature",
      //   "OrderNature_masters",
      //   response,
      //   options
      // ),
      mode:
        localStorage.getItem("role_name") &&
        localStorage.getItem("role_name")?.toLowerCase() === "doctor"
          ? options?.OrderMode_masters?.data?.map((v) => {
              if (
                v?._id === "CodingMaster/10301" ||
                v?.value === "CodingMaster/10301"
              ) {
                return {
                  value: v?._id ?? v?.value ?? "",
                  label:
                    v?.display || v?.coding?.[0]?.display || v?.label || "",
                  id: v?.id,
                };
              }
            })?.[0]
          : {
              value: response?.presMode?._id ?? "",
              label:
                response?.presMode?.display ||
                response?.presMode?.coding?.[0]?.display ||
                "",
              id: response?.presMode?.id,
            },
      // mode: getFilteredData(
      //   "presMode",
      //   "OrderMode_masters",
      //   response,
      //   options
      // ),
      dispensingPharmacy: response?.dispensing_pharmacy?._id
        ? {
            label: response?.dispensing_pharmacy?.DispLocCode ?? response?.dispensing_pharmacy?.shortdesc ?? "",
            value: response?.dispensing_pharmacy?._id ?? "",
          }
        : {},
      durationValue: response?.dosageDurationValue ?? "",
      durationUOM: {
        label: response?.dosageDurationUOM?.UOM ?? "",
        value: response?.dosageDurationUOM?._id ?? "",
      },
      frequency: {
        value: response?.dosageFreqCode?._id,
        label: response?.dosageFreqCode?.freqcode ?? "",
        id: response?.dosageFreqCode?.id,

        frequency:
          // response?.dosageFreqCode?.[0]?.frequencytype?.code === "UDE" &&
          response?.FreqDetls?.length > 0
            ? response?.FreqDetls
            : response?.dosageFreqCode?.scheduleline,

        type: response?.dosageFreqCode?.frequencytype?.display ?? "",
        shortdesc: response?.dosageFreqCode?.shortdesc ?? "",
        // value: result?.dosageFreqCode?._id,
      },
      notes: response?.notes,
      //  COMMON POPULATION ENDS
    };
  });
};

export const doseRangeCheck = (data, pid, encount_id) => {
  return {
    patient_Id: pid ?? "",
    drugCode: data?.drugCode?.value ?? "",
    drugrouteid: "10487626",
    dosageValue: data?.dosageValue ?? 0,
    dosageUOM: data?.dosageUOM?.value ?? "",
    dosageFreqCode: data?.frequency?.value ?? "",
    dosageDurationValue: data?.durationValue
      ? JSON.parse(data?.durationValue)
      : 0,
    dosageDurationUOM: data?.durationUOM?.value ?? "",
    encounter_Id: encount_id ?? "",
    routeCode: data?.route?.value ?? "",
    FreqDetls: data?.frequency?.frequency?.map((v) => {
      return {
        remarks: v?.remarks || "",
        dose: v?.dose || "",
        time: v?.time || "",
        baseFreq:
          v?.basefreq?.val ?? v?.basefreq?.freqcode ?? v?.baseFreq ?? "",
        weekDay: v?.weekDay || v?.weekday || "",
        day: v?.day?.code
          ? typeof v?.day?.code === "string"
            ? JSON.parse(v?.day?.code)
            : v?.day?.code
          : 0,
        fromDay: v?.fromDay
          ? typeof v?.fromDay === "string"
            ? JSON.parse(v?.fromDay)
            : v?.fromDay
          : 0,
        toDay: v?.toDay
          ? typeof v?.toDay === "string"
            ? JSON.parse(v?.toDay)
            : v?.toDay
          : 0,
        fromDate: v?.fromDate
          ? typeof v?.fromDate === "string"
            ? JSON.parse(v?.fromDate)
            : v?.fromDate
          : 0,
        toDate: v?.toDate
          ? typeof v?.toDate === "string"
            ? JSON.parse(v?.toDate)
            : v?.toDate
          : 0,
        adminDosageInstruction: v?.adminDosageInstruction ?? "",
      };
    }),
    // "FreqDetls": [
    //   {
    //     "remarks": "8 AM",
    //     "dose": "",
    //     "time": "08:00",
    //     "baseFreq": "",
    //     "weekDay": "",
    //     "day": 0,
    //     "fromDay": 0,
    //     "toDay": 0,
    //     "fromDate": 0,
    //     "toDate": 0,
    //     "adminDosageInstruction": ""
    //   }
    // ]
  };
};
