import React from "react";
import { Div, Button, H6 } from "qdm-component-library";
import { UIColor } from "../../themes/theme";
import { getCalculatedAge, AxiosCall, urls, FnExecutorBasedOnFLow } from "../../utils";
export const Footer = (props) => {
  const styles = {
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      width: "98%",
      position: "fixed",
      bottom: "0px",
      backgroundColor: "transparent",
      padding: "5px 10px",
      //margin: "15px",
      borderRadius: "10px",
      border: `1px solid ${UIColor?.lineBorderFill?.color}`,
    },
    backButton: {
      backgroundColor: "#F0F0F0",
      color: "#323232",
      fontFamily: "pc_medium",
      fontSize: 14,
      borderRadius: 8,
      padding: "3.5px 10px",
      marginLeft:"10px"
    },
    signOffButton: {
      fontSize: 14,
      borderRadius: 8,
      padding: "7px 16px",
      fontFamily: "pc_medium",
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
    },
    eSign: {
      color: "#101010",
      fontSize: 14,
      borderBottom: "2px solid #E0E0E0",
      padding: "0px 12px 7px 12px",
      margin: 0,
      marginRight: 20,
      letterSoacing: 0,
    },
  };
  const { parent_id, isProfileView, printDisable } = props;

  const constructMoniterAndIntradialysis = (moniter, intraDyData) => {
    for (let i = 0; i < moniter.length; i++) {
      let monVal;
      if (moniter[i]?.HourlyMonitoring === "Blood Pressure (BP)") {
        monVal = "BP";
      } else if (moniter[i]?.HourlyMonitoring === "Venous Pressure") {
        monVal = "VP";
      } else if (moniter[i]?.HourlyMonitoring === "Conductivity") {
        monVal = "CONDUCTIVITY";
      } else if (moniter[i]?.HourlyMonitoring === "TMP") {
        monVal = "TEMP";
      } else if (moniter[i]?.HourlyMonitoring === "Pulse") {
        monVal = "PULSE";
      } else if (moniter[i]?.HourlyMonitoring === "Heparin") {
        monVal = "Heparin";
      } else if (moniter[i]?.HourlyMonitoring === "Staff ID") {
        monVal = "StaffID";
      }

      for (let j = 0; j < intraDyData?.length; j++) {
        if (monVal === "id") {
          if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
            moniter[i].Second_test = intraDyData[j]?.[monVal]?.id;
          } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
            moniter[i].Baseline = intraDyData[j]?.[monVal]?.id;
          } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
            moniter[i].First_test = intraDyData[j]?.[monVal]?.id;
          } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
            moniter[i].Third_test = intraDyData[j]?.[monVal]?.id;
          }
        } else {
          if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
            moniter[i].Second_test = intraDyData[j]?.[monVal]?.obsvalue;
          } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
            moniter[i].Baseline = intraDyData[j]?.[monVal]?.obsvalue;
          } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
            moniter[i].First_test = intraDyData[j]?.[monVal]?.obsvalue;
          } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
            moniter[i].Third_test = intraDyData[j]?.[monVal]?.obsvalue;
          }
        }
      }
    }

    return moniter;
  };
  const constructComplication = (compl, intraDyData) => {
    for (let i = 0; i < compl.length; i++) {
      let monVal;
      if (compl[i]?.Complications === "Time") {
        monVal = "Time";
      } else if (compl[i]?.Complications === "Complication") {
        monVal = "COMPLICATION";
      } else if (compl[i]?.Complications === "Action") {
        monVal = "ACTION";
      } 

      for (let j = 0; j < intraDyData?.length; j++) {
        if (monVal === "id") {
          if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
            compl[i].Second_test = intraDyData[j]?.[monVal]?.id;
          } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
            compl[i].Baseline = intraDyData[j]?.[monVal]?.id;
          } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
            compl[i].First_test = intraDyData[j]?.[monVal]?.id;
          } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
            compl[i].Third_test = intraDyData[j]?.[monVal]?.id;
          }
        } else {
          if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
            compl[i].Second_test = intraDyData[j]?.[monVal]?.obsvalue;
          } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
            compl[i].Baseline = intraDyData[j]?.[monVal]?.obsvalue;
          } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
            compl[i].First_test = intraDyData[j]?.[monVal]?.obsvalue;
          } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
            compl[i].Third_test = intraDyData[j]?.[monVal]?.obsvalue;
          }
        }
      }
    }

    return compl;
  };
  const constructNotes = (notes, intraDyData) => {
    for (let i = 0; i < notes.length; i++) {
      let monVal;
      if (notes[i]?.Notes !== "Heparin") {
        if (notes[i]?.Notes === "Nursing Notes") {
          monVal = "NN";
        } else if (notes[i]?.Notes === "Staff ID") {
          monVal = "StaffID";
        }

        for (let j = 0; j < intraDyData?.length; j++) {
          if (monVal === "id") {
            if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
              notes[i].Second_test = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
              notes[i].Baseline = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
              notes[i].First_test = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
              notes[i].Third_test = intraDyData[j]?.[monVal]?.id;
            }
          } else {
            if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
              notes[i].Second_test = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
              notes[i].Baseline = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
              notes[i].First_test = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
              notes[i].Third_test = intraDyData[j]?.[monVal]?.obsvalue;
            }
          }
        }
      }
    }

    return notes;
  };

  const printPDF = async () => {
    let intraDyData = props?.footerData?.intradialysis;

    let moniter = [
      {
        HourlyMonitoring: "Blood Pressure (BP)",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Pulse",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Heparin",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Venous Pressure",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "TMP",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Conductivity",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Staff ID",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },
    ];
    let Intradialysis_hourly_table = [
      {
        HourlyMonitoring: "Blood Pressure (BP)",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Pulse",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Heparin",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Venous Pressure",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "TMP",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Conductivity",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Staff ID",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },
    ];

    let complication = [
      {
        Complications: "Time",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        Complications: "Complication",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        Complications: "Action",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },
    ];

    let notes = [
      {
        Notes: "Nursing Notes",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        Notes: "Staff ID",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },
    ];

    notes = constructNotes(notes, intraDyData);

    complication = constructComplication(complication, intraDyData);

    moniter = constructMoniterAndIntradialysis(moniter, intraDyData);
    Intradialysis_hourly_table = constructMoniterAndIntradialysis(
      Intradialysis_hourly_table,
      intraDyData
    );

    //  moniter.map((m) => {
    //     intraDyData.map((li) =>{

    //     })
    //  })
    let payload = {
      reportid: "6287ae7c-c0be-4ce7-b289-9de54caecb75",

      inputparams: {},

      // result: [
      //   {
      //     Mandatory: {
      //       logo: "13767",
      //       Name: props?.encounterData[0]?.Patient[0]?.name[0]?.fulname,
      //       Age: props?.encounterData[0]?.Patient[0]?.personInfo[0]?.age,//getCalculatedAge(props?.encounterData[0]?.Patient[0]?.personInfo[0]?.age),
      //       Sex: props?.encounterData[0]?.Patient[0]?.Gender?.display,
      //       DialyzerType:
      //         props?.footerData?.treatmentChart?.[0]?.DialyzerType?.obsvalue,
      //       DurationHrs:
      //         props?.footerData?.treatmentChart?.[0]?.["Duration(hrs)"]?.obsvalue,
      //       NoOfReuse: props?.footerData?.treatmentChart?.[0]?.Noofreuse?.obsvalue,
      //       Hmaintenance:
      //         props?.footerData?.treatmentChart?.[0]?.Maintenance?.obsvalue,
      //       DryWeight:
      //         props?.footerData?.treatmentChart?.[0]?.TargetDryWeight?.obsvalue,
      //       UfTarget: props?.footerData?.treatmentChart?.[0]?.TargetUF?.obsvalue,
      //       Dialysate:
      //         props?.footerData?.treatmentChart?.[0]?.["DialysateCa2+"]?.obsvalue,
      //       DurationShift:
      //         props?.footerData?.treatmentChart?.[0]?.["DialysateCa2+"]?.obsvalue,
      //       DurationFreq:
      //         props?.footerData?.treatmentChart?.[0]?.["Frequency(/week)"]
      //           ?.obsvalue,
      //       LastVisitDate:
      //         props?.footerData?.treatmentChart?.[0]?.LastVisitDate?.obsvalue,
      //       DialysisDate:
      //         props?.footerData?.treatmentChart?.[0]?.DialysisDate?.obsvalue,
      //       SurfaceArea:
      //         props?.footerData?.treatmentChart?.[0]?.SurfaceArea?.obsvalue,
      //       HdMachineNo:
      //         props?.footerData?.treatmentChart?.[0]?.HDMachineNo?.obsvalue,
      //       Idwg: props?.footerData?.treatmentChart?.[0]?.["IDWG(PrelastHDi/kg)"]
      //         ?.obsvalue,
      //       PrePostHdWeight:
      //         props?.footerData?.treatmentChart?.[0]?.PrevPostHDWeight?.obsvalue,
      //       PreHdWeight:
      //         props?.footerData?.treatmentChart?.[0]?.PreHDWeight?.obsvalue,
      //       AvfNeedleSize:
      //         props?.footerData?.treatmentChart?.[0]?.AVFneedlesize?.obsvalue,
      //       Hloading: props?.footerData?.treatmentChart?.[0]?.Loading?.obsvalue,
      //       TotalWeightLoss:
      //         props?.footerData?.postdialysis?.[0]?.TWL?.obsvalue,
      //       PostHdWeight: props?.footerData?.postdialysis?.[0]?.PHDW?.obsvalue,
      //       Nursingnote:props?.footerData?.postdialysis?.[0]?.NN?.obsvalue,
      //       Startedby_sn: props?.footerData?.predialysis?.[0]?.SS?.obsvalue,
      //       TotalUf: props?.footerData?.postdialysis?.[0]?.TUF?.obsvalue,
      //       TypeofVascularAccess:
      //         props?.footerData?.predialysis?.[0]?.TVA?.obsvalue,
      //       PatientCondition:
      //         props?.footerData?.predialysis?.[0]?.PCS?.obsvalue,
      //       ConditionOfAccess:
      //         props?.footerData?.predialysis?.[0]?.CCA?.obsvalue,
      //       Temperature: props?.footerData?.postdialysis?.[0]?.TEMP?.obsvalue,
      //       Bp_standing: props?.footerData?.postdialysis?.[0]?.BPStnd?.obsvalue,
      //       Bp_sitting:props?.footerData?.postdialysis?.[0]?.BPSit?.obsvalue,
      //       Pulse_standing:
      //         props?.footerData?.postdialysis?.[0]?.PULSESTND?.obsvalue,
      //       Pulse_sitting:
      //       props?.footerData?.postdialysis?.[0]?.PULSESIT?.obsvalue,
      //       Htype: props?.footerData?.treatmentChart?.[0]?.Type?.obsvalue,
      //       FlowRateQb:
      //         props?.footerData?.treatmentChart?.[0]?.["Qb(ml/min)"]?.obsvalue,
      //       FlowRateQd:
      //         props?.footerData?.treatmentChart?.[0]?.["Qd(ml/min)"]?.obsvalue,
      //       "Kt/v":props?.footerData?.postdialysis?.[0]?.["Kt/V"]?.obsvalue,
      //       endTime:props?.footerData?.postdialysis?.[0]?.EndTime?.obsvalue,
      //       Endingby_sn:props?.footerData?.postdialysis?.[0]?.EndedBySN?.obsvalue,
      //       Timearrived:props?.footerData?.predialysis?.[0]?.["Time(Arrived)"]?.obsvalue,
      //       TimeStart:props?.footerData?.predialysis?.[0]?.["Time(StartHD)"]?.obsvalue,
      //       TreatmentStaffId:props?.footerData?.treatmentChart?.[0]?.StaffID?.obsvalue,
      //       MonitorParameter: moniter,
      //       Intra_Dialysis_Medication: [
      //         {
      //           Description: "IV iron",

      //           Dosage: "",

      //           "Given or Not": "",

      //           "Given By": "",
      //         },

      //         {
      //           Description: "Vaccination",

      //           Dosage: "",

      //           "Given or Not": "",

      //           "Given By": "",
      //         },

      //         {
      //           Description: "EPO",

      //           Dosage: "",

      //           "Given or Not": "",

      //           "Given By": "",
      //         },

      //         {
      //           Description: "Staff ID",

      //           Dosage: "",

      //           "Given or Not": "",

      //           "Given By": "",
      //         },
      //       ],

      //       Intradialysis_hourly_table: Intradialysis_hourly_table,
      //       Complication: complication,
      //       Notes: notes,
      //     },

      //     optional: {},
      //   },
      // ],
      result: [
        {
          Mandatory: {
            logo: "13767",
            Name: props?.encounterData[0]?.Patient[0]?.name[0]?.fulname,
            Age: props?.encounterData[0]?.Patient[0]?.personInfo[0]?.age, //getCalculatedAge(props?.encounterData[0]?.Patient[0]?.personInfo[0]?.age),
            Sex: props?.encounterData[0]?.Patient[0]?.Gender?.display,
            Sponsor: "-",
            NRIC: "-",
            LastVisitDate:
              props?.footerData?.treatmentChart?.[0]?.LastVisitDate?.obsvalue,
            DialysisDate:
              props?.footerData?.treatmentChart?.[0]?.DialysisDate?.obsvalue,
            HdMachineNo:
              props?.footerData?.treatmentChart?.[0]?.HDMachineNo?.obsvalue,
            DryWeight:
              props?.footerData?.treatmentChart?.[0]?.TargetDryWeight?.obsvalue,
            UfTarget:
              props?.footerData?.treatmentChart?.[0]?.TargetUF?.obsvalue,
            PreHdWeight:
              props?.footerData?.treatmentChart?.[0]?.PreHDWeight?.obsvalue,
            PrePostHdWeight:
              props?.footerData?.treatmentChart?.[0]?.PrevPostHDWeight
                ?.obsvalue,
            Idwg: props?.footerData?.treatmentChart?.[0]?.[
              "IDWG"
            ]?.obsvalue,
            DurationShift:
              props?.footerData?.treatmentChart?.[0]?.["DialysateCa2+"]
                ?.obsvalue,
            DurationFreq:
              props?.footerData?.treatmentChart?.[0]?.["Frequency(/week)"]
                ?.obsvalue,
            DurationHrs:
              props?.footerData?.treatmentChart?.[0]?.["Duration(hrs)"]
                ?.obsvalue,
            FlowRateQb:
              props?.footerData?.treatmentChart?.[0]?.["Qb(ml/min)"]?.obsvalue,
            FlowRateQd:
              props?.footerData?.treatmentChart?.[0]?.["Qd(ml/min)"]?.obsvalue,
            AvfNeedleSize:
              props?.footerData?.treatmentChart?.[0]?.AVFneedlesize?.obsvalue,
            DialyzerType:
              props?.footerData?.treatmentChart?.[0]?.DialyzerType?.obsvalue,
            SurfaceArea:
              props?.footerData?.treatmentChart?.[0]?.SurfaceArea?.obsvalue,
            Dialysate:
              props?.footerData?.treatmentChart?.[0]?.["DialysateCa2+"]
                ?.obsvalue,
            NoOfReuse:
              props?.footerData?.treatmentChart?.[0]?.Noofreuse?.obsvalue,
            Htype: props?.footerData?.treatmentChart?.[0]?.Type?.obsvalue,
            Hloading: props?.footerData?.treatmentChart?.[0]?.Loading?.obsvalue,
            Hmaintenance:
              props?.footerData?.treatmentChart?.[0]?.Maintenance?.obsvalue,
            TreatmentStaffId:
              props?.footerData?.treatmentChart?.[0]?.StaffID?.obsvalue,
            HEPType: props?.footerData?.predialysis?.[0]?.Hepatatis?.obsvalue,
            TypeofVascularAccess:
              props?.footerData?.predialysis?.[0]?.TVA?.obsvalue,
            ConditionOfAccess:
              props?.footerData?.predialysis?.[0]?.CCA?.obsvalue,
            PatientCondition:
              props?.footerData?.predialysis?.[0]?.PCS?.obsvalue,
            Timearrived:
              props?.footerData?.predialysis?.[0]?.["Time(Arrived)"]?.obsvalue,
            TimeStart:
              props?.footerData?.predialysis?.[0]?.["Time(StartHD)"]?.obsvalue,
            "pre-temperature":
              props?.footerData?.predialysis?.[0]?.TEMP?.obsvalue,
            bloodPressure: props?.footerData?.predialysis?.[0]?.BP?.obsvalue,
            pulse: props?.footerData?.predialysis?.[0]?.PULSE?.obsvalue,
            Startedby_sn: props?.footerData?.predialysis?.[0]?.SS?.obsvalue,
            Intradialysis_hourly_table: Intradialysis_hourly_table,
            Complication: complication,
            Notes: notes,
            Intra_Dialysis_Medication: [
              {
                Description: "",

                "Given or Not": "",

                "Given By": "",
              },

              {
                Description: "",

                Dosage: "",

                "Given or Not": "",
              },

              {
                Description: "",

                Dosage: "",

                "Given or Not": "",

                "Given By": "",
              },

              {
                Description: "",

                Dosage: "",

                "Given or Not": "",

                "Given By": "",
              },
            ],

            TotalWeightLoss:
              props?.footerData?.postdialysis?.[0]?.TWL?.obsvalue,
            PostHdWeight: props?.footerData?.postdialysis?.[0]?.PHDW?.obsvalue,
            Nursingnote: props?.footerData?.postdialysis?.[0]?.NN?.obsvalue,
            TotalUf: props?.footerData?.postdialysis?.[0]?.TUF?.obsvalue,
            "post-Temperature":
              props?.footerData?.postdialysis?.[0]?.TEMP?.obsvalue,
            Bp_standing: props?.footerData?.postdialysis?.[0]?.BPStnd?.obsvalue,
            Bp_sitting: props?.footerData?.postdialysis?.[0]?.BPSit?.obsvalue,
            Pulse_standing:
              props?.footerData?.postdialysis?.[0]?.PULSESTND?.obsvalue,
            Pulse_sitting:
              props?.footerData?.postdialysis?.[0]?.PULSESIT?.obsvalue,
            "Kt/v": props?.footerData?.postdialysis?.[0]?.["Kt/V"]?.obsvalue,
            endTime: props?.footerData?.postdialysis?.[0]?.EndTime?.obsvalue,
            Endingby_sn:
              props?.footerData?.postdialysis?.[0]?.EndedBySN?.obsvalue,
            MonitorParameter: moniter,
          },

          optional: {},
        },
      ],
    };
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };

    let result = await AxiosCall("post", urls.jasperReport, payload, header);

    window.open(result?.downloadUrl);
  };

  return (
    <div id={`${parent_id}_footer_parent_div`} style={styles.wrapper}>
      <Div id={`${parent_id}_footer_parent_sub_div`} style={styles.flexCenter}>
        {props?.goBackButton && (
          <Div id={`${parent_id}_footer_button_div`} style={{ flex: 1 }}>
            <Button
              id={`${parent_id}_footer_goback_button`}
              onClick={() =>
                props?.goBackButtonAction && props?.goBackButtonAction()
              }
              variant={"text"}
              inLineStyles={styles.backButton}
            >
              {props?.goBackButton}
            </Button>
          </Div>
        )}
         {FnExecutorBasedOnFLow({AppoitmentFlowFn:()=>
         <Div
          id={`${parent_id}_footer_confrim_button_div`}
          style={styles.flexCenter}
        >
          {props?.eSignature && !isProfileView && (
            <H6
              id={`${parent_id}_footer_E-signature_h6`}
              className="pc_regular"
              style={styles.eSign}
            >
              E-signature
            </H6>
          )}
          {props?.encounterData && props?.footerData && !isProfileView && !printDisable && (
            <Button
              id={`${parent_id}_footer_confirm_button`}
              onClick={printPDF}
              inLineStyles={styles.signOffButton}
              style={{ marginRight: "10px" }}
              
            >
              {"Print"}
            </Button>
          )}
          {props?.confirmButton && !isProfileView && (
            <Button
              id={`${parent_id}_footer_confirm_button`}
              onClick={() =>
                props?.confirmButtonAction && props?.confirmButtonAction()
              }
              inLineStyles={styles.signOffButton}
            >
              {props?.confirmButton}
            </Button>
          )}
        </Div>
         })}
      </Div>
    </div>
  );
};

Footer.defaultProps = {
  goBackButton: "Go back",
  goBackButtonAction: null,
  confirmButton: "Confirm",
  confirmButtonAction: null,
  eSignature: true,
};