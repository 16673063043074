/**
 * @author PRAKASH P
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-08-17
 * @desc LAB ORDER COMPONENT
 */
import React, { Suspense, useState } from "react";
import {
  Row,
  Col,
  Text,
  Div,
  Image,
  Icons,
  Divider,
  TextInput,
} from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  TextField,
  Typography,
  Button,
  Tooltip,
  InputAdornment,
  Switch,
  makeStyles,
  Popover,
  Chip,
  Grid,
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import { CalendarIcon, InsightsIcon, Recurrent } from "../../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import {
  getCurrentMinTime,
  getData,
  getUserInfo,
  calculateAgeInDays,
  AlertProps,
  formatDateFromEpoch,
  __tenantid__,
  getUtcTime,
  LabOrder_Json_Generator,
  getReccuranceDates,
  utcTOLocal,
  getTenantId,
  isObjectEmpty,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../../utils";
import { UIColor } from "../../../themes/theme";
import { DialogContext } from "../../../context";
import withAllContexts from "../../../hoc/withAllContexts";
import Tick from "../../../assets/img/icons8-double-tick.svg";
import Addicon from "../../../assets/img/icons8-add.svg";
import Cancel from "../../../assets/img/icons8-multiply.svg";
import AlertsRestriction from "../alertsRestriction";
import CustomDatePicker from "../../../components/customDatePicker";
import { setHours, setMinutes } from "date-fns";
import backIcon from "../../../assets/img/svg/icons8-left.svg";
import AddResults from "./addResults";
import { Pagination } from "@material-ui/lab";
import HistoryAccordion from "../historyAccordion";
import axios from "axios";

import { PackageList } from "./packageList";
import { InfoOutlined } from "@material-ui/icons";
import { useLocation, useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
//import { AlertProps } from "../../../utils";

const useStyles = makeStyles({
  // autocomplete: {
  //   width: 120,
  //   "& input": {
  //     fontFamily: "pc_regular",
  //     fontSize: 14,
  //     color: UIColor.secondaryText.color,
  //     "&::placeholder": {
  //       fontSize: 14,
  //       fontFamily: "pc_regular !important",
  //     },
  //   },
  // },
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
  input: {
    fontFamily: "pc_regular",
    color: UIColor.secondaryText.color,
    fontSize: 14,
    "&::placeholder": {
      fontSize: 14,
      fontFamily: "pc_regular !important",
    },
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  switch: {
    "& .Mui-checked": {
      color: "#fff",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: UIColor.primaryColor.color,
  },
});

const styles = {
  vitalsHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "0px 0px 0px 10px",
  },
  vitalsHead1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "10px 10px 12px 20px",
  },
  vitalsfieldHead: {
    padding: "6px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
  },
  vitailsCard: {
    //border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    //marginBottom: "14px !important",
    backgroundColor: "#fff",
  },
  vitailsCarderr: {
    fontSize: "12px",
    textAlign: "right",
    justifyContent: "right",
    float: "right",
    color: UIColor.error.color,
    marginBottom: "24px !important",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  infoBell: {
    width: "50px",
    display: "flex",
    padding: "12px",
    borderLeft: `1px solid ${UIColor.lineBorderFill.color}`,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
  },
  icons: {
    cursor: "pointer",
  },
  flexFlow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  floatingLable: {
    backgroundImage: `linear-gradient(#fff, ${UIColor.greyBackground.color})`,
    position: "absolute",
    top: "-8px",
    fontSize: 10,
  },
  filledBtn: {
    fontSize: 12,
    margin: 0,
    cursor: "pointer",
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    borderRadius: 8,
    color: "#fff",
    padding: "4px 10px",
  },
  cancelBtn: {
    fontSize: 12,
    margin: 0,
    cursor: "pointer",
    marginRight: "15px",
    padding: "8px",
    borderRadius: 8,
    border: `1px solid ${UIColor.primaryColor.color}`,
    color: UIColor.primaryColor.color,
    padding: "4px 10px",
  },
  eye: {
    cursor: "pointer",
    marginRight: "10px",
  },
  uploadIcon: {
    width: "24px",
    height: "24px",
    border: "1px solid #E0E0E0",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 5px 0px auto",
    cursor: "pointer",
  },
  img: {
    width: "16px",
    height: "16px",
  },
  // flexRow: {
  //   display: "flex",
  //   alignItems: "center",
  // },
  textColor: {
      fontFamily: "pc_regular",
      fontSize: 12,
      paddingLeft: 6,
      color: UIColor.tertiaryText.color,
    },
  textColor1: {
      fontFamily: "pc_regular",
      fontSize: 12,
      color: UIColor.tertiaryText.color,
    },
};

const PackageOrder = (props) => {
  const templocation = useLocation();
  const [searchParams] = useSearchParams();
  const [location,setLocation] = useState({})

  const classes = useStyles();
  const dispatch = useDispatch();
  const info = getUserInfo();
  const [anchorEl, setAnchorEl] = useState(null);
  const [index, setIndex] = useState(null);
  const [alertData, setAlertData] = useState({
    open: false,
    data: null,
  });
  const [nurseHistory, setNurseHistory] = React.useState([]);
  const [list,setList] = React.useState([]);
  const [packageOrderEntry, setpackageOrderEntry] = useState({
    orderCatalog: {},
    priority: {},
    nature: {},
    dateTime: "",
    headerShow: true,
    qty: 1,
    recurrent: false,
    recurrentData: {
      every: {
        value: "",
        periodicity: {},
      },
      startsOn: {
        dateTime: "",
      },
      endsAfter: {
        endsAfter: "",
        endsBy: "",
      },
      preferrence: {
        preferredDays: [],
        preferredWeeks: [],
      }
    },
    orders: [],
    error: false,
  });

  const searchPractioner = useSelector(
    (state) => state?.personSlice?.searchPractitioner
  );
  const storeData = useSelector((state) => state?.LabOrderApiSlice);
  const packageOrderDelLoading = useSelector(
    (state) => state?.LabOrderApiSlice?.orderdelete?.loading
  );
  const savePackageOrderLoading = useSelector(
    (state) => state?.LabOrderApiSlice?.set_packageorder?.loading
  );

    //pagination for history
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update current page when page is changed
  };

  // Assuming treatmentPlanHistory and pageSize are defined somewhere in your component's scope
  const pageSize = 2; // Number of items per page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleItems = nurseHistory.slice(startIndex, endIndex);

  //pagination for Current
  const [currentCurPage, setCurrentCurPage] = useState(1);
  const handleCurrentPageChange = (event, value) => {
    setCurrentCurPage(value); // Update current page when page is changed
  };

  // Assuming treatmentPlanHistory and pageSize are defined somewhere in your component's scope
  const currentPageSize = 5; // Number of items per page
  const startCurrentIndex = (currentCurPage - 1) * currentPageSize;
  const endCurrentIndex = startCurrentIndex + currentPageSize;
  const visibleCurrentItems = list.slice(startCurrentIndex, endCurrentIndex);
  if(visibleCurrentItems.length === 0&& list.length > 4){
    setCurrentCurPage(1)
  }

  const getPackageOrderRead = async () => {
    if(props?.patientgetData?.encounterId){
      let packageorderData = await dispatch(
        actions.LABORDER_READ({
          encounter_id: props?.patientgetData?.encounterId, //?? location?.state?.encounterId ?? location?.state?.encounter_id,
          order_id: "PACK",
          patientid: location?.state?.patientId ?? location?.state?.pId,
          startdate: props?.patientgetData?.encounterStart ? props?.patientgetData?.encounterStart : getUtcTime(moment().startOf("day")),
        })
      );
      let defaultDate = new Date(props?.patientgetData?.encounterStart * 1000);
      setList(packageorderData?.payload?.data)
      setpackageOrderEntry({
          ...packageOrderEntry,
          headerShow: packageorderData?.payload?.data?.length > 0 ? false : true ,
          orderCatalog: {},
          recurrentData:{
            every: {
              value: "",
              periodicity: {},
            },
            startsOn: {
              dateTime: defaultDate,
            },
            endsAfter: {
              endsAfter: "",
              endsBy: "",
            },
            preferrence: {
              preferredDays: [],
              preferredWeeks: [],
            }
          },
          dateTime: defaultDate,
        }) 
    }
  }

  const savePackageOrder = async (
    list,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message: "Adding New Package Order",
      });
      let personDetails = searchPractioner?.data?.[0];
      let search_Practioner = personDetails?.practitioner?.[0];
      let data = LabOrder_Json_Generator(
        list,
        props?.patientgetData,
        personDetails,
        search_Practioner,
      );

      if (data?.length > 0) {
        let dateArr = [];
          if (
            list?.[0]?.orderCatalog?._id &&
            list?.[0]?.dateTime &&
            list?.[0]?.nature?.value &&
            list?.[0]?.priority?.value &&
            list?.[0]?.recurrentData?.every?.value &&
            list?.[0]?.recurrentData?.every?.periodicity?.value &&
            (list?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              list?.[0]?.recurrentData?.endsAfter?.endsBy) &&
            list?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(list?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                    list?.[0]?.recurrentData?.endsAfter?.endsBy,
                    "DD-MM-YYYY"
                  ), // // [by => Date(DD-MM-YYYY), on => number(5)]
              list?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(list?.[0]?.recurrentData?.every?.value || "{}"), // number
              list?.[0]?.recurrentData?.preferrence?.preferredDays || [],
              list?.[0]?.recurrentData?.preferrence?.preferredWeeks || []
            );
          } else {
            dateArr = [];
          }

        const upsertdata = await dispatch(
            actions.SET_PACKAGEORDER_SERVICE({
              list: data,
              dateArr: dateArr?.[0],
              isupdate: false,
              // childIds: childIds,
              deleteArr,
            })
          );
        getPackageOrderRead();
        loadDefaultValues();

        if (upsertdata?.payload?.data?.[0]?.validation_error?.[0]?.Errormsg) {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: upsertdata?.payload.data.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }

      }

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });

  };

  const closeAlertModal = (data) => {
    setAlertData({
      open: false,
      data: null,
    });

    if (data.type === 1) {
      setpackageOrderEntry({
        ...packageOrderEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !packageOrderEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          }
        },
        orders: [],
      });
    } else if (data.type) {
      AddPackageOrder(true, data.type);
    }
  };
  const getNurseHistory = async () => {
    if(props?.patientgetData?.encounterId){
      const response = await dispatch(
        actions.PACKAGE_ORDER_HISTORY({
          patientId: location?.state?.patientId ?? location?.state?.pId,
          encounterId: props?.patientgetData?.encounterId,// ?? props?.location?.state?.encounter_id,
          startdate: getUtcTime(moment().startOf("day")),
        })
      );
      setNurseHistory(response?.payload?.data);
    }
  };
  const loadDefaultValues = async () => {

    let payload = {
      screenid: process.env.REACT_APP_NURSING_PROCEDURE_SCREEN_ID,
      tenantid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
    };

    let defaultValue = await dispatch(
      actions.GET_SCREEN_DEFAULT_VALUE(payload)
    );

    if (
      defaultValue?.payload?.data?.response?.screenId ===
      process.env.REACT_APP_NURSING_PROCEDURE_SCREEN_ID
    ) {
      defaultValue = defaultValue?.payload?.data?.response?.defaultValues;
      let obj = {};
      defaultValue.forEach((v) => {
        obj[v?.stateName] = v?.value;
      });
      let epochDate = obj?.dateTime * 1000;
      let defaultDate = props?.patientgetData?.encounterStart ? new Date(props?.patientgetData?.encounterStart * 1000) : new Date(epochDate);
      setpackageOrderEntry({
          ...packageOrderEntry,
          orderCatalog: {},
          priority: {
            value: obj?.priority?._id,
            label: obj?.priority?.value,
          },
          nature: {
            value: obj?.nature?._id,
            label: obj?.nature?.value,
          },
          recurrentData:{
            every: {
              value: "",
              periodicity: {},
            },
            startsOn: {
              dateTime: defaultDate,
            },
            endsAfter: {
              endsAfter: "",
              endsBy: "",
            },
            preferrence: {
              preferredDays: [],
              preferredWeeks: [],
            }
          },
          dateTime: defaultDate,
          error: false,
          qty:1   //default value for qty
        });
    }
  };
  React.useEffect(() => {
    loadDefaultValues();
    dispatch(actions.GET_PACKAGEORDER());
  }, []);
  
  React.useEffect(() => {
    if(!(isObjectEmpty(location?.state || {}))){
      getNurseHistory();
      getPackageOrderRead();
    }
  }, [location?.state?.encounterId,location?.state?.patientId , location?.state?.pId,props?.patientgetData]);
  React.useEffect(()=>{
    if(searchParams.get("data")){
      setLocation({state:JSON.parse(decodeURIComponent(searchParams.get("data")))})
    }
    else{
      setLocation(templocation)
    }
  },[])

  const handleInputChange = (e, name) => {
    if (name === "dateTime") {
      let Data = {
        every: {
          value: "",
          periodicity: {},
        },
        startsOn: {
          dateTime: e,
        },
        endsAfter: {
          endsAfter: "",
          endsBy: "",
        },
      };
      const hours = e.getHours().toString().padStart(2, '0');
      const minutes = e.getMinutes().toString().padStart(2, '0');
      const seconds = e.getSeconds().toString().padStart(2, '0');
      const timePart = `${hours}:${minutes}:${seconds}`;
  
      if (timePart === "00:00:00" || timePart === "0:0:0") {
        const currentDateTime = new Date();
        const currentHours = currentDateTime.getHours().toString().padStart(2, '0');
        const currentMinutes = currentDateTime.getMinutes().toString().padStart(2, '0');
        const currentSeconds = currentDateTime.getSeconds().toString().padStart(2, '0');
        const currentTime = `${currentHours}:${currentMinutes}:${currentSeconds}`;
        
        setpackageOrderEntry({
          ...packageOrderEntry,
          [name]: props?.patientgetData?.encounterStart ? new Date(props?.patientgetData?.encounterStart * 1000) : new Date(e.toDateString() + " " + currentTime),
          recurrentData: Data,
          error: false,
        });
      } else {
        setpackageOrderEntry({
          ...packageOrderEntry,
          [name]: e ? e : new Date(props?.patientgetData?.encounterStart * 1000),
          recurrentData: Data,
          error: false,
        });
      }
      // setIndex(null);
      // handleClose();
    } else if (name === "qty") {
      // if (Number(e) > 999 || Number(e) < 1) {
      //   return;
      // }\
      
      if (Number(e) > 999 ) {
        return;
      }
      if(Number(e) <= 0)
      {
        setpackageOrderEntry({
          ...packageOrderEntry,
          [name]: "",
          error: false,
        });
        setIndex(null);
        setAnchorEl(null);
      }
      else{
        setpackageOrderEntry({
          ...packageOrderEntry,
          [name]: e,
          error: false,
        });
        setIndex(null);
        setAnchorEl(null);
      }
      
    } else {
      setpackageOrderEntry({
        ...packageOrderEntry,
        [name]: e,
        error: false,
      });
      setIndex(null);
      setAnchorEl(null);
    }
  };

  const checkDuplicateOrder = () => {
    let arr = [];
    if (list.length > 0) {
      list.map((v) => {
        arr.push(v?.orderCatalog?.value);
      });
    }
    if (arr.length > 0) {
      return arr.includes(packageOrderEntry?.orderCatalog?.label);
    } else {
      return false;
    }
  };

  const AddPackageOrder = async (ruleValidated, ruleType) => {
    if (packageOrderEntry.orderCatalog.label &&
      packageOrderEntry.dateTime &&
      packageOrderEntry.nature.label &&
      packageOrderEntry.priority.label &&
      packageOrderEntry.qty &&
      (!packageOrderEntry.recurrent ||
        (packageOrderEntry.recurrentData?.every?.value &&
          packageOrderEntry.recurrentData?.every?.periodicity?.value &&
          (packageOrderEntry.recurrentData?.endsAfter?.endsAfter ||
            packageOrderEntry.recurrentData?.endsAfter?.endsBy) &&
            packageOrderEntry.recurrentData?.startsOn.dateTime))) {
      let datalist = {
        ...packageOrderEntry,
        createdName: info.name,
        createdAt: getData(),
        currentStatus: "New",
      };
      if (!ruleValidated) {
        if (checkDuplicateOrder()) {
          setAlertData({
            open: true,
            // data: "Duplicate order is entered. Do you still want to continue?"
            data: {
              type: 3,
              alertText:
                "Duplicate Procedure is entered. Do you still want to continue?",
            },
          });
          return false;
        }
      }
      await savePackageOrder([datalist], null);
      setpackageOrderEntry({
        ...packageOrderEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !packageOrderEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          }
        },
        orders: [],
        qty: 1,
      });
    } else {
      setpackageOrderEntry({
        ...packageOrderEntry,
        recurrent: false,
        headerShow: true,
        error: true,
      });
      setAnchorEl(null);
    }
  };

  const handleClick = (index, event) => {
    setAnchorEl(event.currentTarget);
    setIndex(index);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { parent_id } = props;
  return (
    <div
      id={`${parent_id}_laborder_parent_div`}
      style={{ width: "100%", marginBottom: "0px" }}
    >
      <Row
        id={`${parent_id}_laborder_parent_row`}
        inLineStyles={styles.vitailsCard}
      >
        {/* Add a new treatment procedures data's */}
        <Col
          id={`${parent_id}_laborder_parent_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          {packageOrderEntry.headerShow ? (
            <div>
              <Grid container justifyContent="space-between" direction="column">
                <Grid item container>
                  <Grid item style={{ marginTop: "15px" }}>
                    <Text
                      id={`${parent_id}_laborder_headerShow_text`}
                      inLineStyles={{
                        fontWeight: 600,
                        fontSize: "14px",
                        cursor: "pointer",
                        color: UIColor.primaryColor.color,
                        alignItems: "center",
                      }}
                    >
                      Package Order
                    </Text>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider orientation="horizontal" variant="fullWidth" />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                style={{ display: "flex", gap: "10px", padding: "15px" }}
              >
                <Grid item>
                  {" "}
                  <div
                    id={`${parent_id}_laborder_title_div`}
                    style={styles.vitalsfieldHead}
                  >
                    <div
                      id={`${parent_id}_laborder_title_sub_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <Autocomplete
                        id={`${parent_id}_laborder_autocomplete`}
                        className={classes.autocomplete}
                        style={{ width: 160 }}
                        fullWidth
                        size="small"
                        options={storeData?.getpackageorder?.data ?? []}
                        popupIcon={
                          <ExpandMoreIcon
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            id={`${parent_id}_laborder_Search_textfield`}
                            {...params}
                            variant="standard"
                            placeholder="Search Package Order"
                            error={packageOrderEntry?.error && !packageOrderEntry?.orderCatalog?.value}
                            style={{ width: "100%", fontSize: 14 }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${parent_id}_laborder_Search_${option.label.replaceAll(
                              " ",
                              "_"
                            )}_typography`}
                            className="pc_regular"
                            style={{
                              width: "100%",
                              fontSize: 14,
                              color: UIColor.secondaryText.color,
                            }}
                            noWrap
                          >
                            {option.label}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={packageOrderEntry.orderCatalog}
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "orderCatalog")
                        }
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_Priority_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <Autocomplete
                        id={`${parent_id}_laborder_Priority_autocomplete`}
                        style={{ width: "100px" }}
                        className={classes.autocomplete}
                        size="small"
                        options={storeData?.getpriority?.data ?? []}
                        popupIcon={
                          <ExpandMoreIcon
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            id={`${parent_id}_laborder_Priority_textfield`}
                            {...params}
                            variant="standard"
                            placeholder="Priority"
                            error={packageOrderEntry?.error && !packageOrderEntry?.priority?.value}
                            style={{ width: "100%", fontSize: 14 }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${parent_id}_laborder_Priority_${option.label.replaceAll(
                              " ",
                              "_"
                            )}_typography`}
                            className="pc_regular"
                            style={{
                              width: "100%",
                              fontSize: 14,
                              color: UIColor.secondaryText.color,
                            }}
                            noWrap
                          >
                            {option.label}
                          </Typography>
                        )}
                        // classes={{popper: "muiPopperDropdown"}}
                        value={packageOrderEntry.priority}
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "priority")
                        }
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_Nature_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <Autocomplete
                        className={classes.autocomplete}
                        style={{ width: "100px" }}
                        id={`${parent_id}_laborder_Nature_autocomplete`}
                        size="small"
                        options={storeData?.getordernature?.data ?? []}
                        popupIcon={
                          <ExpandMoreIcon
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            id={`${parent_id}_laborder_Nature_textfield`}
                            {...params}
                            variant="standard"
                            placeholder="Nature"
                            error={packageOrderEntry?.error && !packageOrderEntry?.nature?.value}
                            style={{ width: "100%", fontSize: 14 }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${parent_id}_laborder_Nature_${option.label.replaceAll(
                              " ",
                              "_"
                            )}_typography`}
                            className="pc_regular"
                            style={{
                              width: "100%",
                              fontSize: 14,
                              color: UIColor.secondaryText.color,
                            }}
                            noWrap
                          >
                            {option.label}
                          </Typography>
                        )}
                        // classes={{popper: "muiPopperDropdown"}}
                        value={packageOrderEntry.nature}
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "nature")
                        }
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_calender_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      {" "}
                      <TextField
                        id={`${parent_id}_laborder_calender_textfield`}
                        className={classes.autocomplete}
                        style={{ width: 170 }}
                        autoComplete={"off"}
                        value={
                          packageOrderEntry?.dateTime
                            ? moment(packageOrderEntry?.dateTime).format(
                                "YYYY-MM-DD, hh:mm A"
                              )
                            : ""
                        }
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "dateTime")
                        }
                        onClick={(event) => handleClick(1, event)}
                        placeholder={"Start Date"}
                        error={packageOrderEntry?.error && !packageOrderEntry?.dateTime}
                        InputProps={{
                          classes: { input: classes.input },
                          endAdornment: (
                            <InputAdornment
                              id={`${parent_id}_laborder_calender_inputadornment`}
                              position="end"
                            >
                              <Div
                                id={`${parent_id}_laborder_calendericon_div`}
                                aria-describedby={id}
                                style={{ cursor: "pointer" }}
                                onClick={(event) => handleClick(1, event)}
                              >
                                <CalendarIcon
                                  id={`${parent_id}_laborder_calendericon`}
                                  color={UIColor.secondaryText.color}
                                  width={"16"}
                                  height={"16"}
                                />
                              </Div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_Qty_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <TextField
                        id={`${parent_id}_laborder_Qty_textinput`}
                        className={classes.autocomplete}
                        style={{ width: 70 }}
                        type={"number"}
                        value={packageOrderEntry?.qty}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "qty")
                        }
                        placeholder="Quantity"
                        error={packageOrderEntry?.error && !packageOrderEntry?.qty}
                        // error={isErr && +state.qty < 1 ? true : false}
                        // label="Qty"
                        // labelStyle={styles.labelStyle}
                        // style={
                        // isErr && +state.qty < 1
                        //   ? { borderRadius: 8 }
                        //   :
                        // styles.borderStyle
                        // }
                        maxLength={3}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  {" "}
                  <Button
                    id={`${parent_id}_laborder_add_button`}
                    onClick={() => {
                      if (
                        packageOrderEntry.orderCatalog?.value &&
                        packageOrderEntry.dateTime &&
                        packageOrderEntry.nature.value &&
                        packageOrderEntry.priority.label &&
                        packageOrderEntry.qty
                      ) {
                        AddPackageOrder();
                      } else {
                        let fld = "";
                        if (!packageOrderEntry.orderCatalog?.value){
                          fld = fld + (fld == ""? "" : ",") + "\nPackage Procedure";
                        }
                        if (!packageOrderEntry.priority.label){
                          fld = fld + (fld == ""? "" : ",") + "\nPriority";
                        }
                        if (!packageOrderEntry.nature.value){
                          fld = fld + (fld == ""? "" : ",") + "\nNature";
                        }
                        if (!packageOrderEntry.dateTime){
                          fld = fld + (fld == ""? "" : ",") + "\nDate";
                        }
                        if (!packageOrderEntry.qty){
                          fld = fld + (fld == ""? "" : ",") + "\nQuantity";
                        }
                        setpackageOrderEntry({
                          ...packageOrderEntry,
                          recurrent: false,
                          headerShow: true,
                          error: true,
                        });
                        props.alert.setSnack({
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: "Please fill all the mandatory fields!" + fld,
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.right,
                        });
                      }
                    }}
                    variant={"text"}
                    style={{
                      color: UIColor.primaryColor.color,
                      fontWeight: 600,
                      minWidth: "1%",
                      padding: "5px",
                      marginLeft: "3px",
                    }}
                    size={"small"}
                  >
                    <img src={Tick} alt="Add" />
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div
              id={`${parent_id}_laborder_headerShow_div`}
              style={{
                display: "flex",
                width: "99%",
                justifyContent: "space-between",
              }}
            >
              <div className="icon" style={{ display: "flex" }}>
                <Text
                  id={`${parent_id}_laborder_headerShow_text`}
                  onClick={() =>
                    handleInputChange(
                      !packageOrderEntry.headerShow,
                      "headerShow"
                    )
                  }
                  inLineStyles={{
                    fontWeight: 600,
                    fontSize: "14px",
                    cursor: "pointer",
                    color: UIColor.primaryColor.color,
                    padding: "15px 0",
                  }}
                >
                  {"Package Order"}
                </Text>
              </div>
              <div
                className="icon"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  paddingTop: "10px",
                }}
              >      
                  <div
                    onClick={() =>
                      handleInputChange(
                        !packageOrderEntry.headerShow,
                        "headerShow"
                      )
                    }
                    style={{ marginLeft: "10px", marginTop: "4px" }}
                  >
                    <img src={Addicon} alt="Add" />
                  </div>
                
              </div>
            </div>
          )}{" "}
        </Col>

        {/* Treatment procedure list */}
        <div style={{width:"100%"}}>
          {
            visibleCurrentItems.length > 0 &&
            visibleCurrentItems.map((val, i) => {
              return (
                <Col
                  id={`${parent_id}_laborder_${i}_list_col`}
                  key={i}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  inLineStyles={{
                    // padding: "10px 8px",
                    borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                    overflow: "hidden",
                  }}
                  className={"allergyListHover_"}
                >
                  <PackageList
                    parent_id={"lab_order" + i}
                    treatment_order={val?.orderCatalog?.label}
                    priority={val?.priority?.label}
                    nature={val?.nature?.label}
                    dateTime={val?.dateTime}
                    // ordersData={treatmentProcedureEntry?.orders}
                    val={val}
                    infoAlertText={val.infoAlertText}
                    status={
                      val?.childRecurrent?.[0]?.statushistory?.length > 0
                        ? val?.childRecurrent?.[0]?.statushistory?.[
                            val?.childRecurrent?.[0]?.statushistory?.length - 1
                          ]?.new_status ?? "Draft"
                        : "Draft"
                    }
                    IndexValue={i}
                    // deleteList={deleteList}
                    data={val}
                    info={info}
                    getData={getData}
                    patientgetData={props?.patientgetData}
                    getPackageOrderRead={getPackageOrderRead}
                    backdrop={props?.backDrop}
                  />
                </Col>
              );
            })
          }
        
        {list.length > 5 && (
         <div
           style={{
             display: "flex",
             justifyContent: "flex-end",
             padding: "10px 10px",
             borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
           }}
         >
           <Pagination
             count={Math.ceil(list.length / currentPageSize)}
             page={currentCurPage}
             onChange={handleCurrentPageChange}
           />
         </div>
        )}
        </div>
        <HistoryAccordion>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            {visibleItems.length ? (
              visibleItems.map((order, i) => (
                <Grid
                  item
                  xs={12}
                  style={{
                    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
                  }}
                >
                   <Accordion elevation={0} >
                      <AccordionSummary
                        style={{padding:0}}
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                  <Grid container xs={12} justifyContent="space-between">
                    <Grid item xs={10} container direction="column" spacing={1}>
                      <Grid
                        item
                        style={{
                          color: UIColor.secondaryButtonColor.color,
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "pc_regular",
                        }}
                      >
                        {order.orderCatalog}{" "}&nbsp;&nbsp;
                        <InfoOutlined style={{height:"18px",width:"18px"}} color={UIColor.primaryColor.color} />
                      </Grid>
                      <Grid
                        item
                        style={{
                          fontFamily: "pc_regular",
                          fontSize: 12,
                          color: UIColor.tertiaryText.color,
                        }}
                      >
                        {order?.priority?.display} -{" "}
                        {order?.OrderNature?.display} - On{" "}
                        {formatDateFromEpoch(order?.occuranceStartDate)}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      container
                      alignItems="flex-end"
                      direction="column"
                      spacing={1}
                      style={{
                        fontFamily: "pc_regular",
                        fontSize: 12,
                        color: UIColor.tertiaryText.color,
                      }}
                    >
                      <Grid item>
                        On{" "}
                        {formatDateFromEpoch(order?.orderDateTime).slice(0, 12)}
                      </Grid>
                      <Grid item>{order?.updatedby?.name?.[0]?.fulname}</Grid>
                    </Grid>
                  </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Grid container direction="column" style={{borderRadius:5,margin:"0px 0px 0px 7px"}}>
                    <Text
                      inLineStyles={{
                        fontWeight: 600,
                        fontSize: "14px",
                        cursor: "pointer",
                        color: UIColor.primaryColor.color,
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      Package Items
                    </Text>
                      {order?.packdetails?.map((item)=>{
                        return(
                        <Grid container justifyContent="space-between" alignItems="center" style={{backgroundColor:UIColor.differentiationBackground.color,padding: "10px 12px",borderTop:`1px solid ${UIColor.lineBorderFill.color}`,borderRadius:5}}>
                          <Grid item>
                            <Grid container  direction="column">
                              <Grid item >
                                <Text
                                inLineStyles={{
                                  color: UIColor.secondaryButtonColor.color,
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  fontFamily: "pc_regular",
                                }}
                                >
                                  {item?.ordername}
                                </Text>
                              </Grid>
                              <Grid item >
                                <Text inLineStyles={styles.textColor1}>
                                    Package Qty - {item?.total}  &nbsp;&nbsp;&nbsp;&nbsp;
                                    Availed Qty - {item?.availqty} &nbsp;&nbsp;&nbsp;&nbsp;  
                                    Order Status - &nbsp;
                                    <Text inLineStyles={styles.textColor1} style={item?.packordstatus=="Completed"? {color:UIColor.success.color}:{color:UIColor.secondaryColor.color} } >{
                                      item?.packordstatus}
                                    </Text>
                                </Text>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item></Grid>
                        </Grid>
                        )
                      })}
                   </Grid>
                  </AccordionDetails>
                  </Accordion>
                </Grid>
              ))
            ) : (
              <Typography
                variant="caption"
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "5px",
                  display: "block",
                }}
              >
                No data available
              </Typography>
            )}
          </Grid>

          {visibleItems.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Pagination
                count={Math.ceil(nurseHistory.length / pageSize)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          )}
        </HistoryAccordion>
      </Row>
      {/* {packageOrderEntry.error && (
        <span
          id={`${parent_id}_laborder_filled_Recurrent_span`}
          style={styles.vitailsCarderr}
        >
          Please fill all the mandatory fields!
        </span>
      )} */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={index === 1 ? () => setAnchorEl(null) : null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.muiPopovers }}
      >
        {/* {index === 0 && (
          <Div
            id={`${parent_id}_laborder_Recurrent_div`}
            style={{ padding: 15 }}
          >
            <Recurrent
              parent_id={"lab_order"}
              recurrentData={packageOrderEntry?.recurrentData}
              saveButtonAction={saveRecurrentData}
              deleteButtonAction={handleCloseRec}
              width={500}
            />
          </Div>
        )} */}
        {index === 1 && (
          <CustomDatePicker
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            selectedDate={
              packageOrderEntry?.dateTime
                ? new Date(packageOrderEntry?.dateTime)
                : null
            }
            //minDate={new Date()}
            minTime={getCurrentMinTime(
              packageOrderEntry?.dateTime
                ? new Date(packageOrderEntry?.dateTime)
                : null
            )}
            maxTime={setHours(setMinutes(new Date(), 59), 23)}
            handleChange={(date, e) => {
              handleInputChange(date, "dateTime");
              if (e?.target === undefined) {
                setIndex(null);
                setAnchorEl(null);
              }
            }}
          />
        )}
      </Popover>
      {alertData.open && (
        <AlertsRestriction {...alertData} handleClose={closeAlertModal} />
      )}
    </div>
  );
};
export default withAllContexts(PackageOrder);
