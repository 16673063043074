import React from "react";
import { Div } from "qdm-component-library";
import { UIColor } from "../../../themes/theme";
import {
  Button,
  ClickAwayListener,
  Fade,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Popper,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Box, Modal } from "@mui/material";
import AdvanceViewBill from "./advanceTable";
import CloseIcon from "@material-ui/icons/Close";
import RefundReciept from "./refundTable";

function PatientDashboardNavBar(props) {
  const [open, setOpen] = React.useState(false);
  const [advanceOpen, setAdvanceOpen] = React.useState(false);
  const [refundOpen, setRefundOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = (event) => {
    setOpen(false);
  };

  const handleMenuItemClick = (options, index) => {
    //debugger
    setSelectedIndex(index);
    setOpen(false);
    handleBookType(options?._id);
  };
  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(!open);
  };
  const handleAdvanceOpen = (e) => {
    setAdvanceOpen(!advanceOpen);
  };
  const handleAdvanceClose = (event) => {
    setAdvanceOpen(false);
  };
  const handleDirectRefundOpen = (e) => {
    setRefundOpen(true);
  };
  const handleDirectRefundClose = (event) => {
    setRefundOpen(false);
  };
  const {
    navBarArr,
    btnName,
    btnClick = () => null,
    clickNav = () => null,
    activeId = 1,
    parent_id,
    enablebtn,
    options = [],
    handleBookType = () => null,
    handleBillButton = () => null,
    secondBtnName = "",
    thirdBtnName = "",
    fourthBtnName = "",
    fifthBtnName = " ",
    handleAdvanceAPayment = () => null,
  } = props;

  return (
    <Div
      id={`${parent_id}_navbar_parent_div`}
      inLineStyles={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        padding: "12px 36px",
        backgroundColor: "#F9F9F9",
        zIndex: 9,
      }}
    >
      <Div id={`${parent_id}_navbar_parent_sub_div`}>
        {navBarArr && (
          <ul
            id={`${parent_id}_navbar_ul`}
            style={{
              display: "inline-flex",
              listStyleType: "none",
              padding: 0,
              margin: 0,
              position: "absolute",
              bottom: 0,
            }}
          >
            {navBarArr.map((val, i) => (
              <li
                id={`${parent_id + "_" + val.label}_${i}_navbar_li`}
                className={activeId === val.id ? "pc_medium" : "pc_regular"}
                onClick={() => clickNav(val.id)}
                style={{
                  cursor: "pointer",
                  marginRight: "24px",
                  borderBottom:
                    activeId === val.id
                      ? `2px solid ${UIColor.secondaryColor.color}`
                      : "",
                  textDecoration: "none",
                  padding: "0px 0px 21px",
                  letterSpacing: "-0.13px",
                  color:
                    activeId === val.id
                      ? UIColor.secondaryColor.color
                      : "#6F6F6F",
                  fontSize: activeId === val.id ? "16px" : "14px",
                  lineHeight: 1,
                }}
              >
                {val.label}
              </li>
            ))}
          </ul>
        )}
      </Div>
      <div style={{ display: "flex", gap: "10px" }}>
      <Div style={{ height: 38 }} id={`${parent_id}_navbar_btn_div`}>
          {fifthBtnName && (
            <Button
              style={{
                fontSize: "14px",
                borderRadius: "8px",
                backgroundColor:
                  !props.disabled && UIColor.secondaryColor.color,
                border: "none",
                padding: "10px 16px",
                letterSpacing: 0,
                height: 40,
                color: "white",
              }}
              color="primary"
              size="small"
              aria-controls={advanceOpen ? "split-button-menu" : undefined}
              aria-expanded={advanceOpen ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleDirectRefundOpen}
              disabled={props.disabled}
              id={`${parent_id}_navbar_btn_thirdBtnName`}
              className="pc_medium"
              //  endIcon={<ArrowDropDownIcon />}
            >
              {fifthBtnName}
            </Button>
          )}
        </Div>
      <Div style={{ height: 38 }} id={`${parent_id}_navbar_btn_div`}>
          {fourthBtnName && (
            <Button
              style={{
                fontSize: "14px",
                borderRadius: "8px",
                backgroundColor:
                  !props.disabled && UIColor.secondaryColor.color,
                border: "none",
                padding: "10px 16px",
                letterSpacing: 0,
                height: 40,
                color: "white",
              }}
              color="primary"
              size="small"
              aria-controls={refundOpen ? "split-button-menu" : undefined}
              aria-expanded={refundOpen ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleAdvanceOpen}
              disabled={props.disabled}
              id={`${parent_id}_navbar_btn_thirdBtnName`}
              className="pc_medium"
              //  endIcon={<ArrowDropDownIcon />}
            >
              {fourthBtnName}
            </Button>
          )}
        </Div>
        <Div style={{ height: 38 }} id={`${parent_id}_navbar_btn_div`}>
          {thirdBtnName && (
            <Button
              style={{
                fontSize: "14px",
                borderRadius: "8px",
                backgroundColor:
                  !props.disabled && UIColor.secondaryColor.color,
                border: "none",
                padding: "10px 16px",
                letterSpacing: 0,
                height: 40,
                color: "white",
              }}
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleAdvanceAPayment}
              disabled={props.disabled}
              id={`${parent_id}_navbar_btn_thirdBtnName`}
              className="pc_medium"
              //  endIcon={<ArrowDropDownIcon />}
            >
              {thirdBtnName}
            </Button>
          )}
        </Div>
        <Div style={{ height: 38 }} id={`${parent_id}_navbar_btn_div`}>
          {secondBtnName && (
            <Button
              style={{
                fontSize: "14px",
                borderRadius: "8px",
                backgroundColor:
                  !props.disabled && UIColor.secondaryColor.color,
                border: "none",
                padding: "10px 16px",
                letterSpacing: 0,
                height: 40,
                color: "white",
              }}
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleBillButton}
              disabled={props.disabled}
              id={`${parent_id}_navbar_btn_`}
              className="pc_medium"
              //  endIcon={<ArrowDropDownIcon />}
            >
              {secondBtnName}
            </Button>
          )}
        </Div>
        <Div style={{ height: 38 }} id={`${parent_id}_navbar_btn_div`}>
          {enablebtn && (
            <Button
              style={{
                fontSize: "14px",
                borderRadius: "8px",
                backgroundColor:
                  !props.disabled && UIColor.secondaryColor.color,
                border: "none",
                padding: "10px 16px",
                letterSpacing: 0,
                height: 40,
                color: "white",
              }}
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleOpen}
              disabled={props.disabled}
              id={`${parent_id}_navbar_btn_`}
              className="pc_medium"
              endIcon={<ArrowDropDownIcon />}
            >
              {btnName}
            </Button>
          )}
        </Div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper style={{ overflow: "auto" }}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="split-button-menu">
              {options?.map((option, index) => (
                <MenuItem
                  key={option?.id}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(option, index)}
                >
                  <Typography style={{ textTransform: "capitalize" }}>
                    {" "}
                    {option?.display}
                  </Typography>
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
      <Modal
        open={advanceOpen}
        onClose={handleAdvanceClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "relative",
            width: "80%",
            height: "80%",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 3,
            mx: "auto",
            my: "5%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 1,
            }}
          >
            <IconButton onClick={handleAdvanceClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <AdvanceViewBill
              patientDetails={props?.patientDetails}
              setAdvanceOpen={setAdvanceOpen}
              parent_id={"advance reciept"}
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={refundOpen}
        onClose={handleDirectRefundClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            width: "60%",
            height: "70%",
            mx: "20%",
            my: "6%",
          }}
        >
          <RefundReciept
            patientDetails={props?.patientDetails}
            setRefundOpen={setRefundOpen}
            parent_id={"refund reciept"}
            handleDirectRefundClose={handleDirectRefundClose}
          />
        </Box>
        {/* <Box
          sx={{
            position: "relative",
            width: "70%",
            height: "80%",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            // p: 3,
            // mx: "auto",
            // my: "6%",
            overflow: "hidden",
          }}
        > */}
        {/* <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 1,
            }}
          >
            <IconButton onClick={handleDirectRefundClose}>
              <CloseIcon />
            </IconButton>
          </Box> */}
        {/* </Box> */}
      </Modal>
    </Div>
  );
}

export default PatientDashboardNavBar;
