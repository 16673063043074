import React, { useEffect } from "react";
import { BorderLinearProgress } from "qdm-component-library";
import { NetworkCall } from "./networkcall";
import { LocalStorageKeys, NetWorkCallMethods, semverGreaterThan, refreshCacheAndReload } from "./utils";

function AppAuth(props) {
  const checkForLatestBuild = () => {
    console.log(`${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json`,"app auth");
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    ).then((_) => {
      const isVersion = semverGreaterThan(_.data.version, localStorage.getItem(LocalStorageKeys.version));
      localStorage.setItem(LocalStorageKeys.version, _.data.version)
      if (isVersion) {
        refreshCacheAndReload();
      }
    }).catch(err => {
      console.log('err:', err);
    })
  }
  useEffect(() => {
    console.log("useEffect"); 
    checkForLatestBuild();
  }, []);
  return (
    <>
      {props.loading && (
        <BorderLinearProgress/>
      )}
      {props.children}
    </>
  );
}

export default AppAuth;
