import { Container, Row, Col, Div, Text } from "qdm-component-library"
import AppointmentDetails from "./appointmentDetails";
import AppointmentBookingFilter from "./components/appointmentBookingFilter";
import { useContext, useEffect, useState, } from "react";
import Loading from "../../components/global/loading";
import AvailableDoctorsWithSlots from "./availableDoctorsWithSlots";
import actions from "../../redux/actions/index";
import { connect, useDispatch } from "react-redux";
// import { DoctorLists } from "./mockData";
import {
  checkWithCasbin,
  getUserInfo,
  getImgUrl,
  AlertProps,
} from "../../utils";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import dollarIcon from "../../assets/img/svg/Group 90507.svg";
import { AlertContext, PatientDetailsContext } from "../../context";
import AppHeader from "../../components/layout/appHeader";
import AppointmentBookingSideMenu from "./appointmentBookingSideMenu";
// import AppointmentBookingSearch from "../searchFacility/appointmentBookingSearch";
// import MultiBook from "../../components/multibook";
// import MultiBookComp from "../../components/multibook/multiBookComp";
// import withAppBar from "../../hoc/withAppBar";
// import AvailableSlots from "./availableDoctorsWithSlots";

const casbinjs = require("casbin.js");

const AppointmentBooking = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useContext(AlertContext);
  const patientDetails = useContext(PatientDetailsContext);
  const patientId = patientDetails?.patientId;

  const IsModifyUser = location?.state?.modifyUser;

  const newBook = location?.state?.newBook;
  const fromCloseConsultation = location?.state?.fromCloseConsultation;

  // Use Selectors

  // const allLanguages = useSelector(
  //   (state) => state?.docterListingReducer?.allLanguages
  // );

  const doctorList = useSelector(
    (state) => state?.doctorlisting?.docterListing
  );
  const reasonForAppointMent = useSelector(
    (state) => state?.appointment.reasonForAppointMent
  );

  console.log("reasonForAppointMent", reasonForAppointMent);

  // Component States

  const [state, setState] = useState({
    loading: false,
    callcen: false,
    Persondata: [],
    fin_data: [],
    fromListing: false,
    referralData: [],
    selectOption: {},
    oldSlot: {
      isUpdate: false,
      info: {},
      appInfoFetched: {},
    },
    sidebar: false,
    collpaseOpen: 0,
    showOtpVerification: false,
    bulkBooking: false,
    city: location?.state?.cityName || "",
    loaction: location?.state?.loaction || "",
    hospital: location?.state?.hospital || [],
    specialities: location?.state?.specialities || [],
    symptoms: location?.state?.symptoms || [],
    doctors: location?.state?.doctors || [],
    symptomsSearch: location?.state?.symptomsSearch || [],
    data: [],
    meageget: {},
    Tempdata: [],
    profileDetails: [],
    guestInfo: {
      name: props?.guestInfo?.data?.name || "",
      age: props?.guestInfo?.data?.age || "",
      gender: props?.guestInfo?.data?.gender || "",
      mobileNo: {},
      mailId: "",
    },
    guestBookerInfo: {
      name:
        props?.guestInfo?.data?.type === 0
          ? props?.guestInfo?.data?.name || ""
          : "",
      relationship:
        props?.guestInfo?.data?.type === 0
          ? { value: "Others", label: "Others" }
          : {},
      mobileNo: {},
      mailId: "",
    },
    otp: 0,
    addBookBy: true,
    selectedSlot: {},
    selectedSlotId: null,
    selectedSlotOrd: null,
    appointmentReason: [],
    modifyAppointmentReason: {},
    doctorInfo: "",
    indexToShow: null,
    age: {
      val: moment().format("DD-MM-YYYY"),
      milli: moment().valueOf(),
    },
    allLang: [],
    rightSideAllCard: false,
    iagree: false,
    sideInfo: {
      age: 0,
      gender: "",
    },
    openCloseCalendar: false,
    filter: false,
    resetTimeSlot: null,
    consultationMode: {
      value: process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE,
      label: "Direct Consultation",
      _id: process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE,
    },
    monthCalendarInputValue: "",
    error: {},
    appointmentUpdate: !!location?.state?.modifyUser,
    isMultipleAppointment: false,
    multiApppointmentInfo: {
      appointments: {
        startsOn: {
          date: "",
          slotSelect: {},
          milli: 0,
        },
        preferrence: {
          preferredDays: [],
          preferredWeeks: [],
        },
        occurence: {
          occurrenceHandle: "NoOfDays",
          occurrenceValue: 1,
        },
        reccurance: {
          reccuranceValue: 1,
          reccuranceType: {
            label: "Days",
            value: process.env.REACT_APP_FREQUENCY_UOM_DAYS,
          },
        },
      },
      info: {},
      data: [],
      slotObj: {},
      filterData: {},
      uploadFiles: [],
    },
    selectedDate: {
      unix: moment().startOf("date").unix(),
      val: moment().startOf("date").format("DD-MM-YYYY"),
    },
    permissionDa: [],
    permissionData: [],
    uploadFiles: [],
    patientInstruction: "",
    modalOpen: false,
    doctorName: "",
    reasonForAppointMent: {},
    // openCloseHeader: false,
    // indexToShow: null,
    // Functions
  });

  const [flag, setFlag] = useState({
    multiApppointment: false,
  });
  // Use Effect

  useEffect(() => {
    fetchData();
  }, []);
  console.log("StateValue", state);

  async function fetchData() {

    await dispatch(actions.GET_COUNTRY_CODE_LIST());
    let permissionData_ = await checkWithCasbin(["appointmentconfirmation"]);
    let permissionData__ = await checkWithCasbin(["bookAppointment"]);
    let reasonForApptCancelModify = [];
    const roleData = jwtDecode(localStorage.getItem("RoleData"));

    // const roleDataArray = roleData[userInf.rolename.toLowerCase()].access;
    const roleDataArray = roleData?.access;
    const permission = { callcenter: roleDataArray };
    const authorizer = new casbinjs.Authorizer("manual", permission);
    authorizer.setPermission(permission);
    const result = await authorizer.can("callcenter", "4");

    let data = [];
    let uploadFiles = [];
    let selectedAppointmentReason = [];
    const patientdata = await dispatch(
      actions.ORGANIZATION_FILTER_BY_PATIENT({
        patientid: patientId ?? location.state.patientId,
        tenantid: "",
        facilityid: "",
      })
    );

    const referral_patientdata = await dispatch(
      actions.GET_REFERRAL({
        // patientid: location?.state?.patientId,
        patientid: "Patient/11340",
        // reffId: location?.state?.refferalId,
        reffId: "ReferralTrx/10024",
      })
    );

    let fin_DATA = [];
    if (actions.GET_FINANCIAL) {
      const fin_data = await dispatch(
        actions.GET_FINANCIAL({
          patientid: patientId,
          tenantid: "",
          facilityid: "",
        })
      );
      if (fin_data?.payload?.data) {
        fin_DATA = fin_data?.payload?.data?.map((v) => {
          //const setdata = v?.payersprioritycoverage?.[0];
          return {
            pic: dollarIcon,
            name: null,
            care_type: v?.guarantortype?.shortdesc,
            title_name: v?.payertype?.shortdesc + ", " + v?.policyid?.shortdesc,
          };
        });
      }
    }
    const reason = await dispatch(
      actions.REASON_FOR_APPOINTMENT({
        tenantid: "",
        facilityid: "",
        lang: "en",
      })
    );
    const { payload } = await dispatch(actions.GET_ALL_SPECIALITY_2());
    const userInfo = getUserInfo();
    const info = {
      age: 0,
      gender: "",
    };
    if (userInfo.type === "Token") {
      let mobileNo = userInfo.mobile;
      if (
        location?.state?.selectedUser &&
        location?.state?.selectedUser?.birthDay
      ) {
        info["age"] = moment().diff(
          moment(location?.state?.selectedUser?.birthDay, "DD/MM/YYYY"),
          "years"
        );
        info["gender"] = location?.state?.selectedUser?.gender;
      } else {
        //phone: mobileNo
        dispatch(
          actions.READ_PERSON({
            username: mobileNo,
            tenantid: "",
            facilityid: "",
          })
        ).then((val) => {
          info["age"] = val?.payload?.data?.birthDay
            ? moment().diff(
              moment(val?.payload?.data?.birthDay, "DD/MM/YYYY"),
              "years"
            )
            : "";
          info["gender"] = val?.payload?.data?.gender;
        });
      }
    } else {
      info["age"] = moment().diff(
        moment(userInfo?.info?.age?.val, "DD/MM/YYYY"),
        "years"
      );
      info["gender"] = userInfo?.info?.gender?.value || "";
    }
    if (location?.state && !location?.state?.modifyUser) {
      const {
        hospital = [],
        specialities = [],
        loc = {},
        doctors = [],
      } = location?.state;

      // if (location.state?.apptypecode) {
      //   getDocterListingDetails({
      //     apptypecode: location.state?.apptypecode,
      //     latitude: loc?.latitude || 0,
      //     longtitude: loc?.longitude || 0,
      //   });
      // } else 
      if (!doctors?.[0]?.value ? specialities.length > 0 : true) {
        getDocterListingDetails({
          hospital: hospital?.map((l) => l?.value),
          specialities: specialities.map((spec) => spec.ID),
          latitude: loc?.latitude || 0,
          longtitude: loc?.longitude || 0,
          apptypecode: location.state?.apptypecode
        });
      } else {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "something went wrong !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        // window.location.href = "/";
        return;
      }
    } else {
      const {
        slots: Slots = [],
        resourcecode,
        slotID,
        resourcetype,
      } = location?.state?.appointMentInfo || {};
      let start = Slots?.[0]?.start;
      if (location?.state?.newBook) {
        start = Math.floor(Date.now() / 1000);
      }
      if (location?.state?.fromCloseConsultation) {
        start = Slots?.[0]?.start;
      }
      data = await dispatch(
        actions.APPOINTMENT_UPDATE_READ({
          start,
          id: [resourcecode?.[0]?._id ?? resourcecode?._id],
          slotID,
          HealthcareServiceID: [resourcecode?.[0]?._id],
          type:
            resourcetype?.display === "Practitioner"
              ? "practionerid"
              : "clinicid",
        })
      );
      console.log("fulloop", data);
      const IsModifyUser = location?.state?.modifyUser;
      const newBook = location?.state?.newBook;
      if (IsModifyUser && !newBook) {
        selectedAppointmentReason = reasonForAppointMent?.data?.filter((d) => {
          if (
            location?.state?.appointMentInfo?.appointmentReason?.includes(
              d?.value
            )
          ) {
            return true;
          }
          return false;
        });
        uploadFiles = location?.state?.appointMentInfo?.report_docs?.map(
          (f) => {
            return {
              name: f.fileName,
              type: f.filetype,
              fileUrl: getImgUrl(f.fileid),
              size: 0,
              originalData: f,
            };
          }
        );
        reasonForApptCancelModify = await dispatch(
          actions.Masters({ type: ["APPMODIFYBYUSER"] })
        );
        console.log({ reasonForApptCancelModify });
      }
    }
    setState((prevState) => ({
      ...prevState,
      permissionDa: permissionData_,
      permissionData: permissionData__,
      callcen: result,
      Persondata: patientdata?.payload?.data,
      referralData: referral_patientdata?.payload?.data,
      selectOption: fin_DATA?.[0] || {},
      fin_data: fin_DATA || [],
      allSpeciality: payload?.data,
      sideInfo: info,
      reasonForAppointMent: reason,
      modifyAppointmentReason:
        reasonForApptCancelModify?.payload?.data?.APPMODIFYBYUSER,
      data: data?.payload?.data || [],
      uploadFiles: uploadFiles || [],
      appointmentReason: selectedAppointmentReason,
      patientInstruction:
        location?.state?.appointMentInfo?.patientInstruction || "",
    }));
  }

  // async function appointmentRead() {

  //   console.log("kol", location);
  //   const {
  //     slots: Slots = [],
  //     resourcecode,
  //     slotID,
  //     resourcetype,
  //   } = location?.state?.appointMentInfo || {};
  //   let start = Slots?.[0]?.start;
  //   if (location?.state?.newBook) {
  //     start = Math.floor(Date.now() / 1000);
  //   }
  //   if (location?.state?.fromCloseConsultation) {
  //     start = Slots?.[0]?.start;
  //   }
  //   const data = await dispatch(
  //     actions.APPOINTMENT_UPDATE_READ({
  //       start,
  //       id: [resourcecode?.[0]?._id ?? resourcecode?._id],
  //       slotID,
  //       HealthcareServiceID: [resourcecode?.[0]?._id],
  //       type:
  //         resourcetype?.display === "Practitioner"
  //           ? "practionerid"
  //           : "clinicid",
  //     })
  //   );

  //   const IsModifyUser = location?.state?.modifyUser;
  //   const newBook = location?.state?.newBook;
  //   if (IsModifyUser && !newBook) {
  //     const selected = reasonForAppointMent?.data?.filter((d) => {
  //       if (
  //         location?.state?.appointMentInfo?.appointmentReason?.includes(
  //           d?.value
  //         )
  //       ) {
  //         return true;
  //       }
  //       return false;
  //     });
  //     const uploadInfo = location?.state?.appointMentInfo?.report_docs?.map(
  //       (f) => {
  //         return {
  //           name: f.fileName,
  //           type: f.filetype,
  //           fileUrl: getImgUrl(f.fileid),
  //           size: 0,
  //           originalData: f,
  //         };
  //       }
  //     );
  //     setState((prevState) => ({
  //       ...prevState,
  //       data: data?.payload?.data || [],
  //       uploadFiles: uploadInfo || [],
  //       appointmentReason: selected,
  //       patientInstruction:
  //         location?.state?.appointMentInfo?.patientInstruction || "",
  //       loading: false,
  //     }));
  //   }
  // }
  const getDoctrlistDataFromFilter = (date) => {
    const {
      hospital = [],
      specialities = [],
      loc = {},
      // doctors = [],
    } = location?.state ?? {};
    getDocterListingDetails({
      hospital: hospital?.map((l) => l?.value),
      specialities: specialities.map((spec) => spec.ID),
      latitude: loc?.latitude || 0,
      longtitude: loc?.longitude || 0,
      date: date,
      //doctorId: loc?.[0]?.value,
    });
    setState((prevState) => ({ ...prevState, applyedDate: date }));
  };
  const getDocterListingDetails = async ({
    hospital,
    specialities,
    latitude,
    longtitude,
    date,
    doctorId,
    apptypecode,
  }) => {
    const promArr = [
      dispatch(
        actions.DOCTER_LISTING({
          hospital: hospital,
          specialities,
          latitude,
          longtitude,
          date,
          doctorId,
          apptypecode,
        })
      ),
      dispatch(actions.GET_ALL_LANGS()),
    ];
    let data = await Promise.all(promArr);

    console.log("DoctorListingData", data);
    setState((prevState) => ({
      ...prevState,
      data: data?.[0]?.payload?.data,
      allLang: data?.[0]?.payload?.data?.result,
    }));

    //changeState("allLang", allLanguages?.data || []);
    //changeState("data", doctorList?.data || []);
    //changeState("Tempdata", doctorList?.data || []);

    // setState((prevState) => ({
    //   ...prevState,
    //   data: doctorList?.data || [],
    //   loading: false,
    // }));
    // changeState("allLang", allLanguages?.data || []);
    // // changeState("data", doctorList?.data || []);
    // changeState("Tempdata", doctorList?.data || []);

    // setState((prevState) => ({
    //   ...prevState,
    //   data: [],
    //   Tempdata: [],
    // }));

    //   setState(
    //     {
    //       ...state,
    //       data: [],
    //       Tempdata: [],
    //     },
    //     () => {
    //       changeState("allLang", props?.allLanguages?.data || []);
    //       changeState("data", props?.docterListing?.data || []);
    //       changeState("Tempdata", props?.docterListing?.data || []);
    //     }
    //   );
  };

  const changeState = (key, value) => {
    if (key === "data") {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
        loading: false,
      }));
      // setState({
      //   ...state,
      //   [key]: value,
      //   loading: false,
      // });
    } else {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
      // setState({
      //   ...state,
      //   [key]: value,
      // });
    }
  };

  const resetMulti = () => {
    setState((prevState) => ({
      ...prevState,
      isMultipleAppointment: false,
      multiApppointmentInfo: {
        appointments: {
          startsOn: {
            date: "",
            slotSelect: {},
            milli: 0,
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          },
          occurence: {
            occurrenceHandle: "NoOfDays",
            occurrenceValue: 1,
          },
          reccurance: {
            reccuranceValue: 1,
            reccuranceType: {
              label: "Days",
              value: process.env.REACT_APP_FREQUENCY_UOM_DAYS,
            },
          },
        },
        info: {},
        data: [],
        slotObj: {},
        filterData: {},
        uploadFiles: [],
      },
    }));

    // setState({
    //   ...state,
    //   isMultipleAppointment: false,
    //   multiApppointmentInfo: {
    //     appointments: {
    //       startsOn: {
    //         date: "",
    //         slotSelect: {},
    //         milli: 0,
    //       },
    //       preferrence: {
    //         preferredDays: [],
    //         preferredWeeks: [],
    //       },
    //       occurence: {
    //         occurrenceHandle: "NoOfDays",
    //         occurrenceValue: 1,
    //       },
    //       reccurance: {
    //         reccuranceValue: 1,
    //         reccuranceType: { label: "Days", value: "days" },
    //       },
    //     },
    //     info: {},
    //     data: [],
    //     slotObj: {},
    //     filterData: {},
    //     uploadFiles: [],
    //   },
    // });
  };

  const handleOpenProfile = async () => {
    await dispatch(actions.DOCTER_LISTING_PROFILE());
    const { data } = props?.docterListingProfile;
    setState((prevState) => ({
      ...prevState,
      profileDetails: data,
    }));
    // setState({
    //   ...state,
    //   profileDetails: data,
    // });
  };

  const rightSideAllCardfun = (val, info) => {
    //console.log("value", val);
    //console.log("infoooo", info);
    if (info && val) {
      setState((prevState) => ({
        ...prevState,
        rightSideAllCard: val,
        DoctorAndAppointmentSlotCard: val,
        selectedSlot: info,
        resetTimeSlot: null,
        iagree: false,
        consultationMode: info.info.isdirect
          ? {
            value: process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE,
            label: "Direct Consultation",
            _id: process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE,
          }
          : info.info.isvideo
            ? {
              value: process.env.REACT_APP_CODING_APMNT_VIDEO_CONSULT_MODE,
              label: "Video Consultation",
              _id: process.env.REACT_APP_CODING_APMNT_VIDEO_CONSULT_MODE,
            }
            : {},
      }));
    } else {
      setState((prevState) => ({
        ...state,
        rightSideAllCard: val,
        DoctorAndAppointmentSlotCard: val,
        resetTimeSlot: null,
        iagree: false,
        isMultipleAppointment: false,
        multiApppointmentInfo: {
          appointments: {
            startsOn: {
              date: "",
              slotSelect: {},
              milli: 0,
            },
            preferrence: {
              preferredDays: [],
              preferredWeeks: [],
            },
            occurence: {
              occurrenceHandle: "NoOfDays",
              occurrenceValue: 1,
            },
            reccurance: {
              reccuranceValue: 1,
              reccuranceType: {
                label: "Days",
                value: process.env.REACT_APP_FREQUENCY_UOM_DAYS,
              },
            },
          },
          info: {},
          data: [],
          slotObj: {},
        },
      }));
    }
  };

  // Rendering

  // console.log("DoctorListingData", doctorList);
  return (
    <Div id="doctor_listing_parent_div" className="light-background-color">
      <Container
        id="doctor_listing_parent_container"
        key={"0"}
        name="container"
        fluid={true}
        style={{
          backgroundColor: "#F3F4F7",
          padding: "0px",
          width: "100%",
        }}
      >
        {/* <Overlay
              id="doctor_listing_consultation_overlay"
              open={state.openCloseHeader}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            //  onClose={(e) => props.handleClick(e)}
            >
              <div  style={{ width: "100%" }}>
                <AppointmentBookingSearch
                  // parent_id={"doctor_listing"}
                  // hideBackground={true}
                  // onValueChange={props.getDocterListingDetails}
                  // onHeaderClicked={props.onHeaderClicked}
                  // values={location?.state || false}
                  // indexToShow={state.indexToShow}
                />
              </div>
            </Overlay> */}
        <AppHeader
          parent_id={"doctor_listing"}
          IsModifyUser={
            IsModifyUser
            //  || !!props?.location?.state?.referral
          }
          onHeaderClicked={props?.onHeaderClicked}
          values={location?.state}
        />
        {/* <Row
          id="doctor_listing_parent_row"
          key={"0"}
          alignItems="stretch"
          direction="row"
          justify="flex-start"
          spacing="2"
          style={{ backgroundColor: "", width: "100%" }}
        >
          <Overlay
            id="doctor_listing_consultation_overlay"
            open={state.openCloseHeader}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            // onClose={() => this.onHeaderClicked()}
            onClose={(e) => handleClick(e)}
          >
            <div ref={overlayNode} style={{ width: "100%" }}>
              <AppointmentBookingSearch
              // parent_id={"doctor_listing"}
              // hideBackground={true}
              // onValueChange={this.getDocterListingDetails}
              // onHeaderClicked={this.onHeaderClicked}
              // values={this.props?.location?.state || false}
              // indexToShow={this.state.indexToShow}
              />
            </div>
          </Overlay>
          <AppHeader
            parent_id={"doctor_listing"}
            // IsModifyUser={
            //   IsModifyUser || !!this.props?.location?.state?.referral
            // }
            // onHeaderClicked={this.onHeaderClicked}
            // values={this.props?.location?.state}
          />
        </Row> */}

        <Row
          id="doctor_listing_appointment_row"
          key={"1"}
          alignItems="stretch"
          direction="row"
          justify="flex-start"
          spacing="2"
        >
          {/* Doctor Listing & Available Slots */}
          <Col
            id="doctor_listing_doctor_available_col_one"
            key={"0"}
            name="qdm-col-12"
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            textAlign="left"
            lg="8"
            xs="12"
            md="8"
            sm="12"
            style={{ padding: "0px", backgroundColor: "#F3F4F7" }}
          >
            <Col
              id="doctor_listing_doctor_available_col"
              key={"0"}
              name="qdm-col-12"
              alignItems="stretch"
              direction="row"
              justify="space-between"
              textAlign="left"
              lg="12"
              xs="12"
              md="12"
              sm="12"
              style={{
                padding: "16px 16px 16px 36px",
                backgroundColor: "#F3F4F7",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Div
                id="doctor_listing_doctor_available_div"
              // style={{ display: "flex", alignItems: "center" }}
              >
                <Text
                  id="doctor_listing_doctor_available_text"
                  style={{ margin: "0px", fontSize: 16 }}
                  className={"pc_semibold"}
                >
                  {IsModifyUser ? (
                    <Div id="doctor_listing_appointment_div">
                      <Text
                        style={{
                          margin: "0px",
                          fontSize: 16,
                        }}
                        className={"pc_semibold"}
                      >
                        {newBook
                          ? fromCloseConsultation
                            ? "Followup Booking"
                            : "Book Again"
                          : "Modify Appointment"}
                      </Text>
                      <br />
                      <span
                        id="doctor_listing_appointment_span"
                        style={{
                          color: "#B6B6B6",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                        className={"pc_regular"}
                      >
                        {newBook
                          ? fromCloseConsultation
                            ? "Book a Followup appointment"
                            : "Book a new appointment."
                          : "If you like to change the schedule. You can change Here."}
                      </span>
                    </Div>
                  ) : (
                    "Beds/Doctors Available"
                  )}
                </Text>

                {!IsModifyUser && (
                  <Text
                    id="doctor_listing_data_text"
                    style={{
                      marginLeft: "8px",
                      marginTop: "2px",
                      fontSize: 16,
                      color: "#6F6F6F",
                    }}
                    className="pc_regular"
                  >
                    ({state?.data?.length})
                  </Text>
                )}
              </Div>

              {!IsModifyUser && (
                <AppointmentBookingFilter
                  getDoctrlistDataFromFilter={getDoctrlistDataFromFilter}
                />
              )}
            </Col>
            <Div
              id="doctor_listing_doctorcard_div"
              style={{
                height: "calc(100vh - 148px)",
                overflowY: "overlay",
                padding: "0px 16px 0px 36px",
                display: state.loading ? "flex" : "block",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {console.log("isLoading", state?.loading || doctorList?.loading)}
              {console.log("stateData", state?.data)}
              <Loading loading={state?.loading || doctorList?.loading}>
                {state?.data?.length > 0 ? (
                  state.data &&
                  state.data.map((d, key) =>
                    !d ? null : (
                      <AvailableDoctorsWithSlots
                        resource_id={d?.resource_id}
                        parent_id={"doctor_listing_" + key}
                        handleChange={changeState}
                        resetMulti={resetMulti}
                        data={d}
                        keydata={key}
                        selectedDate={state.applyedDate}
                        selectedSlot={state.selectedSlotId}
                        selectedSlotId={state.selectedSlotId}
                        selectedSlotOrd={state.selectedSlotOrd}
                        oldAppointment={location?.state?.appointMentInfo || {}}
                        // orgId={this?.location?.state?.hospital?.[0]?.value || 0}
                        values={state}
                        handleOpenProfile={handleOpenProfile}
                        rightSideAllCardfun={rightSideAllCardfun}
                        rightSideAllCard={state.rightSideAllCard}
                        totlenght={state?.data?.length}
                      />
                    )
                  )
                ) : (
                  <div
                    style={{ padding: "0px 16px 16px 36px", fontWeight: 500 }}
                  >
                    No result found
                  </div>
                )}
              </Loading>
            </Div>
          </Col>
          <AppointmentBookingSideMenu />
          {/* Appointment Booking Detailis */}
          <Col
            id="doctor_listing_parent_col"
            key={"1"}
            name="qdm-col-12"
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            textAlign="left"
            lg="4"
            xs="12"
            md="4"
            sm="12"
            style={{
              backgroundColor: "#F3F4F7",
              padding: "0px",
            }}
          >
            <div style={{ paddingTop: "16px" }}>
              <AppointmentDetails
                state={state}
                flag={flag}
                setFlag={setFlag}
                setState={setState}
                location={location}
                reasonForAppointment={state?.reasonForAppointMent?.payload}
                reasonForApptCancelModify={
                  state?.reasonForApptCancelModify?.data
                }
                apptypecode={location?.state?.apptypecode}
              ></AppointmentDetails>
            </div>
          </Col>
        </Row>
      </Container>
    </Div>
  );
};

// export default withAppBar(AppointmentBooking);
export default AppointmentBooking;
