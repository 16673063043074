import React, { useContext, useEffect, useState } from "react";
import { NewCard, WorkFlowComponent } from "./src";
import {
  actionButtonClick,
  AlertProps,
  axiosCall,
  checkWithCasbin,
  epochToDatetime,
  fetchData,
  getAge,
  getCalculatedAge,
  getFacilityId,
  getGlobalValueFrom_LclStrg_And_QryStrg,
  getImgUrl,
  getUtcTime,
  makeName,
  personRequests,
  RefundQueueRejectTabs,
  urls,
} from "../../utils";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { AlertContext, BackdropContext, drawerContext } from "../../context";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import MultiBookComp from "../../components/multibook/multiBookComp";
import { SideMenu } from "qdm-component-library";
import UploadFiles from "../../components/queueCard/fileUpload";
import Commonsidebar from "../../components/commonsidebar";
import SampleFormPage from "../../components/queueCard/sampleForm";
import MuiDrawer from "../../components/drawer.js";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import withAppBar from "../../hoc/withAppBar";
import SearchPatient from "../dashboard/searchPatient/searchPatient.js";
import { getLayout } from "../dashboard/functions.js";
import NursingProcedure from "../../components/queueCard/nursingProcedure.js";
import CloseIcon from "@mui/icons-material/Close";
function Queue(props) {
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  // work flow states
  const [QueueTypeList_wf, setQueueTypeList_wf] = React.useState([]); //qur
  const [stateList_wf, setStateList_wf] = React.useState([]);
  const [selQueueData_wf, setSelQueueData_wf] = useState([]);
  const [tabValue_wf, setTabValue] = React.useState(0);
  const [selectedQueue_wf, setSelectedQueue_wf] = React.useState({});

  const [selCardData, setSelCardData] = useState({}); // totalData
  const [selCardActionData, setSelCardActionData] = useState({});
  // This component states
  const [currentIds, setCurrentIds] = React.useState({
    queueId: "",
    scenarioId: "",
  });

  // component render states
  // multibook state
  const [multiBookOpen, setMultiBookOpen] = React.useState(false);
  const [mBData, setMbData] = React.useState({});
  // upload files state
  const [comp_Upload_files, setComp_Upload_files] = React.useState([]);
  const [comp_Upload_State, setComp_Upload_State] = React.useState({
    openUpload: false,
    uploadData: {},
  });
  // common side bar
  const [comp_SideBar_State, setComp_SideBar_State] = React.useState({
    open: false,
    headingName: "",
    reasonType: "",
    isCancellationType: false,
  });
  //specimen component
  const [comp_specimen_state, setComp_specimen_state] = React.useState({
    open: false,
  });
  
  const [comp_administer_state, setComp_administer_state] = React.useState({
    open: false,
  });
  const [selectedSpeciment, setselectedSpeciment] = React.useState();
  const [selectedAdminstrator, setselectedAdminstrator] = React.useState();
  const [selectedqueuevalue, setselectedqueuevalue] = React.useState();
  const [state, setState] = useState({});
  const [permission1, setPermission] = React.useState({});
  const [permissionData, setPermissionData] = React.useState([]);
  const [refundDetailsOpen, setRefundDetailsOpen] = useState(false);
  const [refundValue, setRefundValue] = useState({});
  const handleTabChange_WF = (event, newValue) => {
    setTabValue(newValue);
  };

  const QueueTypeListDataFormatter = (AllQueueList = []) => {
    const queuetype = [];
    for (const item of AllQueueList) {
      queuetype.push({
        id: item?.repodetails?._id,
        value: item?.repodetails?.queuename,
      });
    }
    return queuetype;
  };
  const StateListDataFormatter = (selectedQueue = []) => {
    const stateList = [];
    try {
      for (
        let i = 0;
        i < selectedQueue?.scenarioChildren[0].stateChildren.length;
        i++
      ) {
        const item = selectedQueue?.scenarioChildren[0].stateChildren[i];
        if ( [ process.env.REACT_APP_LAB_ORDER_QUEUE_ID].includes(selectedQueue?.repodetails?._id )) {
          stateList.push({
            id: item.repoid.unique_id,
            index: i,
            value: item.repoid.rep_name,
            isMultiple: true,
            isDuplicateCheck:true
          });
        } else {
          stateList.push({
            id: item.repoid.unique_id,
            index: i,
            value: item.repoid.rep_name,

          });
        }
      }
      return stateList;
    } catch (error) {
      console.error(error);
      return stateList;
    }
  };

  const QueueDataFormatter = (datas) => {
    let list = [];
    datas?.map((item) => {
      let val= JSON.parse(JSON.stringify(item));
      if (item.data && item.data.length > 0) {
        const fieldList = {};
        let data = [];
        item.data.map((d) => {
          let cdata = JSON.parse(JSON.stringify(d));
          let action =[];
          const fields = d.fields;
          for (const field of fields || []) {
            cdata[field.name] =
              field.label === "appdate"
                ? epochToDatetime(field.value)
                : field.value;
          }
          d?.action.map((a) => {
            const actionPermi =permissionData.filter( x => x.repoid?.unique_id == a._id);
            if(actionPermi.length>0){
              action.push(a);  
            }
          })
          cdata.action = action;
          data.push(cdata);
        })
        val.data = data;
      }
      list.push(val);      
    });
    return list;
  };

  const SelQueueDatas = useSelector((state) => state?.auth?.get_queuetemplate);
  const getQueueListData = useSelector(
    (state) => state?.auth?.get_all_queue_list
  );
  const StateandQueueDateJoinerfn = (stateList, particularQueueData) => {
    let data = [];
    stateList?.forEach((l, i) => {
      let obj = l;
      const hasSpecificQueueId = particularQueueData?.some((x) => x?.queueid === process.env.REACT_APP_DIALYSIS_QUEUE_ID); 
      if (hasSpecificQueueId) {
        obj.data = particularQueueData;
      } else {
      obj.data = particularQueueData?.filter((x) => x?.statesid === l?.id);
      }
      data.push(obj);
    });
    return data;
  };
  const GetSelectedQueueDataCall = async (
    queueId,
    scenarioId,
    FromDate,
    toDate
  ) => {
    return await dispatch(
      actions.GET_QUEUE_TEMPLATE({
        queueIds: queueId,
        scenarioId: scenarioId,
        facilityid: decodedUserinfo?.facilityid,
        locationid: decodedUserinfo?.locationid,
        practitionerid: decodedUserinfo?.practitionerid,
        practitioneroleid: decodedUserinfo?.roleid,
        startdate: FromDate
          ? FromDate
          : [
            "queue/10018",
            "queue/10022",
            "queue/10025",
            // "queue/10026",
          ].includes(queueId)
            ? getUtcTime(moment().startOf("day"))
            : 0,
        enddate: toDate
          ? toDate
          : [
            "queue/10018",
            "queue/10022",
            "queue/10025",
            // "queue/10026",
          ].includes(queueId)
            ? getUtcTime(moment().endOf("day"))
            : 0,
      })
    );
  };
  const GetQueueListCall = async () => {
    return await dispatch(actions.GET_ALL_QUEUE_LIST());
  };

  const loadCustomCard = (val) => {
    // console.log(val, "val");
    if (val && val.data && val.data.length > 0) {
      return <NewCard actionButtonClick={CardBtnClick_WF} patient={val} />;
    } else {
      return <></>;
    }
  };

  const RefreshLatestQueue = async (actionVal , queueVal) => {
    let fetched_SelectedQueueData = await GetSelectedQueueDataCall(
      currentIds.queueId,
      currentIds.scenarioId
    );
    let selectedQueueData = StateandQueueDateJoinerfn(
      stateList_wf,
      fetched_SelectedQueueData?.payload?.data?.response
    );
    // setTabValue((n) => n +  1);
      setTabValue((n) => {
    let actionId = ""
    if(RefundQueueRejectTabs?.rejectActionId === actionVal?._id){
      actionId = RefundQueueRejectTabs?.rejectedTabId
    } 
    const matchedState = stateList_wf?.find(
      s => s?.id === actionId
    );
    return matchedState ? matchedState.index : n + 1;
  });
  
    let sqdata =QueueDataFormatter(selectedQueueData);
    setSelQueueData_wf(sqdata);
  };
  const filterClick = async (val) => {
    let fetched_SelectedQueueData = await GetSelectedQueueDataCall(
      currentIds.queueId,
      currentIds.scenarioId,
      val?.fromDate,
      val?.toDate
    );
    let selectedQueueData = StateandQueueDateJoinerfn(
      stateList_wf,
      fetched_SelectedQueueData?.payload?.data?.response
    );
    let sqdata = QueueDataFormatter(selectedQueueData);
    setSelQueueData_wf(sqdata);
  };
  const alertPopup = (type, msg) => {
    
    alert.setSnack({
      open: true,
      severity: type,
      msg: msg,
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
      tone: true,
    });
  };
  const HandleSelectQueue_WF = async (val) => {
    setSelectedQueue_wf(val);
    let selectedQueue = getQueueListData?.data?.result?.filter(
      (v) => v?.repodetails?._id === val.id
    );
    let selectedScenario = selectedQueue?.[0]?.scenarioChildren.filter(
      (li) => li.permsnwrite === true
    );
    let formted_StateList = StateListDataFormatter(selectedQueue?.[0]);
    setStateList_wf(formted_StateList);
    let fetched_SelectedQueueData = await GetSelectedQueueDataCall(
      selectedQueue?.[0]?.repodetails?._id,
      selectedScenario?.[0]?.repodetails?._id
    );
    let selectedQueueData = StateandQueueDateJoinerfn(
      formted_StateList,
      fetched_SelectedQueueData?.payload?.data?.response
    );
    setCurrentIds({
      queueId: selectedQueue?.[0]?.repodetails?._id,
      scenarioId: selectedScenario?.[0]?.repodetails?._id,
    });
    setTabValue(0);
    sessionStorage.setItem("currentQueue", JSON.stringify(selectedQueue?.[0]));
    
    let sqdata =QueueDataFormatter(selectedQueueData);
    setSelQueueData_wf(sqdata);
  };

  async function PerformUserAction({ ticketId = "", userAction = "" }) {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });
    try {
      let result = await dispatch(
        actions.PERFORM_USER_ACTION({
          ticketId: ticketId,
          userAction: userAction,
        })
      );

      if (result?.payload?.data?.error) {
        alert.setSnack({
          ...alert,
          open: true,
          msg:
            result?.payload?.data?.message ||
            result?.payload?.data?.Response?.[0]?.errorMessage ||
            "something went wrong",
          severity: "error",
        });
      } else {
        RefreshLatestQueue();
        alert.setSnack({
          ...alert,
          open: true,
          msg: "success !",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error?.message);
      alert.setSnack({
        ...alert,
        open: true,
        msg: error?.message,
        severity: "error",
      });
    }
    backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
  }
  // component render fns
  // multibook
  const handleMultiBookData = (data) => {
    if (data) {
      setMbData(data);
    }
    setMultiBookOpen(!multiBookOpen);
  };
  // upload
  const handlesidebarCancelbooking = (id) => {
    setComp_Upload_State({ openUpload: false });
  };
  const uploadDocument = (files) => {
    setComp_Upload_files(files);
  };
  const uploadImage = async (image) => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const data = {
      ReferralTrxId: selCardData?.inputdoc?.referralTrxId, //"ReferralTrx/10050",
      attachments: [
        {
          _id: "",
          fileName: image?.viewFileName,
          fileid: "",
          filetype: image?.fileType,
          base64string: image?.openFile,
        },
      ],
      ticketId: comp_Upload_State?.uploadData?.ticketId,
      practitionerId: decodedUserinfo?.practitionerid,
      practitionerRoleId: decodedUserinfo?.roleid,
    };
    const reportGenerate = await axiosCall(
      data,
      process.env.REACT_APP_SAVE_REFERRAL_ATTACHMENT,
      header
    );
    handlesidebarCancelbooking();
    if (!reportGenerate?.error) {
      setComp_Upload_State({
        ...comp_Upload_State,
        openUpload: false,
        uploadData: {},
      });
      setComp_Upload_files([]);
      RefreshLatestQueue();
    }
  };

  // sidebar
  async function Comp_SideBar_HandleSubmit(data, TotActionData) {
    setComp_SideBar_State({
      open: false,
      headingName: "",
      reasonType: "",
      isCancellationType: false,
    });
    try {
      let fetchVal = await actionButtonClick(
        selCardActionData?.action || TotActionData?.action,
        selCardActionData?.apiEndPoint || selCardActionData?.apiendpoint || TotActionData?.apiEndPoint || TotActionData?.apiendpoint,
        selCardActionData?.backendCall || selCardActionData?.backEndCall || TotActionData?.backendCall || TotActionData?.backEndCall,
        selCardData?.ticketId || data?.ticketId,
        data?.reason?.value, //app booking cancel reason
        selCardData?.queueMetaData?.queuename?.queuename || data?.queueMetaData?.queuename?.queuename,
        data?.reason?.value, // no show reason
        data?.otherReason, // app booking n no show others
        data?.reason, // cancel check in reason full object
        data?.otherReason, // cancel check in others
        data?.cacellationType, // app booking type
        selCardData?.inputdoc?.appointmentid || data?.inputdoc?.appointmentid
      );
      if (!fetchVal.error) {
        RefreshLatestQueue(selCardActionData || TotActionData,selCardData?.queueMetaData?.queuename?.queuename || data?.queueMetaData?.queuename?.queuename);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          msg: fetchVal?.response?.[0]?.message,
          severity: "error",
        });
      }
    } catch (error) {
      alert.setSnack({
        ...alert,
        open: true,
        msg: error?.message,
        severity: "error",
      });
    }
  }

  const NursingProcedureOpen = async (patientData , l) => {
    if (patientData) {
      setselectedAdminstrator(patientData);
      setselectedqueuevalue(l)
    }

    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const orgid = decodedUserinfo.facilityid;

    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });
    Promise.all([
      dispatch(actions.ADMINISTRATED_BY(orgid)),
      dispatch(actions.GET_ALL_MASTERS("")),
      
    ])
      .then((res) => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
        setComp_administer_state({
          ...comp_administer_state,
          open: true,
        })
      })
      .catch((err) => {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      });
  };
  const nursingProcedureClose = () => {
    setComp_administer_state({
      ...comp_administer_state,
      open: false,
    })  };
    const sampleFormOpen = async () => {
  
      // setState({
      //   spicemanCollectedData: {
      //     ticketId: ticketId,
      //     queueId: queueId,
      //     personId:personId,
      //     encounterId:encounterId,
      //     patientId:patientId
  
      //   },
      // });
      const userInfo = localStorage.getItem("UserInfo");
      const decodedUserinfo = jwtDecode(userInfo);
      const orgid = decodedUserinfo.facilityid;
      backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });
      Promise.all([
        dispatch(actions.REGISTERED_BY(orgid)),
        dispatch(actions.SAMPLE_COLLECTED(decodedUserinfo?.practitionerid)),
        dispatch(actions.BRANCH_CODE(orgid)),
      ])
        .then((res) => {
          backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
  
          setComp_specimen_state({
            ...comp_specimen_state,
            open: true,
          });
        })
        .catch((err) => {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went wrong !",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        });
    };
  function ComponentRender({ totalData = {}, TotActionData = "", type }) {
    let totalDataToPatientData = totalData?.[0]?.totalData;
    switch (type) {
      case "multibook":
        setMultiBookOpen(true); //FIXME -
        setMbData(totalDataToPatientData);
        break;
      case "upload":
        setComp_Upload_State({
          ...comp_Upload_State,
          openUpload: true,
          uploadData: {
            action: TotActionData?.action,
            apiEndPoint: TotActionData?.apiendpoint,
            backendCall: TotActionData?.backEndCall,
            ticketId: totalDataToPatientData?.ticketid || totalDataToPatientData?.ticketId,
            queue: totalDataToPatientData?.queue || totalDataToPatientData?.queueMetaData?.lastQueue?.lastQueue,
            queueId: totalDataToPatientData?.queueId || totalDataToPatientData?.queueid,
          },
        }); //FIXME -
        break;
      case "sidebar_cancel":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "CANCELREASON",
          headingName: "Cancel",
        });
        break;
      case "sidebar_referral_onhold":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "REFERRALONHOLDREASON",
          headingName: "On Hold",
        });
        break;
      case "sidebar_referral_reject":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "REFERRALREJECTREASON",
          headingName: "Reject",
        });
        break;
      case "sidebar_cancelAdministerReason":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "CANCELADMINISTERREASON",
          headingName: "Cancel Administration",
        });
        break;

      case "sidebar_pic_cancel":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "PICCANCELREASON",
          headingName: "Cancel",
        });
        break;
      case "sidebar_nephrologist_cancel":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "NEPROLOGISTCANCELREASON",
          headingName: "Cancel",
        });
        break;
      case "sidebar_dialysis_noShow":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "DIALYSISNOSHOWREASON",
          headingName: "No Show",
        });
        break;
      case "sidebar_onHold":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "CANCELREASON",
          headingName: "On Hold",
        });
        break;
      case "sidebar_dialysis_cancel":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "DIALYSISCANCELREASON",
          headingName: "Cancel",
          isCancellationType: TotActionData?.action === "CANCEL" ? true : false,
        });
        break;
      case "sidebar_cancelCheckIn":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "CANCELCHECKINREASON",
          headingName: "Cancel Check-In",
        });
        break;
      case "sidebar_pause":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "PAUSEREASON",
          headingName: "Pause",
        });
        break;
      case "sidebar_refundReject":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: totalDataToPatientData?.field5 === "Receipt Refund"? "RCPTREFUNDREJECTREASON" : "CANCELREASON",
          headingName: "Refund Reject",
        });
        break;
      case "sidebar_reject":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "CANCELREASON",
          headingName: "Reject",
        });
        break;
      case "sidebar_noShow":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: "NOSHOWREASON",
          headingName: "No Show",
        });
        break;
      case "dialog_specimenCollected":
        setselectedSpeciment(totalData);
        sampleFormOpen()
        // setComp_specimen_state({
        //   ...comp_specimen_state,
        //   open: true,
        // });
        break;
      case "dialog_nurse_procudere_administer":
        NursingProcedureOpen(totalDataToPatientData ,TotActionData )
        break;
      default:
      // setMultiBookOpen(true);
      // setMbData(totalData);
      // setComp_SideBar_State({
      //   ...comp_SideBar_State,
      //   open: true,
      //   reasonType: "CANCELCHECKREASON",
      //   headingName: "Cancel Check-In",
      // });
      // setComp_Upload_State({
      //   ...comp_Upload_State,
      //   openUpload: true,
      //   uploadData: {
      //     action: TotActionData?.action,
      //     apiEndPoint: TotActionData?.apiendpoint,
      //     backendCall: TotActionData?.backEndCall,
      //     ticketId: totalData?.ticketid || totalData?.ticketId,
      //     queue: totalData?.inputdoc?.queueType,
      //     queueId: totalData?.queueId,
      //   },
      // }); //FIXME -

      // setComp_SideBar_State({
      //   ...comp_SideBar_State,
      //   open: true,
      //   reasonType: "CANCELCHECKREASON",
      //   headingName: "Cancel Check-In",
      // });
      // break;
    }
  }

  function NavigateToScreen({
    ticketId = "",
    userAction = "",
    totalData = {},
    queueName = selectedQueue_wf?.value,
  }) {
    //debugger
    let routingPath =
      userAction?.action === "EMR"
        ? "/patient_detail"
        : userAction?.action === "CHECK-IN"
          ? "/visit-registration"
          : userAction?.action === "UPDATETREATMENTPLAN" ? "/patient_detail" : "";
          
    navigate(routingPath, {
      state:{
        encounter_id: totalData?.inputdoc?.encounterid,
        personId: totalData?.personid,
        from: 1,
        patientId: totalData?.inputdoc?.patientid
          ? totalData?.inputdoc?.patientid
          : totalData?.inputdoc?.patientId,

        appointmentId: totalData?.inputdoc?.appointmentid,
        facilityId: totalData?.facilityid,

        fromQueue: true,
        idedit: false,
        Ispatient: true,
        isedit: false, //userAction?.action == "VISIT REGISTER" ? false : true,
        // isRevise: userAction?.action == "REVISE VISIT" ? true : false,
        patientData: totalData?.mobileNo,
        stateAction: userAction?.action,
        stateEndPoint: userAction?.apiendpoint,
        stateBackEndCall: userAction?.backEndCall,
        stateTicketid: ticketId,
        encounterId: totalData?.inputdoc?.encounterid,
        type: totalData?.inputdoc?.resource_type,
        pId: totalData?.inputdoc?.patientid
          ? totalData?.inputdoc?.patientid
          : totalData?.inputdoc?.patientId,
        app_id: totalData?.inputdoc?.encounterid,
        allCurrentData: totalData,
        queue: queueName,
        referralId: totalData?.inputdoc?.referralTrxId
          ? totalData?.inputdoc?.referralTrxId
          : totalData?.inputdoc?.referralid,
        mrnId: totalData?.field2,
        ticketId: ticketId,
        // nextRoute: AppRoutes.visitRegistration,
        isReferral:queueName === process.env.REACT_APP_REFERRAL_QUEUE ? true : false,
        isNephrologyOrPicQueue:(queueName === process.env.REACT_APP_NEPHROLOGIST_QUEUE || queueName ===  process.env.REACT_APP_PIC_REVIEW_QUEUE) ? true : false
      }
    });
  }
  // functioncaller fns
  const redirectBilling = async (totalData = {}, action = "") => {
    const facilityId = getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"});
    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);
    const endDate = new Date(currentDate);
    endDate.setHours(23, 59, 59, 999);
    const endEpochTime = getEpochTime(endDate);
    const queryData = personRequests.getDetailsAgainstPatientSearchByIsCashier(
      startEpochTime,
      endEpochTime,
      "",
      totalData?.totalData?.field2,
      "",
      "",
      "",
      "",
      "",
      facilityId,
      0,
      10
    );
    var lista = {};
    const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
    if (!SearchUserData || SearchUserData.length === 0) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Billing data not avaliable",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      SearchUserData?.forEach((v, i) => {
        Object.assign(lista, {
          idnumber: v?.identifier[0]?.value,
          idsourcecode: v?.identifier[0]?.code,
          address: v?.address,
          age: getCalculatedAge(v?.birthDate),
          gender: v?.gender,
          email:
            v?.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
              ?.value ?? "",
          name: makeName(v?.name?.[0] || {}) || "",
          id: v?.patmrn ?? "",
          dis: `${(v?.gender + ", " ?? "") +
            (v?.birthDate ? getAge(v?.birthDate) : "")
            }
              ${v?.telecom[0]?.value ? " | " + v?.telecom[0]?.value : ""}`,
          encounter: v?.encounter,
          mobileNumber:
            v?.telecom?.find((tel) => tel?.system === "CodingMaster/10473")
              ?.value ||
            v.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
              ?.value ||
            "",
          patientId: v?._id,
          img:
            getImgUrl(v?.photo?.[0]?.fileid) ||
            "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
        });
      });
      navigate(AppRoutes.billingDetails, {
        state: {
          personId: totalData?.totalData?.personid,
          patientId: totalData?.totalData?.inputdoc?.patientid
            ? totalData?.totalData?.inputdoc?.patientid
            : totalData?.inputdoc?.patientId,
          appointmentId: totalData?.totalData?.inputdoc?.appointmentid,
          encounter_id: totalData?.totalData?.inputdoc?.encounterid,
          encounterId: totalData?.inputdoc?.encounterid,
          ticketId: totalData?.totalData?.ticketId,
          type: totalData?.inputdoc?.resource_type,
          pId: totalData?.totalData?.inputdoc?.patientid
            ? totalData?.totalData?.inputdoc?.patientid
            : totalData?.totalData?.inputdoc?.patientId,
          app_id: totalData?.totalData?.info?._id,
          allCurrentData: totalData?.totalData,
          actionName: totalData?.action || action,
          ...lista,
        },
      });
    }
  };

  const NavigateToPatientRegistration = async ({
    totalData,
    isViewMode,
    isEditedMode,
  }) => {
    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: totalData?.inputdoc?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
      })
    );
    navigate(AppRoutes.patientRegistration, {
      state: {
        isViewMode,
        isEditedMode,
        data: patientInfo?.payload?.data,
        totalData: totalData,
      },
    });
  };
  const NavigateToPaymentCollection = async ({
    totalData,
    isRefund,
    action,
  }) => {
    navigate(AppRoutes.paymentCollections, {
      state: {
        patientid: totalData?.inputdoc?.patientid,
        _id: totalData?.inputdoc.refundid,
        ticketId: totalData?.ticketId,
        isRefund,
        action
      },
    });
  };
  //jasper_print_referral
  const printForm = async (totalData, queueId) => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const data = {
      reportid: process.env.REACT_APP_PRINT_REPORTID,
      inputparams: {
        "@patientid": totalData?.inputdoc?.patientId,
        "@orgid": decodedUserinfo?.facilityid,
      },
      result: [],
    };
    const reportGenerate = await axiosCall(
      data,
      process.env.REACT_APP_GENERATE_REPORT,
      header
    );
    if (!reportGenerate?.error) {
      window.location.href = reportGenerate?.downloadUrl;
      RefreshLatestQueue();
    }
  };
  const referralPrint = async (totalData, queueId) => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const data = {
      reportid: process.env.REACT_APP_REFERRAL_PRINT_REPORTID,
      inputparams: {
        "@patientid": totalData?.inputdoc?.patientId,
      },
      result: [],
    };
    const reportGenerate = await axiosCall(
      data,
      process.env.REACT_APP_GENERATE_REPORT,
      header
    );
    if (!reportGenerate?.error) {
      window.location.href = reportGenerate?.downloadUrl;
      // RefreshLatestQueue();
    }
  };
  const stringconvertedarray = (originalArray) => {
    const convertedArray = originalArray.map((element) => `'${element}'`);
    return `[${convertedArray.join(", ")}]`;
  };
  const printOrderRequestForm = async (totalData, queueId) => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const orgid = decodedUserinfo.facilityid;
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    let arraydata = [totalData];
    if (props?.selectedTicket?.length > 0) {
      arraydata = props?.SelectedTicketValue;
    }
    let payload = {
      "@patientid": "",
      "@encounterid": "",
      "@orderlineid": "",
      "@facilityid": orgid,
      "@tenantid": decodedUserinfo?.tenantid,
    };
    let orderlineid = [];
    arraydata.forEach((v) => {
      payload["@encounterid"] = v?.inputdoc?.encounterid;
      payload["@patientid"] = v?.inputdoc?.patientid;
      orderlineid.push(v?.inputdoc?.caorderlineid || "");
    });
    payload["@orderlineid"] = stringconvertedarray(orderlineid);
    const data = {
      reportid: process.env.REACT_APP_PRINT_ORDER_FORM_REPORTID,
      inputparams: payload,
      result: [],
    };
    try {
      const reportGenerate = await axiosCall(
        data,
        process.env.REACT_APP_GENERATE_REPORT,
        header
      );
      if (!reportGenerate?.error) {
        window.location.href = reportGenerate?.downloadUrl;
        // RefreshLatestQueue();
      }
    } catch (error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: error.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  function functionCaller({ data = {}, action = "", key = "", TotActionData }) {
    // let key = "";
    let totalData = data?.[0]?.totalData;
    switch (key) {
      case "billing":
        redirectBilling({ totalData: data, action: action });
        break;
      case "route_view_patient_registration":
        NavigateToPatientRegistration({ totalData: totalData, isViewMode: true });
        break;
      case "route_edit_patient_registration":
        NavigateToPatientRegistration({
          totalData: totalData,
          isViewMode: false,
          isEditedMode: true,
        });
        break;
      case "performUserAction_Refund":
        NavigateToPaymentCollection({
            totalData:totalData,
          isRefund: true,
          action: action,
        });
        break;
      case "referralQ_registered_jasper_print":
        printForm(totalData, currentIds.queueId);
        break;
      case "referralQ_scheduled_jasper_printForm":
        referralPrint(totalData, currentIds.queueId);
        break;
      case "labOrderQ_specimenCollected_jasper_printOrderRequestForm":
        printOrderRequestForm(totalData, currentIds.queueId);
        break;
      case "performUserAction_approve_treatment_plan":
          Comp_SideBar_HandleSubmit(totalData,TotActionData);
        break;
      case "performUserAction_approve_verbal_treatment_plan":
          Comp_SideBar_HandleSubmit(totalData,TotActionData);
        break;
      case "performUserAction_Refund":
          Comp_SideBar_HandleSubmit(totalData,TotActionData);
        break;
      default:
        // redirectBilling({ action, totalData });
        break;
    }

  }
  const CardBtnClick_WF = async (
    ticketId = [],
    TotActionData = "",
    data = [],
  ) => {
    
    let totalData = data?.[0]?.totalData;
    let action = TotActionData?.action;
    setSelCardData(totalData ?? data);
    setSelCardActionData(TotActionData);
    let type = TotActionData?.rendertype;
    switch (type) {
      case "performUserAction":
        PerformUserAction({
          ticketId: ticketId,
          userAction: action,
        });
        break;
      case "route":
        NavigateToScreen({
          ticketId: ticketId,
          userAction: TotActionData,
          totalData: totalData ?? data,
        });
        break;
      case "component":
        ComponentRender({ totalData: data, TotActionData: TotActionData, type: TotActionData?.rendername, });
        break;
      case "function":
        functionCaller({ data: data, action: action, key: TotActionData?.rendername, TotActionData: TotActionData });
        break;
      default:
        // functionCaller({totalData,action,key:"billing"})
        PerformUserAction({
          ticketId: ticketId,
          userAction: action,
        });
        // NavigateToScreen({
        //   ticketId: ticketId,
        //   userAction: TotActionData,
        //   totalData: totalData??data,
        // });
        // ComponentRender({ action, totalData,type:"sidebar_noShow" });
        break;
    }
  };
  const actionPermission = async(data) => {
    let action = [];
    data?.map((d) => {
      d?.scenarioChildren?.[0]?.stateChildren?.map((v) => {
        v?.actionChildren?.map((a)=> {
          action.push(a);
        })
      });
    });
    setPermissionData(action)
  };
  async function Init() {
    let FetchedList = await GetQueueListCall();
    let temp_QueueTypelist = FetchedList?.payload?.data?.result;
    let defaultQueueType = temp_QueueTypelist?.[0];
    let formted_queueTypeList = QueueTypeListDataFormatter(temp_QueueTypelist);
    let sessionValue = sessionStorage.getItem("currentQueue"); // string

    if (sessionValue) {
      defaultQueueType = JSON.parse(sessionValue);
    }
    let permittedScenario = defaultQueueType?.scenarioChildren.filter(
      (li) => li.permsnwrite === true
    );
    let formted_StateList = [];
    if (permittedScenario?.length > 0) {
      formted_StateList = StateListDataFormatter(defaultQueueType);
    }
    let fetched_SelectedQueueData = await GetSelectedQueueDataCall(
      defaultQueueType?.repodetails?._id,
      permittedScenario?.[0]?.repodetails?._id
    );
    let selectedQueueData = StateandQueueDateJoinerfn(
      formted_StateList,
      fetched_SelectedQueueData?.payload?.data?.response
    );
    let frmtedSelQueuetype = formted_queueTypeList.filter(
      (v) => v?.id === defaultQueueType?.repodetails?._id
    );
    sessionStorage.setItem("currentQueue", JSON.stringify(defaultQueueType));
    setQueueTypeList_wf(formted_queueTypeList);
    setStateList_wf(formted_StateList);
    let sqdata =QueueDataFormatter(selectedQueueData);
    setSelQueueData_wf(sqdata);
    // setSelQueueData_wf(QueueDataFormatter(selectedQueueData));
    
    setCurrentIds({
      queueId: defaultQueueType?.repodetails?._id,
      scenarioId: permittedScenario?.[0]?.repodetails?._id,
    });
    actionPermission(temp_QueueTypelist);

    setSelectedQueue_wf(frmtedSelQueuetype?.[0]);
  }

  const loadWithPermission = async() => {
    let fetched_SelectedQueueData = await GetSelectedQueueDataCall(
      currentIds.queueId,
      currentIds.scenarioId
    );
    let selectedQueueData = StateandQueueDateJoinerfn(
      stateList_wf,
      fetched_SelectedQueueData?.payload?.data?.response
    );
    let sqdata =QueueDataFormatter(selectedQueueData);
    setSelQueueData_wf(sqdata);
  }
  useEffect(() => {
    loadWithPermission();
  }, [permissionData]);

  useEffect(() => {
    Init();
    const initFun = async () => {
      let permission = await checkWithCasbin(["queueSideNav"]);
      let dataList = await checkWithCasbin(["dashboard"]);
      let layouts = await getLayout(dataList);
      setState({
        permission: dataList,
        initialLayout: layouts,
      });
      setPermission(permission)
    };
    initFun();
  }, []);

  const parent_id = "WorkFlowComponent";
  const { permission } = state;
  const handleRefundCloseModal = () => {
    setRefundDetailsOpen(false); 
  };
  const handleRefundInfo = async (refundId, statesid) => {    
    try {
      const response = await dispatch(
        actions.REFUND_GET_DETAILS({
          refundId:refundId, statesid
        })
      );
        setRefundValue({
          refundAmount: response?.payload?.data?.[0]?.refundamt,
          rejectReason: response?.payload?.data?.[0]?.reason?.ReasonDesc ?? response?.payload?.data?.[0]?.refundreason,
          reasonCmt: response?.payload?.data?.[0]?.reason?.ReasonCmt,
        });
        setRefundDetailsOpen(true)
      } catch (error) {

      }
  };
  return (
    <div>
      <WorkFlowComponent
        data={selQueueData_wf} //fun cal
        tabList={stateList_wf}
        queueList={QueueTypeList_wf}
        CardBtnClick={CardBtnClick_WF}
        PopupAlert={alertPopup}
        filterClick={filterClick}
        HandleSelectQueue={HandleSelectQueue_WF}
        dataName={"Patient"}
        colValue={3} //12
        avatarType="rounded" //"square"
        itemPerPage={selectedQueue_wf?.value ==  process.env.REACT_APP_DIALYSIS_QUEUE ? 8 : 6} //4
        loadCustomCard={loadCustomCard}
        showTabs={selectedQueue_wf?.value ==  process.env.REACT_APP_DIALYSIS_QUEUE ? false : true} //true
        // data={QueueList}
        isgetQueueListData={getQueueListData?.loading}
        isSelQueueDatas={SelQueueDatas?.loading || getQueueListData?.loading}
        HandleQueueSelect={HandleSelectQueue_WF}
        handleTabChange={handleTabChange_WF}
        tabValue={tabValue_wf}
        selectedQueue={selectedQueue_wf}
        handleRefundInfo={handleRefundInfo}
        handleRefundCloseModal={handleRefundCloseModal}
        refundDetailsOpen={refundDetailsOpen}
        refundValue={refundValue}
        RefreshLatestQueue={RefreshLatestQueue}
      />

      <MultiBookComp
        multiBookOpen={multiBookOpen}
        setMultiBookOpen={handleMultiBookData}
        mBData={mBData}
        selectedQueueid={currentIds?.queueId}
        RefreshLatestQueue={RefreshLatestQueue}
      />

      <SideMenu
        id="patient_dashboard_cancel_sidemenu"
        open={comp_Upload_State?.openUpload}
        direction="right"
        width={440}
        color="default"
        onClose={() => handlesidebarCancelbooking()}
        style={{ padding: 0 }}
      >
        <UploadFiles
          id={`${parent_id}_topnavbar_upload_uploadfiles`}
          uploadDocument={uploadDocument}
          files={comp_Upload_files}
          uploadImage={uploadImage}
          uploadData={comp_Upload_State?.uploadData}
          parent_id={parent_id}
        />
      </SideMenu>
      {permission?.write?.indexOf("searchPatient") > -1 && (
        <SearchPatient
          noNewPatient={!(permission?.write?.indexOf("newPatient") > -1)}
          parent_id={"dashboard_search_pid"}
        />
      )}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={comp_specimen_state?.open}
        onClose={() =>
          setComp_specimen_state({ ...comp_specimen_state, open: false })
        }
      >
        <DialogContent>
          <SampleFormPage
            sampleFormClose={() =>
              setComp_specimen_state({ ...comp_specimen_state, open: false })
            }
            selectedSpeciment={selectedSpeciment}
            SelectedTicketValue={props?.SelectedTicketValue}
            selectedTicket={props?.selectedTicket}
            RefreshLatestQueue ={RefreshLatestQueue}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={comp_administer_state?.open}
        onClose={() =>
          setComp_administer_state({ ...comp_administer_state, open: false })
        }
      >
        <DialogContent>
          <NursingProcedure
            nursingProcedureClose={nursingProcedureClose}
            selectedqueuevalue={selectedqueuevalue}
            selectedAdminstrator={selectedAdminstrator}
            RefreshLatestQueue ={RefreshLatestQueue}
          />
        </DialogContent>
      </Dialog>

      <MuiDrawer
        open={comp_SideBar_State?.open}
        close={() => {
          setComp_SideBar_State({ ...comp_SideBar_State, open: false });
        }}
      >
        <Commonsidebar
          reasonType={comp_SideBar_State?.reasonType}
          headingName={comp_SideBar_State?.headingName}
          cancellationType={{
            isCancellationType: comp_SideBar_State?.isCancellationType,
            headingName: "Cancellation Type",
            list: [
              {
                label: "Single",
                value: process.env.REACT_APP_CANCEL_APPMNT_TYPE_SINGLE,
              },
              {
                label: "Multiple",
                value: process.env.REACT_APP_CANCEL_APPMNT_TYPE_MULTIPLE,
              },
            ],
          }}
          Comp_SideBar_HandleSubmit={Comp_SideBar_HandleSubmit}
        />
      </MuiDrawer>
      <Dialog 
      open={refundDetailsOpen} 
      onClose={handleRefundCloseModal}
      PaperProps={{
        style: {
          minWidth: 300,
        },
      }}
      >
        <DialogTitle>Refund details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleRefundCloseModal}
          style={{ position: "absolute", right: 6, top: 6 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
         style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100px',
        }}
        >
          <React.Fragment>
            <Grid>
              <div>
                <Typography
                  variant="body2"
                  style={{
                    // color: "#EC6A49",
                    fontSize: "15px",
                    margin: "5px 0",
                    fontWeight: 600,
                  }}
                >
                  {`Refund amount : ${refundValue?.refundAmount ?? ""}`}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="body2"
                  style={{
                    // color: "#EC6A49",
                    fontSize: "15px",
                    margin: "10px 0",
                    fontWeight: 600,
                  }}
                >
                  {`Reason : ${
                    refundValue?.rejectReason ?? ""
                  }`}
                </Typography>
              </div>
              {refundValue?.reasonCmt && (
              <div>
                <Typography
                  variant="body2"
                  style={{
                    // color: "#EC6A49",
                    fontSize: "15px",
                    margin: "10px 0",
                    fontWeight: 600,
                  }}
                >
                  {`Comments : ${
                    refundValue?.reasonCmt ?? ""
                  }`}
                </Typography>
              </div>
              )}
            </Grid>
          </React.Fragment>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withAppBar(Queue);
