import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AppRoutes } from "./routes";
import SignIn from "../screens/authentication/signIn";
import ForgetPassword from "../screens/authentication/forgetPassword";
import ChangePassword from "../screens/authentication/changePassword";
import ResetPassword from "../screens/authentication/resetPassword";
import LinkExpired from "../screens/authentication/linkExpired";
import Dashboard from "../screens/dashboard";
import FinanceRegistration from "../screens/financeRegistration";
import VisitRegistration from "../screens/visitRegistration";
import ConfirmBooking from "../screens/confirmBooking";
import PatientRegistration from "../screens/patientRegistration/patientRegistration";
import PatientDetails, { PatientDetailWithoutAppBar } from "../screens/patientDetail";
import AppointmentBooking from "../screens/appointmentBooking";
import TestComponent from "../screens/testComponent";
import searchFacility from "../screens/searchFacility";
import PrivateRoute from "./privateRouter";
import PatientDashboard from "../screens/patientDashboard/patientDashboard";
import SearchFacility from "../screens/searchFacility";
import PicReviewSheet from "../components/reports/picReviewSheetReport";
import PatientReviewChartReport from "../components/reports/patientReviewChartReport";
import PanelReport from "../components/reports/panelReport";
import cashierBilling from "../screens/cashierBillingDetails";
import { CashierBillingSummary } from "../screens/cashierSummary";
import TreatmentPlans from "../screens/treatmentPlan";
import ClinicalInfo from "../screens/clinicalInfo";
import ReferralRegistration from "../screens/ReferralRegistration";
import CashierCollectionSummary from "../components/reports/cashCollectionsReport";
import DashboardLanding from "../screens/dashboardLanding";
import EpoConsumptionReport from "../components/reports/epoConsumptionReport";
import IFrames from "../screens/iFrames";
import PaymentCollections from "../screens/paymentCollections";
import AdvancePayment from "../screens/advancePayment";
import ObservationReport from "../components/reports/observationReport";
import BulkBooking from "../screens/bulkBooking/bulkBooking";
import MonthwiseAttendanceReport from "../components/reports/monthwiseAttendanceReport";
import Queue from "../screens/Queue/queue";
import PayerMapping from "../screens/payerChargeCodeMapping";
import SignInOTP from "../screens/pre-assemble/signinOTP";
import PatientSignIn from "../screens/pre-assemble/patientSignin";
import ProceedAsAGuest from "../screens/pre-assemble/proceedAsAGuest";
import VideoCall from "../screens/AVC/VideoCall";
import PatientAppointmentsListingPage from "../screens/pre-assemble/appointmentsListingPage";
import PatientDashBoard from "../screens/pre-assemble/patientDashboard";
import PreAssemPatientDashboard from "../screens/patientDashboard/pre-assem-patient-dashboard";
import Dashboards from "../screens/Dashboards";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path={AppRoutes.signIn} element={<SignIn />} />
        <Route
          exact
          path={AppRoutes.dashboard}
          element={
            <PrivateRoute path={AppRoutes.dashboard}>
              {/* <Dashboard /> */}
              <Dashboards /> 
              {/* <Queue/> */}
            </PrivateRoute>
          }
        />
         {/* <Route
          exact
          path={AppRoutes.oldDashboard}
          element={
            // <PrivateRoute path={AppRouter.oldDashboard}>
              <Dashboard /> 
           
            // </PrivateRoute>
          }
        />
         <Route
          exact
          path={AppRoutes.dashboards}
          element={
            // <PrivateRoute path={AppRouter.oldDashboard}>
              <Dashboards /> 
           
            // </PrivateRoute>
          }
        /> */}
        <Route
          exact
          path={AppRoutes.forgotPassword}
          element={
            <PrivateRoute path={AppRoutes.forgotPassword}>
              <ForgetPassword />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={AppRoutes.changePassword}
          element={
            <PrivateRoute path={AppRoutes.changePassword}>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={AppRoutes.resetPassword}
          element={
            <PrivateRoute path={AppRoutes.resetPassword}>
              <ResetPassword />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={AppRoutes.expired}
          element={
            <PrivateRoute path={AppRoutes.expired}>
              <LinkExpired />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={AppRoutes.patientRegistration}
          element={
            <PrivateRoute path={AppRoutes.patientRegistration}>
              <PatientRegistration />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={AppRoutes.PatientDashBoard}
          element={
            // <PrivateRoute path={AppRoutes.PatientDashBoard}>
              <PatientDashboard />
            // </PrivateRoute>
          }
        />
        <Route
          exact
          path={AppRoutes.financeRegistration}
          element={
            // <PrivateRoute path={AppRoutes.financeRegistration}>
              <FinanceRegistration />
            // </PrivateRoute>
          }
        />
        <Route
          exact
          path={AppRoutes.visitRegistration}
          element={
            // <PrivateRoute path={AppRoutes.visitRegistration}>
              <VisitRegistration />
            // </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path={AppRoutes.confirmBooking}
          Component={ConfirmBooking}
        ></Route>
        <Route
          exact
          path={AppRoutes.billingDetails}
          Component={cashierBilling}
        ></Route>
        <Route
          exact
          path={AppRoutes.billingSummary}
          Component={CashierBillingSummary}
        ></Route>
        <Route
          exact
          path={AppRoutes.patientDetail}
          Component={PatientDetails}
        ></Route>
         <Route
          exact
          path={AppRoutes.PatientDetailWithoutAppBar}
          Component={PatientDetailWithoutAppBar}
        ></Route>
        <Route
          exact
          path={AppRoutes.referralInRegistration}
          Component={ReferralRegistration}
        ></Route>
        <Route
          exact
          path={AppRoutes.searchFacility}
          element={
            // <PrivateRoute path={AppRoutes.searchFacility}>
              <SearchFacility />
          //  </PrivateRoute>
          }
        />
        <Route
          exact
          path={AppRoutes.appointmentBooking}
          element={
           // <PrivateRoute path={AppRoutes.appointmentBooking}>
              <AppointmentBooking />
           // </PrivateRoute>
          }
        />
        <Route
          exact
          path={AppRoutes.dashboardLanding}
          element={
            // <PrivateRoute path={AppRoutes.dashboardLanding}>
            <DashboardLanding />
            //</PrivateRoute>
          }
        />
        <Route
          exact
          path={AppRoutes.treatmentPlan}
          element={<TreatmentPlans />}
        />
        <Route path={AppRoutes.clinicalInfo} element={<ClinicalInfo />} />
        <Route
          exact
          path={AppRoutes.cashCollection}
          Component={CashierCollectionSummary}
        ></Route>
        <Route path={AppRoutes.iframes} element={<IFrames />} />
        <Route path={AppRoutes.cashCollectionReport} element={<CashierCollectionSummary/>} />
        <Route path={AppRoutes.observationReport} element={<ObservationReport/>} />
        <Route path={AppRoutes.monthwiseAttendanceReport} element={<MonthwiseAttendanceReport/>} />
        <Route path={AppRoutes.patientReviewChartReport} element={<PatientReviewChartReport/>} />
        <Route path={AppRoutes.panelReports} element={<PanelReport/>} />
        <Route path={AppRoutes.epoConsumptionReport} element={<EpoConsumptionReport/>} />
        <Route path={AppRoutes.picReviewSheet} element={<PicReviewSheet/>} />
        <Route path={AppRoutes.bulkBooking} element={<BulkBooking/>} />
        
				{/* VideoCall */}
				<Route path={AppRoutes.videoCall} element={<VideoCall/>} />
 

        <Route
          exact
          path={AppRoutes.paymentCollections}
          Component={PaymentCollections}
        ></Route>
        <Route
          exact
          path={AppRoutes.advancePayment}
          element={
            // <PrivateRoute path={AppRoutes.advancePayment}>
            <AdvancePayment />
            // </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path={AppRoutes.bulkBooking}
          element={
            // <PrivateRoute path={AppRoutes.advancePayment}>
            <BulkBooking></BulkBooking>
            // </PrivateRoute>
          }
        ></Route>
    
        <Route
          exact
          path={AppRoutes.payerMapping}
          element={
            // <PrivateRoute path={AppRoutes.payerMapping}>
            <PayerMapping />
            // </PrivateRoute>
          }
        />
         <Route
          exact
          path={AppRoutes.signInOTP}
          Component={SignInOTP}
        />
         <Route
          exact
          path={AppRoutes.proceesAsAGuest}
          Component={ProceedAsAGuest}
        />
         <Route
          exact
          path={AppRoutes.patientAppointmentsListingPage}
          Component={PreAssemPatientDashboard}
        />
         <Route
          exact
          path={AppRoutes.patientSignIn}
          Component={PatientSignIn}
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
