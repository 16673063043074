import React, { useState, useEffect } from "react";
import withAllContexts from "../../../hoc/withAllContexts";
import { DataGrid, GridEditCellValueParams } from '@mui/x-data-grid';
import { AlertProps } from "../../../utils";
import {
    Div,
    Row,
    Col,
    TextInput,
    Text,
    Image,
    Icons,
    Button,
    H6
} from "qdm-component-library";
import Box from '@mui/material/Box';
import { UIColor } from "../../../themes/theme";
 
const styles = {
    cardView: {
        // backgroundColor: "#F0F0F0",
        // border: "1px solid #E0E0E0",
        borderRadius: 21,
        padding: "8px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    flexCenter: {
        display: "flex",
        alignItems: "center",
    },
    text: {
        fontSize: 12,
        color: UIColor.secondaryText.color,
    },
    text1: {
        fontSize: 12,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "140px",
        textAlign: "right"
    },
 
    headerText: {
        fontSize: 14,
        color: "#055CA7",
        fontWeight: 600,
        paddingLeft: "8px",
        textTransform: "capitalize",
    },
    iconSize: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 26,
        height: 26,
        borderRadius: "50%",
        backgroundColor: "#d2e4f3",
        marginRight: 5,
    },
    dot: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        margin: "0px 8px",
        display: "inline-block",
    },
    userIcon: {
        width: 25,
        height: 25,
        borderRadius: 50,
        backgroundColor: "#E2EEFB",
        marginLeft: 10,
        padding: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    verticalDivider: {
        height: 25,
        backgroundColor: UIColor.tertiaryText.color,
        marginLeft: 10,
        width: 0.8,
    },
    avatar: {
        width: 34,
        height: 34,
        borderRadius: "50%",
        margin: 0,
        marginLeft: 5,
    },
    span: {
        fontSize: 10,
        color: "#323232",
    },
    span1: {
        fontSize: 10,
        color: UIColor.secondaryText.color,
    },
    colPadding: {
        padding: 0,
    },
    cancelBtn: {
        padding: "8px",
        fontSize: 14,
        marginRight: 10,
        borderColor: UIColor.lineBorderFill.color,
        color: UIColor.primaryColor.color,
        borderRadius: 8,
    },
    filledBtn: {
        backgroundColor: UIColor.primaryColor.color,
        borderColor: UIColor.primaryColor.color,
        padding: "8px",
        fontSize: 14,
        borderRadius: 8,
    },
    flexEnd: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    headerText: {
        fontSize: 16,
        fontWeight: 500,
        color: UIColor.secondaryButtonColor.color,
        paddingLeft: 8,
    },
    textColor: {
        fontFamily: "pc_regular",
        fontSize: 12,
        paddingLeft: 6,
        color: UIColor.tertiaryText.color,
    },
    textColor1: {
        fontFamily: "pc_regular",
        fontSize: 12,
        color: UIColor.tertiaryText.color,
    },
 
};
const AddResult = (props) => {
    const {
        TableBody,
        TableHeader,
        BodyData,
        selectedLab,
        componentdata,
        Resulted,
        Result,
        ButtonShow,
        isEdit,
        labResult
 
    } = props;
    const [data, setData] = useState([])
    const [Resultdata, setResultdata] = useState([])
    const [parentId, setParentId] = useState([])
    const [components, setComponents] = useState(componentdata)
    const [dataUpdate, setdataUpdate] = useState(true)
    let UpdatedValue = {}
    useEffect(() => {
        const unique = [...new Set(TableBody.map(item => item.parentordercatname))]
        setParentId(unique)
        let Arr = []
        unique.map(li => {
            let SelectedValue = TableBody.filter(value => value.parentordercatname == li)
            Arr[li] = SelectedValue
        })
        setResultdata(Arr)
        setData(TableBody)
    }, [])
    const RowEDit = (datass) => {
        UpdatedValue = datass
    }
 
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        if (!isEdit) {
            let selectedValue = BodyData.filter(li => li?.ordercatlogid == newRow?.ordercatlogid)
            if (selectedValue?.length > 0) {
                let Arr = data.filter(li => li.ordercatlogid != newRow?.ordercatlogid);
                let obj = {
                    id: newRow?.id,
                    Test: newRow?.Test,
                    Result: newRow?.Result,
                    Unit: newRow?.Unit,
                    NormalRange: newRow?.NormalRange,
                    Notes: newRow?.Notes,
                    ordercatlogid: newRow?.ordercatlogid,
                    parentordercatid: newRow?.parentordercatid
                }
                Arr.push(obj)
                setData(Arr.sort((a, b) => (a.id < b.id) ? -1 : 1))
                let flag = ""
 
                if (newRow?.Result >= selectedValue?.[0]?.ordermin && newRow?.Result <= selectedValue?.[0]?.ordermax) {
                    flag = "N"
                }
 
                else if (newRow?.Result < selectedValue?.[0]?.ordermin) {
                    flag = "L"
                }
                else if (newRow?.Result > selectedValue?.[0]?.ordermax) {
                    flag = "H"
                }
 
                let Array1 = components.filter(li => li.ordercatlogid != newRow?.ordercatlogid);
                let obj1 = {
                    orderlineid: selectedValue?.[0]?.orderlineid,
                    resultvalue: newRow?.Result,
                    flag: flag,
                    ordermax: selectedValue?.[0]?.ordermax,
                    ordermin: selectedValue?.[0]?.ordermin,
                    resultuom: selectedValue?.[0]?.unit,
                    parentordercatid: selectedValue?.[0]?.parentordercatid ? selectedValue?.[0]?.parentordercatid : "",
                    ordercatlogid: selectedValue?.[0]?.ordercatlogid,
                    remarks: newRow?.Notes,
                    method: ""
                }
                Array1.push(obj1)
                setComponents(Array1)
            }
 
        }
 
        else {
            let selectedValue = labResult.filter(li => li?.ordercatlogid == newRow?.ordercatlogid)
            if (selectedValue?.length > 0) {
                let Arr = data.filter(li => li.ordercatlogid != newRow?.ordercatlogid);
                let obj = {
                    id: newRow?.id,
                    Test: newRow?.Test,
                    Result: newRow?.Result,
                    Unit: newRow?.Unit,
                    NormalRange: newRow?.NormalRange,
                    Notes: newRow?.Notes,
                    ordercatlogid: newRow?.ordercatlogid,
                    parentordercatid: newRow?.parentordercatid
                }
                Arr.push(obj)
                setData(Arr.sort((a, b) => (a.id < b.id) ? -1 : 1))
                let flag = ""
 
                if (newRow?.Result >= selectedValue?.[0]?.components?.[0]?.ordermin && newRow?.Result <= selectedValue?.[0]?.components?.[0]?.ordermax) {
                    flag = "N"
                }
                else if (newRow?.Result < selectedValue?.[0]?.components?.[0]?.ordermin) {
                    flag = "L"
                }
                else if (newRow?.Result > selectedValue?.[0]?.components?.[0]?.ordermax) {
                    flag = "H"
                }
                let Array1 = components.filter(li => li.ordercatlogid != newRow?.ordercatlogid);
                let updatedValue = components.filter(li => li.ordercatlogid == newRow?.ordercatlogid);
                let update = ""
                if (updatedValue?.[0]?.ordervalue == newRow?.Result) {
                    update = ""
                }
                else if (updatedValue?.[0]?.ordervalue == "" && newRow?.Result != "" || updatedValue?.[0]?.ordervalue != newRow?.Result) {
                    update = "update"
                }
                else if (updatedValue?.[0]?.ordervalue != "" && newRow?.Result == "") {
                    update = "delete"
                }
                let obj1 = {
                    orderlineid: selectedValue?.[0]?.orderlineid,
                    resultvalue: newRow?.Result,
                    flag: flag,
                    ordermax: selectedValue?.[0]?.components?.[0]?.ordermax ? selectedValue?.[0]?.components?.[0]?.ordermax : 0,
                    ordermin: selectedValue?.[0]?.components?.[0]?.ordermin ? selectedValue?.[0]?.components?.[0]?.ordermin : 0,
                    resultuom: selectedValue?.[0]?.components?.[0]?.orderuom?._id ? selectedValue?.[0]?.components?.[0]?.orderuom?._id : "",
                    parentordercatid: selectedValue?.[0]?.components?.[0]?.parentordercatid?._id ? selectedValue?.[0]?.components?.[0]?.parentordercatid?._id : "",
                    ordercatlogid: selectedValue?.[0]?.ordercatlogid ? selectedValue?.[0]?.ordercatlogid : "",
                    remarks: newRow?.Notes,
                    method: "",
                    type: update
                }
                Array1.push(obj1)
                setComponents(Array1)
            }
        }
        return updatedRow;
    }
    const { parent_id } = props;
    return (
        <div id={`${parent_id}_laborder_parent_div`}>
             {BodyData?.length > 0 ?
            <Div
                id={`${parent_id}_laborder_header_div`}
                style={{
                    //padding: "6px 0px",
                    background: "#fff",
                    //borderRadius: "10px",
                }}
            >
                {parentId?.map(li => {
                    return (<div>
                        <Row id={`${parent_id}_formheader_parent_row`}>
                            <Col
                                id={`${parent_id}_formheader_parent_col`}
                                inLineStyles={{ padding: "0px 0px"}}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                //style={{ padding: "0px 0px !important"  }}
                            >
                                <Div
                                    id={`${parent_id}_formheader_parent_sub_div`}
                                    style={styles.cardView}
                                >
                                    <Div
                                        id={`${parent_id}_formheader_title_sub_div`}
                                        style={{ ...styles.flexCenter, flex: 1 }}
                                    >
                                        {li && (
                                            <Text
                                                id={`${parent_id}_formheader_text`}
                                                inLineStyles={styles.headerText}
                                            >
                                                {li}
                                            </Text>
                                        )}
                                    </Div>
 
                                </Div>
                            </Col>
                        </Row>
                        <Row
                            id={`${parent_id}_laborder_buttons_row`}
                            inLineStyles={{ background: "#fff", borderRadius: "10px" }}
                        >
                           
                                <Col
                                    id={`${parent_id}_laborder_buttons_col`}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    inLineStyles={{ padding: "0px 0px"}}
                                >
                                    {dataUpdate && (
                                        <DataGrid
                                            rows={Resultdata[li]}
                                            columns={TableHeader}
                                            onCellEditStop={RowEDit}
                                            processRowUpdate={processRowUpdate}
                                            hideFooter={true}
 
                                        />
                                    )}
                                </Col>
                            
                        </Row>
                    </div>)
                })}
 
                {ButtonShow &&
                    <Row
                        id={`${parent_id}_laborder_buttons_row`}
                        inLineStyles={{ background: "#fff", borderRadius: "10px" }}
                    >
                        <Col
                            id={`${parent_id}_laborder_buttons_col`}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            style={{ margin: "10px 0px" }}
                        >
                            <Div
                                id={`${parent_id}_laborder_buttons_div`}
                                inLineStyles={styles.flexEnd}
                            >
                                <Button
                                    id={`${parent_id}_performerdetails_Cancel_button`}
                                    onClick={() => props?.handleResultClose()}
                                    variant={"text"}
                                    style={styles.cancelBtn}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    id={`${parent_id}_performerdetails_Save_button`}
                                    variant={"outlined"}
                                    style={styles.filledBtn}
                                    onClick={() => props?.saveLabResult(components)}
                                >
                                    {isEdit ? "Update" : "Save"}
                                </Button>
                            </Div>
                        </Col>
                    </Row>
                }
                </Div>
                :
                <Div id={`${parent_id}_search_speciality_data_found_div`}>
                    <H6
                        style={{
                            margin: 0,
                            textAlign: "center",
                            color: "#000000",
                            fontSize: 14,
                            fontWeight: "600",
                        }}
                    >
                        No Data Found
                    </H6>
                </Div>
               
                }
           
        </div>
    )
 
}
 
export default withAllContexts(AddResult);