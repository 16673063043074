import React, { useContext, useEffect, useState } from "react";
import actions from "../../redux/actions";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { withStyles } from "@material-ui/core";
import { Div, Text, Row, Button, Col, Divider } from "qdm-component-library";
import withAppBar from "../../hoc/withAppBar";
import {
  //Allergies,
  PatientCareTypeCard,
  PatientStatusCard,
  TopHeader,
} from "../../components";
import { PatientInfoCard, ReferralListView } from "../../components";
import { ActionItems } from "./actionItems";
import { MenuList } from "./menuList";
import PatientChart from "./patientChart";
import WidgetsList from "./widgets";
import { AllergyForm } from "./allergyFlows";
import "./styles.css";
import { DiagnonsisForm } from "./diagnosisFlow";
import { CheifComplaintsForm } from "./cheifComplaintsFlow";
import { MedicationForm } from "./medicationFlows";
import { LabOrderForm } from "./labOrderFlow";
import SignOffCard from "./signOffComp/index";
import withAllContexts from "../../hoc/withAllContexts";
import {
  getUserInfo,
  CC_Json_Generator,
  _calculateAge,
  DIAGNOSIS_Json_Generator,
  Treatment_Json_Generator,
  Rad_Json_Generator,
  updateStatus,
  AlertProps,
  LabOrder_Json_Generator,
  fetchUserInfo,
  getReccuranceDates,
  utcTOLocal,
  getUtcTime,
  getCalculatedAge,
  makeName,
  baseURL,
  dateGiverForFollowUp,
  urls,
  __tenantid__,
  axiosCall,
  personRequests,
  getFacilityId,
  fetchData,
  getImgUrl,
  dbName,
  getTenantId,
  isObjectEmpty,
  getGlobalValueFrom_LclStrg_And_QryStrg,
  WhichFlow,
  FnExecutorBasedOnFLow,
} from "../../utils";
import { UIColor } from "../../themes/theme";
import { AlertContext as alert } from "../../context";
import { checkWithCasbin } from "../../utils/permissonHandling";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { RadiologyOrderForm } from "./radiologyFlow";
import { TreatmentProcedureForm } from "./treatmentProcedureFlow";
import { Footer } from "./footer";
import FollowupFotter from "./followupfooter";
import DoccForm from "./documentation/form";
import Miniwidget from "./miniwidget";
import Axios from "axios";
import { Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import GroupIcon from "../../assets/img/svg/Group 90506.svg";
import Group90507 from "../../assets/img/svg/Group 90507.svg";
import { generateJson } from "../patientDetail/medicationFlows/generateJson";
import jwtDecode from "jwt-decode";
import { NursingForm } from "./nursingProcedure/nursingForm";
import { PackageForm } from "./packageOrder/packageForm";
import Grid from "@mui/material/Grid";
import axios from "axios";
import CpgPackage from "./cpg/cpgPackage";

const materialStyles = (theme) => ({});
const info = getUserInfo();

const PatientDetail = (props) => {
  const dispatch = useDispatch();
  const templocation = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
    const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo =
    userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const roleName = decodedUserinfo?.rolename;  

  const AlertContext = useContext(alert);

  const [selectOption, setselectOption] = React.useState({});
  const [dialysisData, setdialysisData] = React.useState([]);

  const [patientChartList, setpatientChartList] =
   // React.useState("action_items");
    React.useState("patient_chart");
  
  const [encounterDataaaa, setencounterData] = React.useState({});
  const [permissions, setpermissions] = React.useState({});
  const [signOff, setsignOff] = React.useState(false);
  const [fetchUser, setfetchUser] = React.useState({});
  //const [followup , setfollowup] = React.useState([]);
  const [followup_number, setfollowup_number] = React.useState(null);
  const [followup_type, setfollowup_type] = React.useState({
    value: "",
    label: "",
  });
  const [followup_reason, setfollowup_reason] = React.useState({
    value: "",
    label: "",
  });

  const [formComponet, setformComponet] = React.useState({
    open: false,
    id: null,
    type: null,
  });
  const [treatmentprocedure, settreatmentprocedure] = React.useState([]);
  const [editIndex, seteditIndex] = React.useState();
  const [editAllergyVal, setAllergyEditVal] = useState({});

  const [encounterDataList,setEncounterDataList] = React.useState();
  const [selectencounterDataList, setSelectEncounterDataList] = useState([]);
  const [count,setcount]= useState(1);
  const [Isdisableright, setIsdisableright] = useState(false);
  const [Isdisableleft, setIsdisableleft] = useState(false);
  const [encPageCount, setencPageCount] = useState(0);
  const [location,setLocation] = useState({});
  const [footerDialsisData, setfooterDialsisData] = React.useState({});

  let flow=WhichFlow()
  const initalFormList = flow ==="pc"? [] : ["cheif_complaints","radiology","medication"]
  const [formsList, setformsList] = React.useState(initalFormList);
  const [propsvalue, setpropsValue] = React.useState({
    patientId: location?.state?.patientId ? location?.state?.patientId : 4,
    encounter_id: location?.state?.encounter_id
      ? location?.state?.encounter_id
      : "",
    personId: location?.state?.personId,
    appointmentId: location?.state?.appointmentId
      ? location?.state?.appointmentId
      : 0,
    encounterId: location?.state?.encounterId
      ? location?.state?.encounterId
      : 0,
    type: location?.state?.type,// ? location?.state?.type : "nurse",
    pId: location?.state?.pId ,//? location?.state?.pId : "Patient/11915",
    app_id: location?.state?.app_id
      ? location?.state?.app_id
      : "Appointment/12683",
    isProfileView: location?.state?.isProfileView
      ? location?.state?.isProfileView
      : false,
  });
  const [editDiagnosisVal, setDiagnosisEditVal] = useState({});

  const searchPractioner = useSelector(
    (state) => state?.personSlice?.searchPractitioner
  );
  const recordStatus = useSelector(
    (state) =>
      state?.signOffSlice?.getAllMastersForSignoff?.data?.RECORDSTATUS ?? []
  );

  const styles = {
    columnStyle: {
      padding: 10,
      backgroundColor: UIColor.greyBackground.color,
      //borderRadius: 5,
      height: "75vh",
    },
    divider: {
      margin: "5px 0px",
      border: "0.5px dashed #EEEEEE",
      borderRadius: 10,
    },
    infoHeader: {
      position: "sticky",
      top: 0,
      // background: "#f8f8f8",
      zIndex: 100,
      padding: "14px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#fff",
      // marginBottom: "20px"
    },
    background: {
      background: UIColor.greyBackground.color,
      paddingTop: "20px",
    },
    rightSideCol: {
      height: "78vh",
      overflow: "auto",
      backgroundColor: "#F9F9F9",
      borderLeft: "1px solid #E0E0E0",
      padding: 0,
    },
  };
console.log(propsvalue,"propsvalue");

  const getData = async (encIds,encStartDate) => {

    // setselectOption(fin_DATA[0]);
    // const findata = fin_DATA//fin_DATA?.reverse();
    // setfin_data(findata);
    //this.setState({ encounterData: encounterData?.payload?.data ?? {} });
    // if (encounterData?.payload?.data?.[0]?.patient_id) {
    //   let d = await dispatch(
    //     actions.PATIENT_BANNER({
    //       id: encounterData?.payload?.data?.[0]?.patient_id,
    //     })
    //   );
    // }
    // if (encounterData?.payload?.data?.[0]?.patient_id) {
    //   let d_ = await dispatch(
    //     actions.ENCOUNTER_BANNER({
    //       patientId: encounterData?.payload?.data?.[0]?.patient_id,
    //       facilityId: encounterData?.payload?.data?.[0]?.facilityid,
    //     })
    //   );
    // }

    // await dispatch(
    //   actions.RAD_ORGANIZATION({
    //     id: fetchUser?.orgId,
    //   })
    // );

    // Radiology fetch

    //await dispatch(actions.RAD_PRIORITY());
    //await dispatch(actions.RAD_NATURE());
    //await dispatch(actions.RAD_MODE());
    // await dispatch(
    //   actions.TREATMENT_DIAGNOSIS({
    //     encounter_id:encId || encounterData?.payload?.data?.[0]?._id,
    //   })
    // );
    //await dispatch(actions.RAD_INDICATE());
    //await dispatch(actions.RAD_INSTRUCTION());


    //getallFOrMedication(encounterData?.payload?.data?.[0]?._id);

    // setMedication({ ...props.medicationsData, list: data });

    //await dispatch(actions.RAD_PRE_INSTRUCTION());
    //await dispatch(actions.RAD_DEP_INSTRUCTION());
    // await readDialysisWithEncouterid();
    // props?.backDrop?.setBackDrop({
    //   ...props?.backDrop,
    //   open: false,
    //   message: "",
    // });
  };
  const readDialysisWithEncouterid = async (enc_dta, orderlineId,encId) => {
    let data = await dispatch(
      actions.READ_DIALYSIS({
        encounterId:  encounterDataaaa?.encounterId,
        orderlineid: orderlineId,
      })
    );
    const read_dialysis = await dispatch(
      actions.TREATMENTPROCEDURE_READ({
        encounterId: encounterDataaaa?.encounterId ?? props?.location?.state?.encounter_id ?? enc_dta?.[0]?._id ?? encounterDataaaa?.[0]?._id,
        patientId: location?.state?.patientId ?? encounterDataaaa[0]?.Patient[0]?._id,
      })
    );
    setdialysisData(read_dialysis?.payload?.data);
    settreatmentprocedure(data?.payload?.data)
  };

  const checkCasbin = async () => {
    let dataList = await checkWithCasbin(["patientChart"]);
    setpermissions(dataList); 
    const fetchUser = await fetchUserInfo();
    setfetchUser(fetchUser);
  }
  const handleEncounterSelect =async (val) => {
    const patientId = location?.state?.patientId || location?.state?.pId;
    setSelectEncounterDataList(val)
    const encounterId =  val?.encounterId;
    setencounterData(val);
    // getData(encounterId,val?.encounterStart)
    // readDialysisWithEncouterid({},"",encounterId);
    // readProgressNotes({},patientId,encounterId);
    // setEncounterNo(encounterId)
    // setEncounterDate(createDate(val?.encounterStart));
    //   const data = await dispatch(
    //     actions.GET_TABLE_DATA({ patientId, encounterId })
    //   );
    // const tableData = displayTable(data?.payload?.data ?? []);

    // setStandardTableorders(tableData);

    // setDisplayOrders(tableData);
    // getFinanceData(encounterId);
  };
  const getNextSet = async ()=>{
    let encCount = encPageCount + 10
    // const queryData =
    //     personRequests.getDetailsAgainstPatientSearchByIsCashier(
    //       0,
    //       0,
    //       "",
    //       location?.state?.mrnId,
    //       //app?.field2,
    //       "",
    //       "",
    //       "",
    //       "",
    //       "",
    //       getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
    //       encCount,
    //       10
    //     );
    //     const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
    let payload = {
      db_name: process.env.REACT_APP_DB,
      filter: {
        patientid: location?.state?.patientId,//"Patient/14319",
        tenantid: "",
        Encounterid: "",//location?.state?.encounter_id,//"Encounter/15373",
        facilityid: "",
        page: encCount,
        perPage: 10
      },
      queryid: "0a1c3c82-6b83-431e-a72b-f7e5034efa3f"
    }

    let SearchUserData = await dispatch(actions.GET_ENC_LIST(payload));
    SearchUserData = SearchUserData?.payload?.data
    if (!SearchUserData || SearchUserData?.[0]?.encounter?.length === 0) {
      setIsdisableright(true);
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Encounter data not Found",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      const list = SearchUserData?.[0]?.encounter?.map((val, i) => ({
            encstatus:val?.encstatus,
            encounterId:val?._id,
        encounterName: val?.encounter_no ?? "",
        type: val?.type?.shortDesc ?? "",
        speciality: val?.practitioner_details?.speciality?.display ?? "-",
        encounterStart: val?.encounterdate ?? "",
        encounterEnd: val?.encounterenddate ?? "",
        location: val?.location?.[i]?.physicalType ?? "",
        doctorName: val?.practitioner_details?.idname,
      }));
      setIsdisableleft(false)
      setencPageCount(encCount)
      setEncounterDataList(list)
          setcount(count+1)
    }
  }
  const getPreviousSet = async ()=>{
    let encPagecount = encPageCount===0 ? 0 : encPageCount - 10;
    // const queryData =
    //     personRequests.getDetailsAgainstPatientSearchByIsCashier(
    //       0,
    //       0,
    //       "",
    //       location?.state?.mrnId,
    //       //app?.field2,
    //       "",
    //       "",
    //       "",
    //       "",
    //       "",
    //       getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
    //       encPagecount,
    //       10
    //     );
    //     const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
    let payload = {
      db_name: process.env.REACT_APP_DB,
      filter: {
        patientid: location?.state?.patientId,//"Patient/14319",
        tenantid: "",
        Encounterid: "",//location?.state?.encounter_id,//"Encounter/15373",
        facilityid: "",
        page: encPagecount,
        perPage: 10
      },
      queryid: "0a1c3c82-6b83-431e-a72b-f7e5034efa3f"
    }

    if(encPageCount != 0){
      let SearchUserData = await dispatch(actions.GET_ENC_LIST(payload));
      SearchUserData = SearchUserData?.payload?.data
      if (!SearchUserData || SearchUserData.length === 0) {
        encPagecount = encPageCount + 2
        setencPageCount(encPagecount === 0 ? 0 : encPagecount)
        setIsdisableleft(true)
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Encounter data not Found",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      } else {
        const list = SearchUserData?.[0]?.encounter?.map((val, i) => ({
            encstatus:val?.encstatus,
            encounterId:val?._id,
          encounterName: val?.encounter_no ?? "",
          type: val?.type?.shortDesc ?? "",
          speciality: val?.practitioner_details?.speciality?.display ?? "-",
          encounterStart: val?.encounterdate ?? "",
          encounterEnd: val?.encounterenddate ?? "",
          location: val?.location?.[i]?.physicalType ?? "",
          doctorName: val?.practitioner_details?.idname,
        }));
        setIsdisableright(false)
        setencPageCount(encPagecount === 0 ? 0 : encPagecount)
        setEncounterDataList(list)
          setcount(count === 1 ? 1 : count-1)
      }
    }
  }
  const getEncList = async (isGetList) => {
    // const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    // const currentDate = new Date();
    // const startDate = new Date(currentDate);
    // startDate.setHours(0, 0, 0, 0);
    // const startEpochTime = getEpochTime(startDate);
    // const endDate = new Date(currentDate);
    // endDate.setHours(23, 59, 59, 999);
    // const endEpochTime = getEpochTime(endDate);
    FnExecutorBasedOnFLow({
      AppoitmentFlowFn: () => {
        let payload = {
          db_name: dbName,
          filter: {
            encounterid: location?.state?.encounter_id,
            tenantid: "",
            facilityid: "",
          },
          queryid: "80099c67-b0a5-4c6f-9735-eacf9ef2fe7e",
        };
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: urls?.readQdmQueries,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };

        axios
          .request(config)
          .then((response) => {
            let SearchUserData = response.data;
            let patientData = {
              patientId: SearchUserData?.[0]?.Patient?.[0]?._id,
              birthdate:
                SearchUserData?.[0]?.Patient?.[0]?.personInfo?.[0]?.birthdate,
              age: SearchUserData?.[0]?.Patient?.[0]?.personInfo?.[0]?.age,
              gender: SearchUserData?.[0]?.Patient?.[0]?.Gender?.display,
              gender_id: SearchUserData?.[0]?.Patient?.[0]?.Gender?._id,
              mrn: SearchUserData?.[0]?.Patient?.[0]?.patmrn,
            };
            const list = SearchUserData?.map((val, i) => ({
              encstatus: val?.encstatus ?? "",
              encounterId: val?._id,
              encounterName: val?.encounter_no ?? "",
              type: val?.type?.longDesc ?? "",
              speciality: val?.practitioner_details?.speciality?.display ?? "-",
              encounterStart: val?.createddate ?? "",
              encounterEnd: val?.encounterdate ?? "",
              location: val?.location?.[i]?.physicalType ?? "",
              doctorName: val?.practitioner_details?.idname ?? "",
              patientDetails: patientData,
              visit_type: val?.visit_type ?? "",
              Patient:val?.Patient
            }));
            console.log(SearchUserData,"SearchUserData",list);
            
            setEncounterDataList(list);
            if (!isGetList) {
              setSelectEncounterDataList(list?.[0]);
              setencounterData(list?.[0]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      DialysisFlowFn: async () => {
        if (
          location?.state?.isReferral ||
          location?.state?.isNephrologyOrPicQueue === true
        ) {
          let payload = {
            db_name: dbName,
            filter: {
              encounterid: location?.state?.encounter_id,
              tenantid: "",
              facilityid: "",
            },
            queryid: "80099c67-b0a5-4c6f-9735-eacf9ef2fe7e",
          };
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: urls?.readQdmQueries,
            headers: {
              "Content-Type": "application/json",
            },
            data: payload,
          };

          axios
            .request(config)
            .then((response) => {
              let SearchUserData = response.data;
              let patientData = {
                patientId: SearchUserData?.[0]?.Patient?.[0]?._id,
                birthdate:
                  SearchUserData?.[0]?.Patient?.[0]?.personInfo?.[0]?.birthdate,
                age: SearchUserData?.[0]?.Patient?.[0]?.personInfo?.[0]?.age,
                gender: SearchUserData?.[0]?.Patient?.[0]?.Gender?.display,
                gender_id: SearchUserData?.[0]?.Patient?.[0]?.Gender?._id,
                mrn: SearchUserData?.[0]?.Patient?.[0]?.patmrn,
              };
              const list = SearchUserData?.map((val, i) => ({
                encstatus: val?.encstatus ?? "",
                encounterId: val?._id,
                encounterName: val?.encounter_no ?? "",
                type: val?.type?.longDesc ?? "",
                speciality:
                  val?.practitioner_details?.speciality?.display ?? "-",
                encounterStart: val?.createddate ?? "",
                encounterEnd: val?.encounterdate ?? "",
                location: val?.location?.[i]?.physicalType ?? "",
                doctorName: val?.practitioner_details?.idname ?? "",
                patientDetails: patientData,
              }));
              setEncounterDataList(list);
              if (!isGetList) {
                setSelectEncounterDataList(list?.[0]);
                setencounterData(list?.[0]);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          let payload = {
            db_name: dbName,
            filter: {
              patientid: location?.state?.patientId, //"Patient/14319",
              tenantid: getTenantId(),
              Encounterid:
                isGetList === true
                  ? location?.state?.isViewEmr === true
                    ? location?.state?.encounter_id
                    : ""
                  : location?.state?.encounter_id, //"Encounter/15373",
              facilityid: getFacilityId(),
              page: 0,
              perPage: 10,
            },
            queryid: "0a1c3c82-6b83-431e-a72b-f7e5034efa3f",
          };

          // let SearchUserData = await dispatch(actions.GET_ENC_LIST(payload));
          // SearchUserData = SearchUserData?.payload?.data

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: urls?.readQdmQueries,
            headers: {
              "Content-Type": "application/json",
            },
            data: payload,
          };

          axios
            .request(config)
            .then((response) => {
              let SearchUserData = response.data;
              let patientData = {
                patientId: SearchUserData?.[0]?._id,
                birthdate: SearchUserData?.[0]?.birthDate,
                age: SearchUserData?.[0]?.age,
                gender: SearchUserData?.[0]?.gender,
                gender_id: SearchUserData?.[0]?.gender_id,
                mrn: SearchUserData?.[0]?.patmrn,
              };
              const list = SearchUserData?.[0]?.encounter?.map((val, i) => ({
                encstatus: val?.encstatus,
                encounterId: val?._id,
                encounterName: val?.encounter_no ?? "",
                type: val?.type?.shortDesc ?? "",
                speciality:
                  val?.practitioner_details?.speciality?.display ?? "-",
                encounterStart: val?.encounterdate ?? "",
                encounterEnd: val?.encounterenddate ?? "",
                location: val?.location?.[i]?.physicalType ?? "",
                doctorName: val?.practitioner_details?.idname,
                patientDetails: patientData,
              }));
              setEncounterDataList(list);
              if (!isGetList) {
                setSelectEncounterDataList(list?.[0]);
                setencounterData(list?.[0]);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
    });
  };
  React.useEffect(() => {
    if(!(isObjectEmpty(location?.state || {}))){
      getEncList();
      checkCasbin();
      dispatch(actions.GET_PRIORITY());
      dispatch(actions.GET_ORDERNATURE());
      dispatch(actions.VERIFICATIONS_MASTERS());
    }
   
  }, [location?.state?.encounterId]);

  React.useEffect(()=>{
    if(searchParams.get("data")){
      setLocation({state:JSON.parse(decodeURIComponent(searchParams.get("data")))})
      console.log("location1",{state:JSON.parse(decodeURIComponent(searchParams.get("data")))});
      
    }
    else{
      setLocation(templocation)
    }
  },[])
  console.log("location",encounterDataaaa,location);
  
  const getallFOrMedication = async (id) => {
    Promise.all([
      dispatch(actions.FREQUENCYMASTER_MASTER()),
      dispatch(actions.STRENGTHUOM_MASTER()),
      dispatch(actions.DURATIONTYPE_MASTER()),
    ]).then(async (res) => {
      let res_copy = res;
      res.forEach((v, i) => (res_copy[i]["key"] = v.type.split("/")?.[1]));
      let obj = {};
      res_copy.forEach((v) => {
        obj[v?.key] = { data: [] };
        obj["status_masters"] = { data: [] }; // we dont know the status master
        obj[v?.key]["data"] = v?.payload?.data ?? [];
      });
      let get_Medication_Details = await dispatch(
        actions.GET_MEDICATIONS({
          id: id,
          tenantid: "",
          facilityid: "",
        })
      );

      let get_Medication = props?.medicationsData;
      let getMedData = generateJson(get_Medication_Details?.payload?.data, obj);
      await get_Medication.setMedication({
        ...get_Medication,
        list: JSON.parse(JSON.stringify(getMedData)),
      });
    });
  };


  // const readTreatmentplanWithPatientid = async (patientid) => {
  //   let data = await dispatch(
  //     actions.TRAEMENT_PLAN_READ({
  //       patientid: patientid //"Patient/12572"
  //     })
  //   );

  //   setTreatmentplan(data?.payload?.data?.[0]);
  // };
  const onEditChart = (id, type,val) => {
    setformComponet({
      open: !formComponet.open,
      id: id,
      type: type,
    });
    setAllergyEditVal(val);
  };
  const onEditDiagnosis = (id, type, editIndex,val) => {
    setformComponet({
      open: !formComponet.open,
      id: id,
      type: type,
    });
    setDiagnosisEditVal(val);
  };
  const onEditWidget = (id, type, index) => {
    // this.setState({
    //   ...this.state,
    //   editIndex: index,

    //   formComponet: {
    //     open: true,
    //     id: id,
    //     type: type,
    //   },
    // });
    seteditIndex(index);

    setformComponet({
      open: true,
      id: id,
      type: type,
    });
  };

  const handleClose = (id) => {
    let selectedFormId = [...formsList];
    let filterId = selectedFormId.filter((val) => val !== id);
    changeChartLayer(filterId);
  };

  const getCharts = (type) => {
    switch (type) {
      // case "action_items":
      // return (
      //   <>
      //     {/* Action Items contains */}
      //     <ActionItems
      //     saveLabOrders={saveLabOrders}
      //     onEditChart={onEditChart}
      //     handleClose={handleClose}
      //    getDialysisData={readDialysisWithEncouterid}

      //     />
      //   </>
      // );
      case "patient_chart":
        return (
          <PatientChart
            art
            parent_id={"patientdetails"}
            formsList={formsList}
            handleClose={handleClose}
            onEditChart={onEditChart}
            onEditDiagnosis={onEditDiagnosis}
            saveCheifComp={saveCheifComp}
            // saveDiagnosis={saveDiagnosis}
            // saveAllergy={saveAllergy}
            saveTreatment={saveTreatment}
            // saveLabOrders={saveLabOrders}
            // saveNursingProcedure={saveNursingProcedure}
            saveRadiology={saveRadiology}
            saveDoccumentation={saveDoccumentation}
            handleOrderSetClose={() => {
              if (formsList) {
                let formList = [...formsList];
                const index = formList.indexOf("orders_sets");
                if (index > -1) {
                  formList.splice(index, 1);
                  changeChartLayer(formList);
                }
              }
            }}
            {...props}
            patientgetData={encounterDataaaa}
            dialysisData={dialysisData}
            getDialysisData={readDialysisWithEncouterid}
            orgdetails={fetchUser}
            // progressNotesData={progressNotesData}
            // treatmentplan={treatmentplan}
            // setTreatmentplan={setTreatmentplan}
            locationdata={location}
          // setOrderlineId={setOrderlineId}
          // readDialysisWithEncouterid={readDialysisWithEncouterid}
          />
        );
      default:
        break;
    }
  };

  const changeChartLayer = async (forms) => {
    await dispatch(actions.VERIFICATIONS_MASTERS());
    await dispatch(actions.CLINIC_STATUS_MASTERS());
    await dispatch(actions.SYMPTOMS_MASTERS());
    await dispatch(actions.GET_ENCOUNTER({ id: encounterDataaaa?.[0]?._id }));
    await dispatch(actions.BODYSITE_MASTERS());
    await dispatch(actions.SEVERITY_MASTERS());
    await dispatch(actions.CC_MASTERS());
    await dispatch(actions.RELATIONSHIP_MASTERS());
    await dispatch(
      actions.ENC_BASED_CC({ encounter_id: encounterDataaaa?.[0]?._id || encounterDataaaa?.encounterId })
    );
    setpatientChartList(forms?.length > 0 ? "patient_chart" : "action_items");
    setformsList(JSON.parse(JSON.stringify(forms)));
    setformComponet({
      open: false,
      id: null,
      type: null,
    });
  };
  const onCancel = () => {
    setformComponet({
      open: false,
      id: null,
      type: null,
    });
  };
  const getFormTypeRender = (type) => {
    switch (type) {
      case "allergy_form":
        return (
          <AllergyForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            // saveAllergy={saveAllergy}
            encounterData={encounterDataaaa}
            editAllergyVal={editAllergyVal}
          />
        );
      case "diagnonsis_form":
        return (
          <DiagnonsisForm
            parent_id={"patient_details"}
            //saveDiagnosis={saveDiagnosis}
            onCancel={onCancel}
            encounterData={encounterDataaaa}
            location={templocation}
            editDiagnosisVal={editDiagnosisVal}

          />
        );
      case "cheif_complaints_form":
        return (
          <CheifComplaintsForm
            parent_id={"patient_details"}
            editIndex={editIndex}
            saveCheifComp={saveCheifComp}
            onCancel={onCancel}
            formComponet={formComponet}
            encId={encounterDataaaa?.[0]?._id}
          />
        );
      case "lab_order_form":
        return (
          <LabOrderForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            // saveLabOrders={saveLabOrders}
            patientgetData={encounterDataaaa}
            info={info}
            fetchUser={fetchUser}
            {...props}
          />
        );
      case "nursing_procedure_form":
        return (
          <NursingForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            // saveNursingProcedure={saveNursingProcedure}
            patientgetData={encounterDataaaa}
            info={info}
            fetchUser={fetchUser}
            {...props}
          />
        );
      case "package_order_form":
        return (
          <PackageForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            // savePackageOrder={savePackageOrder}
            patientgetData={encounterDataaaa}
            info={info}
            fetchUser={fetchUser}
            {...props}
          />
        );
      case "medication_form":
        return (
          <MedicationForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            fetchUser={fetchUser}
            patientgetData={encounterDataaaa}
          />
        );
      case "rad_order_form":
        return (
          <RadiologyOrderForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            saveRadiology={saveRadiology}
            patientgetData={encounterDataaaa}
            fetchUser={fetchUser}
            {...props}
          />
        );
      case "treatment_procedure_form":
        return (
          <TreatmentProcedureForm
            parent_id={"patient_details"}
            {...props}
            onCancel={onCancel}
            saveTreatment={saveTreatment}
            patientgetData={encounterDataaaa}
            info={info}
            treatment={props.treatment}
            fetchUser={fetchUser}
          />
        );
      case "docc_form":
        return (
          <DoccForm
            parent_id={"patient_details"}
            {...props}
            onCancel={onCancel}
            saveDoccumentation={saveDoccumentation}
            patientgetData={encounterDataaaa}
            patientID={propsvalue?.patientId}
            encounterID={propsvalue?.encounter_id}
            info={info}
            pID={propsvalue?.pId}
            fetchUser={fetchUser}
            editData={formComponet}
            personDetails={searchPractioner?.data?.[0]}
          />
        );

      default:
        break;
    }
  };
  const saveCheifComp = async (list, editIndex) => {
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: true,
      message: !editIndex ? "Adding New Chief Complaints" : "Updating",
    });
   
    let personDetails = searchPractioner?.data?.[0] || {};
    let search_Practioner = personDetails?.practitioner?.[0];
    let data = CC_Json_Generator(
      propsvalue,
      list,
      search_Practioner,
      personDetails,
      encounterDataaaa
    );
    if (data?.length > 0) {
      await dispatch(actions.SAVE_CHEIF_COMPAILANTS({ list: data }));
      let cheifComplaints = props.cheifComplaints;
      if (!editIndex && editIndex !== 0) {
        let data = await dispatch(
          actions.ENC_BASED_CC({
            encounter_id: encounterDataaaa?.[0]?._id || encounterDataaaa?.encounterId,
          })
        );
        let dataList = JSON.parse(
          JSON.stringify({
            ...{
              isNew: true,
              createdName: list.createdName,
              createdAt: list.createdAt,
            },
            ...data?.payload?.data?.[data.payload?.data?.length - 1 ?? 0],
          })
        );
        cheifComplaints.list.push(dataList);
      } else {
        cheifComplaints.list[editIndex] = {
          ...list[0],
          isNew: false,
          isModified: true,
        };
      }
      cheifComplaints.setCheifComplaints({
        ...cheifComplaints,
      });
    }
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: false,
      message: "",
    });
  };
  const saveTreatment = async (
    list,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {
    let status = recordStatus?.find((val) => val?.label === "Draft");

    let treatment = props.treatment;

    return new Promise(async (resolve, reject) => {
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message:
          editIndex === null
            ? "Adding New Treatment & Procedures"
            : "Updating Treatment & Procedures",
      });

      let personDetails = searchPractioner?.data?.[0];
      let searchPractioner1 = personDetails?.practitioner?.[0];
      let data = Treatment_Json_Generator(
        list,
        encounterDataaaa,
        personDetails,
        searchPractioner1,
      );

      if (!editIndex) {
        data.forEach((d) => (d.status = status?.value || ""));
      }

      if (data?.length > 0) {
        let dateArr = [];
        if (list?.[0]?.recurrentList?.length > 0) {
          let listDataArr = list[0].recurrentList.map((val) =>
            getUtcTime(val.date)
          );
          dateArr = [listDataArr];
        } else {
          if (
            list?.[0]?.orderCatalog.label &&
            list?.[0]?.dateTime &&
            list?.[0]?.nature.label &&
            list?.[0]?.priority.label &&
            list?.[0]?.recurrentData?.every?.value &&
            list?.[0]?.recurrentData?.every?.periodicity?.value &&
            (list?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              list?.[0]?.recurrentData?.endsAfter?.endsBy) &&
            list?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(list?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                  list?.[0]?.recurrentData?.endsAfter?.endsBy,
                  "DD-MM-YYYY"
                ), // // [by => Date(DD-MM-YYYY), on => number(5)]
              list?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(list?.[0]?.recurrentData?.every?.value || "{}"), // number
              [], // []
              [] // []
            );
          } else {
            dateArr = [];
          }
        }

        var upsertdata;

        if (treatment?.child?.parentOrderId || date) {
          const vals = await dispatch(
            actions.READ_TREATMENT_CHILD({
              list: data,
              Isparent: date
                ? list?.[0]?.editData
                : treatment?.child?.parentOrderId,
              dates: date ? getUtcTime(date) : null,
            })
          );
        } else {
          upsertdata = await dispatch(
            actions.TREATMENT_PROCEDURE_SAVE({
              list: data,
              dateArr: dateArr?.[0],
              isupdate: isupdate ? true : false,
              childIds: childIds,
              deleteArr,
            })
          );
        }

        if (upsertdata?.payload?.data?.validation_error?.[0]?.Errormsg) {
          AlertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: upsertdata?.payload.data.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          props?.backDrop?.setBackDrop({
            ...props?.backDrop,
            open: false,
            message: "",
          });
          return;
        }
        let Treatmentdata = await dispatch(
          actions.TRAEMENT_READ({
            encounter_id: encounterDataaaa?.encounterId,
            order_id: "TREAT",
            patientid: location?.state?.patientId,
            startdate: getUtcTime(moment().startOf("day")),
          })
        );

        if (!editIndex && editIndex !== 0) {
          // return
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
              },
              infoAlertText: list[0]?.infoAlertText ?? "",
              ...Treatmentdata?.payload?.data?.[
              Treatmentdata?.payload?.data?.length - 1 ?? 0
              ],
            })
          );
          treatment.list.push(dataList);
        } else {
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
              },
              infoAlertText: list[0]?.infoAlertText ?? "",
              ...Treatmentdata?.payload?.data?.[
              Treatmentdata?.payload?.data?.length - 1 ?? 0
              ],
            })
          );
          var dataedit;
          if (!treatment?.child?.index >= 0) {
            dataedit = editIndex >= 0 ? list[treatment.editIndex] : 0;
            treatment.list[treatment.editIndex] = dataList;
          } else {
            dataedit =
              treatment.editIndex >= 0
                ? treatment.child.index >= 0
                  ? treatment.list[treatment.editIndex].childRecurrent[
                  treatment.child.index
                  ]
                  : 0
                : 0;
            treatment.list[treatment.editIndex].childRecurrent[
              treatment.child.index
            ] = dataList;
          }
        }
        treatment.child = {};
        await treatment.setTreatment({
          ...treatment,
        });
      }

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  const saveRadiology = async (
    list,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {
    let status = recordStatus?.find((val) => val?.label === "Draft");
    let radiology = props.radiology;
    return new Promise(async (resolve, reject) => {
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message:
          editIndex === null ? "Adding New Radiology" : "Updating Radiology",
      });
      let personDetails = searchPractioner?.data?.[0];
      let search_Practioner = personDetails?.practitioner?.[0];
      let data = Rad_Json_Generator(
        list,
        encounterDataaaa,
        personDetails,
        search_Practioner,
      );
      if (!editIndex) {
        data.forEach((d) => (d.status = status?.value || ""));
      }
      if (data?.length > 0) {
        let dateArr = [];
        if (list?.[0]?.recurrentList?.length > 0) {
          let listDataArr = list[0].recurrentList.map((val) =>
            getUtcTime(val.date)
          );
          dateArr = [listDataArr];
        } else {
          if (
            list?.[0]?.orderCatalog.label &&
            list?.[0]?.dateTime &&
            list?.[0]?.nature.label &&
            list?.[0]?.priority.label &&
            list?.[0]?.recurrentData?.every?.value &&
            list?.[0]?.recurrentData?.every?.periodicity?.value &&
            (list?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              list?.[0]?.recurrentData?.endsAfter?.endsBy) &&
            list?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(list?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                  list?.[0]?.recurrentData?.endsAfter?.endsBy,
                  "DD-MM-YYYY"
                ), // // [by => Date(DD-MM-YYYY), on => number(5)]
              list?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(list?.[0]?.recurrentData?.every?.value || "{}"), // number
              [], // []
              [] // []
            );
          } else {
            dateArr = [];
          }
        }

        var upsertdata;
        if (radiology?.child?.parentOrderId || date) {
          const vals = await dispatch(
            actions.READ_RAD_CHILD({
              list: data,
              Isparent: date
                ? list?.[0]?.editData
                : radiology?.child?.parentOrderId,
              dates: date ? getUtcTime(date) : null,
            })
          );
        } else {
          upsertdata = await dispatch(
            actions.RAD_SAVE({
              list: data,
              dateArr: dateArr?.[0],
              isupdate: isupdate ? true : false,
              childIds: childIds,
              deleteArr,
            })
          );
        }

        if (upsertdata?.payload?.data?.validation_error?.[0]?.Errormsg) {
          AlertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: upsertdata.payload.data.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          props?.backDrop?.setBackDrop({
            ...props?.backDrop,
            open: false,
            message: "",
          });
          return;
        }

        let Treatmentdata = await dispatch(
          actions.RAD_READ({
            encounter_id: encounterDataaaa?.encounterId,
            order_id: "RAD",
            patientid: location?.state?.patientId,
            startdate: getUtcTime(moment().startOf("day")),
          })
        );
        console.log(Treatmentdata,"Treatmentdata");
        
        if (!editIndex && editIndex !== 0) {
          // return
          if (
            Array.isArray(Treatmentdata?.payload?.data) &&
            Treatmentdata?.payload?.data?.length > 0
          ) {
            Treatmentdata.payload.data.map((val) => {
              let dataList = JSON.parse(
                JSON.stringify({
                  ...{
                    isNew: true,
                  },
                  infoAlertText: list[0]?.infoAlertText ?? "",
                  ...val,
                })
              );
              radiology.list.push(dataList);
            });
          }
        } else {
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
              },
              infoAlertText: list[0]?.infoAlertText ?? "",
              ...Treatmentdata?.payload?.data?.[
              Treatmentdata?.payload?.data?.length - 1 ?? 0
              ],
            })
          );
          var dataedit;
          if (!radiology?.child?.index >= 0) {
            dataedit = editIndex >= 0 ? list[radiology.editIndex] : 0;
            radiology.list[radiology.editIndex] = dataList;
          } else {
            dataedit =
              radiology.editIndex >= 0
                ? radiology.child.index >= 0
                  ? radiology.list[radiology.editIndex].childRecurrent[
                  radiology.child.index
                  ]
                  : 0
                : 0;
            radiology.list[radiology.editIndex].childRecurrent[
              radiology.child.index
            ] = dataList;
          }
        }
        radiology.child = {};
        await radiology.setRadiology({
          ...radiology,
          list: JSON.parse(JSON.stringify(Treatmentdata?.payload?.data)),
        });
      }
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  const saveDoccumentation = async (list, editIndex) => {
    return new Promise(async (resolve, reject) => {
      let { Doccument, setDoccument } = props.Doccument;
      let personDetails = searchPractioner?.data?.[0];

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message: editIndex === "Add" ? "Adding New Documentation" : "Updating",
      });
      const data = await dispatch(
        actions.DOCC_SAVE_SERVICE({
          data: {
            data: list[0],
            encounterData: encounterDataaaa,
            personDetails,
            formID: list?.[0]?.selectDoccument?.formrefid,
            formdata: list[0]?.formData ?? {},
          },
        })
      );
      if (data?.payload?.data?.statusCode === 201 && editIndex === "Add") {
        let doc_key = data?.payload?.data?.response?.Result?.[0]?.properties?.doc?._id;
        await dispatch(actions.DOCC_SINGLE_READ({ key: doc_key }));
      }
      let Readdata = await dispatch(
        actions.DOCC_READ({
          // encounter_id: this.props.encounterData?.data?.eId,
          patient_id: propsvalue?.pId,
        })
      );

      setDoccument({
        ...Doccument,
        list: JSON.parse(JSON.stringify(Readdata?.payload?.data)),
      });
      // if (!editIndex && editIndex !== 0) {
      //   let data = await this.props.DOCC_READ({
      //     encounter_id: this.props.encounterData?.data?.eId,
      //   });

      // // return
      // let dataList = JSON.parse(
      //   JSON.stringify({
      //     ...{
      //       isNew: true,
      //     },
      //     ...data?.payload?.data?.[data.payload?.data?.length - 1 ?? 0],
      //   })
      // );
      // Doccument.editIndex =
      //   data?.payload?.data?.[data.payload?.data?.length - 1]?._key;
      // Doccument.list.push(dataList);
      // } else {
      // Doccument.list[editIndex] = list[0];
      // }
      // Doccument.setDoccument({
      //   ...Doccument,
      // });

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };
  const goBackInsignOff = (key, val) => { 
      navigate(-1);
  }
  //const { isProfileView } = location?.state
  const goBack = () => {
    navigate(AppRoutes.dashboard);
  };
  const signOffFunc = async () => {
    setsignOff(true);
    return;
  };
  const confirmButtonAction = async () => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "checking",
    });
    let action = "";
    if (roleName === "Doctor" || roleName === "nephrologistdoctor" || roleName === "doctor" ) {
      action = process.env.REACT_APP_DOCTOR_SIGN_OFF;
      if (
        followup_number?.length > 0 &&
        followup_reason?.value &&
        followup_type?.value 
      ) {
        let payload = {
          eSignature: "",
          followupDur: Number(followup_number),
          followupreqd: true,
          followupUOM: followup_type?.value,
          reasonCode: followup_reason?.value,
          encounterid: location?.state?.encounter_id,
          appointmentid: location?.state?.allCurrentData?.info?._id,
          practitionerid: location?.state?.allCurrentData?.info?.resourcecode,
          orgid: location?.state?.allCurrentData?.info?.orgid,
          healthserviceid: "0",
          patientid: location?.state?.pId ?? location?.state?.patientId,
          specialtyid: "0",
          encounter_type:
            location?.state?.allCurrentData?.info?.consultationMode,
          orderid: "",
          followupdate: Number(
            (
              dateGiverForFollowUp(followup_number, followup_type) / 1000
            ).toFixed()
          ),
          encounterdate: moment().unix(),
        };
        props.backDrop.setBackDrop({
          ...props.backDrop,
          open: true,
          message: "signing off",
        });
        await dispatch(actions.DOCTOR_SIGN_OFF_FOLLOWUP_ACTION(payload));
      } else if (
        ![
          followup_number?.length > 0,
          followup_reason?.value,
          followup_type?.value,
        ].every((v) => v) &&
        [
          followup_number?.length > 0,
          followup_reason?.value,
          followup_type?.value,
        ].some((v) => v)
      ) {
        AlertContext.setSnack({
          open: true,
          severity: AlertProps.severity.warning,
          msg: "Please fill all follow up details",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return;
      }
    } else if (roleName === "Nurse" || roleName === "nurse") {
      action = process.env.REACT_APP_NURSE_SIGN_OFF;
    }

    let data = await dispatch(
      actions.SIGN_OFF_ACTION({
        userAction: action,
        ticketId: location?.state?.ticketId,
      })
    );

    let firstpass = await dispatch(
      actions.FIRST_PASS_API({
        actionType: "END",
        appId: location?.state?.appointmentId,
        servingType: (roleName).toLowerCase() === "nurse" ? "VITAL" : "GENERAL" 
      })
    );
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
     navigate(AppRoutes.dashboard);
  };
  let isAppoinmentFlow= FnExecutorBasedOnFLow({
    AppoitmentFlowFn: () => {
   return true
    },
    DialysisFlowFn: async () => {
   return false
    },
  });
  return (
    <div id="patient_detail_parent_div">
      <div>
        <Row
          id="patient_detail_parent_row"
          inLineStyles={{
            // background:
            //  "transparent linear-gradient(90deg, rgb(251 194 235 / 20%) 0%, hsl(237deg 78% 82% / 20%) 49%, #6bbbff33 100%) 0% 0% no-repeat padding-box",
            while: "white",
            padding: "5px 10px 5px 10px",
          }}
        >
          <Grid container xs={12}>
            <Grid
              item
              xs={4}
              inLineStyles={{ padding: 5, borderRight: "1px solid #DFE5EB" }}
            >
              <PatientInfoCard
                parent_id={"patient_detail"}
              // pic={encounterDataaaa?.[0]?.Patient?.[0]?.photo?.[0]?.[0]?.url}
              // name={makeName(encounterDataaaa?.[0]?.Patient?.[0]?.name[0])}
              // age={getCalculatedAge(
              //   encounterDataaaa?.[0]?.Patient?.[0]?.personInfo[0]?.birthdate
              // )}
              // gender={encounterDataaaa?.[0]?.Patient?.[0]?.Gender?.display}
              // mrn_id={encounterDataaaa?.[0]?.Patient?.[0]?.patmrn}
              // pi_id={
              //   encounterDataaaa?.[0]?.Patient?.[0]?.personInfo?.[0]
              //     ?.identifier?.[0]?.value
              // }
              // patientphonenum={
              //   encounterDataaaa?.[0]?.Patient?.[0]?.personInfo?.[0]
              //     ?.telecom?.[0]?.value
              // }
              // attachments={getallattachments(encounterDataaaa)}
              />
            </Grid>
            <Grid item xs={8} container>
              <Col
                id="patient_detail_statuscard_col"
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                inLineStyles={{ padding: 5, borderRight: "1px solid #DFE5EB" }}
              >
                <PatientStatusCard
                  parent_id={"patient_detail"}
                  encounterList={encounterDataList}
                  selected={selectencounterDataList}
                  handleEncounterSelect={handleEncounterSelect}
                  getPreviousSet={getPreviousSet}
                  getNextSet={getNextSet}
                  getEncList={getEncList}
                  Isdisableright={Isdisableright}
                  Isdisableleft={Isdisableleft}
                  count={count}
                  isReferral={location?.state?.isReferral}
                />
              </Col>
              <Col
                id="patient_detail_care_typecard_col"
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                inLineStyles={{
                  padding: 5,
                  borderRight: "1px solid #DFE5EB",
                  paddingRight: 5,
                }}
              >
                <PatientCareTypeCard
                  parent_id={"patient_detail"}
                  // careList={fin_data}
                  // selected={selectOption}
                  //onChange={(data) => changeState("selectOption", data)}
                  // attachments={getfindataalldoc(fin_data)}
                  isDetailed={true}
                  patientId={location?.state?.patientId}
                  encounterid={encounterDataaaa?.encounterId}
                  isReferral={location?.state?.isReferral}
                />
              </Col>
              {location?.state?.referralId && (
                <Col
                  id="patient_detail_care_typecard_col"
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  inLineStyles={{ padding: 5 }}
                >
                  <ReferralListView
                    // ReferralData={ReferralData}
                    parent_id={"patientdetails"}
                  // attachments={getrefalldoc(ReferralData)}
                  />
                </Col>
              )}
            </Grid>
          </Grid>

        </Row>
      </div>
      <Row
        id="patient_detail_footer_row"
        inLineStyles={{ borderTop: "1px solid rgb(224, 224, 224)" }}
      >
        <Col
          id="patient_detail_footer_col"
          xl={signOff ? 12 : 9}
          // xl={12}
          lg={signOff ? 12 : 9}
          // lg={12}
          md={signOff ? 12 : 9}
          // md={12}
          sm={12}
          xs={12}
          inLineStyles={{ height: "calc( 100vh - 93px )", overflow: "auto" }}
          style={{ ...styles.columnStyle, padding: 0 }}
        >
          <div
            id="patient_detail_footer_buttons_div"
            style={{ display: "flex", overflowX: "hidden" }}
          >
            {/* Menus items */}
            {!signOff && !propsvalue?.isProfileView && (
              <Div
                id="patient_detail_menulist_div"
                inLineStyles={{
                  backgroundColor: "#fff",
                  //borderRight: "1px solid rgb(224, 224, 224)",
                  padding: "10px",
                  overflow: "auto",
                }}
              >
                <MenuList
                  permissions={permissions}
                  parent_id={"patient_detail"}
                  selectedFormList={changeChartLayer}
                  selectedForm={formsList ?? []}
                />
              </Div>
            )}

            {/* Mian Action items and Chart */}

            {formComponet?.open ? (
              //  Edit the patient_chart forms
              <Div
                id="patient_detail_formtype_div"
                inLineStyles={{
                  padding: "10px 10px 0px 10px",
                  width: "95%",
                  background: UIColor.greyBackground.color,
                }}
              >
                {getFormTypeRender(formComponet?.type)}
              </Div>
            ) : (
              <Div
                id="patient_detail_buttons_div"
                inLineStyles={{
                  width: "95%",
                  background: !signOff
                    ? UIColor.greyBackground.color
                    : "transparent",
                  height: !signOff ? "auto" : "65vh",
                }}
              >
                <div
                  id="patient_charts_div"
                  style={{
                    maxWidth: signOff ? "1140px" : "100%",
                    margin: signOff ? "auto" : "0",
                  }}
                >
                  {/* Action Items contains */}
                  <ActionItems
                    onEditChart={onEditChart}
                    handleClose={handleClose}
                    // treatmentplan={treatmentplan}
                    patientgetData={encounterDataaaa}
                    dialysisData={dialysisData}
                    getDialysisData={readDialysisWithEncouterid}
                    saveTreatment={saveTreatment}
                    orgdetails={fetchUser}
                    locationdata={location}
                    referralId={location?.state?.referralId}
                    settreatmentprocedure={settreatmentprocedure}
                    onEditDiagnosis={onEditDiagnosis}

                    saveRadiology={saveRadiology}
                    saveCheifComp={saveCheifComp}
                    saveDoccumentation={saveDoccumentation}

                  />
                  {getCharts(patientChartList)}

                </div>
              </Div>
            )}
            {/* END Mian Action items and Chart */}
          </div>
        </Col>
        {!signOff && !propsvalue?.isProfileView && (
          <Col
            id="patient_detail_info_widget_col"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            inLineStyles={styles.rightSideCol}
          >
            <Row id="patient_detail_info_widget_row">
              <Col
                id="patient_detail_infoHeader_col"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={styles.infoHeader}
              >
                <Div id="patient_detail_info_div">
                  <Text
                    id="patient_detail_info_text"
                    inLineStyles={{
                      fontWeight: 600,
                      fontSize: "16px",
                      color: UIColor.primaryText.color,
                    }}
                  >
                    {"Info"}
                  </Text>
                </Div>
                <Div id="patient_detail_Add_Widget_div">
                  <Button
                    id="patient_detail_Add_Widget_button"
                    variant={"text"}
                    inLineStyles={{
                      color: UIColor.secondaryColor.color,
                      padding: "5px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {"+ Add Widget"}
                  </Button>
                </Div>
              </Col>

              {/* Widgets list  */}
              <Col
                id="patient_detail_WidgetList_col"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={styles.background}
              >
                <WidgetsList
                  permissions={permissions}
                  parent_id={"patent_details"}
                  editWidget={onEditWidget}
                  propsvalue={propsvalue}
                  encounterData={encounterDataaaa}
                  treatdata={treatmentprocedure}

                />
              </Col>
              {/* END Widgets list */}

              {/* CPG AI Module */}
              <CpgPackage patientId={location?.state?.patientId} encounterid={location?.state?.encounterId} />
              
            </Row>
          </Col>
        )}
       {/* { FnExecutorBasedOnFLow({AppoitmentFlowFn:()=><Footer
          // confirmButtonAction={confirmButtonAction}
          parent_id={"patient_detail"}
          goBackButtonAction={() =>
            goBackInsignOff("signOff", false)
          }
        //isProfileView={propsvalue?.isProfileView}
        // encounterData={encounterDataaaa}
        // footerData={footerDialsisData}
        />
        })} */}
          {!signOff && !propsvalue?.isProfileView && isAppoinmentFlow? (
                      <Col
                        id="patient_detail_footer_buttons_col"
                        xs={9}
                        sm={9}
                        md={9}
                        lg={9}
                        xl={9}
                        inLineStyles={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingBottom: "20px",
                          background: UIColor.greyBackground.color,
                          // paddingTop: "20px",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            background: "#fff",
                            padding: "8px 12px",
                            borderRadius: "0px 0px 10px 10px",
                            width: "100%",
                          }}
                        >
                          <Div id="patient_detail_goback_button_div">
                            <Button
                              id="patient_detail_goback_button"
                              onClick={() => goBack()}
                              variant={"text"}
                              inLineStyles={{
                                backgroundColor: UIColor.lineBorderFill.color,
                                borderColor: UIColor.lineBorderFill.color,
                                color: UIColor.primaryColor.color,
                                borderRadius: "8px",
                                fontSize: "14px",
                              }}
                            >
                              {"Go back"}
                            </Button>
                          </Div>
                          <Div id="patient_detail_hold-sign_button_div" style={{display:"flex"}}>
                            <Button
                              id="patient_detail_put_hold_button"
                              inLineStyles={{
                                backgroundColor: "#FFE1DA",
                                borderColor: "#FFE1DA",
                                color: UIColor.secondaryColor.color,
                                marginRight: "14px",
                                borderRadius: "8px",
                                fontSize: "14px",
                              }}
                              onClick={async () => {
                                await dispatch(
                                  actions.SIGN_OFF_ACTION({
                                    userAction: process.env.REACT_APP_HOLD_ON,
                                    ticketId: location?.state?.ticketId,
                                  })
                                );
                                navigate(AppRoutes.dashboard);
                              }}
                              style={{
                                display:
                                location?.state?.allCurrentData?.queueMetaData?.queuename?.queuename ===
                                  process.env.REACT_APP_APPOINTMENT_QUEUE
                                    ? "flex"
                                    : "none",
                              }}
                            >
                              {"Put On-Hold"}
                            </Button>
                            <Button
                              id="patient_detail_signoff_button"
                              onClick={() => signOffFunc()}
                              inLineStyles={{
                                backgroundColor: UIColor.primaryColor.color,
                                borderColor: UIColor.primaryColor.color,
                                borderRadius: "8px",
                                fontSize: "14px",
                              }}
                              style={{
                                display:
                                  location?.state?.allCurrentData?.queueMetaData?.queuename?.queuename ===
                                  process.env.REACT_APP_APPOINTMENT_QUEUE
                                    ? "flex"
                                    : "none",
                              }}
                            >
                              {"Sign Off"}
                            </Button>
                          </Div>
                        </div>
                      </Col>
                    ) : (
                      <Footer
                        confirmButtonAction={confirmButtonAction}
                        parent_id={"patient_detail"}
                        goBackButtonAction={() =>
                          goBackInsignOff("signOff", false)
                        }
                        isProfileView={propsvalue?.isProfileView}
                        encounterData={encounterDataaaa}
                        footerData={footerDialsisData}
                        printDisable={flow == "pc"}
                      />
                    )}
      </Row>
      {
        false && <SignOffCard />
      }
    </div>
  );
};
export const PatientDetailWithoutAppBar =withAllContexts(PatientDetail);
export default withAppBar(withAllContexts(PatientDetail));
