/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Text, Div, Image, Divider } from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  TextField,
  Typography,
  Button,
  Tooltip,
  InputAdornment,
  Switch,
  makeStyles,
  Popover,
  Card,
  Grid,
  CardContent,
  IconButton,
} from "@material-ui/core";
import { CalendarIcon, InsightsIcon, Recurrent } from "../../../components";
import { TreatmentProcedureList } from "./treatmentProcedureList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import { AxiosCall, axiosCall, epochToDatetime, fetchData, formatDateFromEpoch, getCurrentMinTime, getData, getUserInfo, urls } from "../../../utils";
import { UIColor } from "../../../themes/theme";
import { AlertContext, DialogContext } from "../../../context";
import withAllContexts from "../../../hoc/withAllContexts";
import { AlertProps } from "../../../utils";
import Tick from "../../../assets/img/icons8-double-tick.svg";
import Addicon from "../../../assets/img/icons8-add.svg";
import Cancel from "../../../assets/img/icons8-multiply.svg";
import AlertsRestriction from "../alertsRestriction";
import CustomDatePicker from "../../../components/customDatePicker";
import { setHours, setMinutes } from "date-fns";
import HistoryAccordion from "../historyAccordion";
import { Pagination } from "@material-ui/lab";
import axios from "axios";
import treatmentChart from "../../../assets/img/svg/treatmentChart.svg"
import preDialysis from "../../../assets/img/svg/dialysis.svg"
import baseline from "../../../assets/img/svg/baseline.svg"
import hour1 from "../../../assets/img/svg/blue hour 1.svg"
import hour2 from "../../../assets/img/svg/blue hour 3.svg"
import hour3 from "../../../assets/img/svg/blue hour 2.svg"
import postDialysis from "../../../assets/img/svg/postdialysisgrey.svg"
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  // autocomplete: {
  //   width: 120,
  //   "& input": {
  //     fontFamily: "pc_regular",
  //     fontSize: 14,
  //     color: UIColor.secondaryText.color,
  //     "&::placeholder": {
  //       fontSize: 14,
  //       fontFamily: "pc_regular !important",
  //     },
  //   },
  // },
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
  input: {
    fontFamily: "pc_regular",
    color: UIColor.secondaryText.color,
    fontSize: 14,
    "&::placeholder": {
      fontSize: 14,
      fontFamily: "pc_regular !important",
    },
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  switch: {
    "& .Mui-checked": {
      color: "#fff",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
});

const styles = {
  vitalsHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "0px 0px 0px 10px",
  },
  vitalsHead1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "10px 10px 12px 20px",
  },
  vitalsfieldHead: {
    padding: "6px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
  },
  vitailsCard: {
    //border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    // marginBottom: "24px !important",
    backgroundColor: "#fff",
  },
  vitailsCarderr: {
    fontSize: "12px",
    textAlign: "right",
    justifyContent: "right",
    float: "right",
    color: UIColor.error.color,
    marginBottom: "24px !important",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  infoBell: {
    width: "50px",
    display: "flex",
    padding: "12px",
    borderLeft: `1px solid ${UIColor.lineBorderFill.color}`,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
  },
  icons: {
    cursor: "pointer",
  },
  flexFlow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  floatingLable: {
    backgroundImage: `linear-gradient(#fff, ${UIColor.greyBackground.color})`,
    position: "absolute",
    top: "-8px",
    fontSize: 10,
  },
};

const initialState = {
  orderCatalog: {},
  priority: {},
  nature: {},
  dateTime: "",
  headerShow: true,
  recurrent: false,
  recurrentData: {
    every: {
      value: "",
      periodicity: {},
    },
    startsOn: {
      dateTime: "",
    },
    endsAfter: {
      endsAfter: "",
      endsBy: "",
    },
  },
  orders: [],
  error: false,
};

const TreatmentProcedure = (props) => {
  console.log(props,"----------99999");
  
  const { parent_id } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialog = React.useContext(DialogContext);
  const alertContext = useContext(AlertContext);
  const { list, setTreatment } = props.treatment;
  const info = getUserInfo();
  const location = useLocation();
  const [alertData, setAlertData] = useState({
    open: false,
    data: null,
  });
  const storeData = useSelector((state) => state?.TreatmentSlice);
  const [treatmentProcedureEntry, setTreatmentProcedureEntry] = useState({
    ...initialState,
    // headerShow:
    //   [...list]?.filter((val) => val?.isNew)?.length > 0 ? false : true,
  });
  const [treatmentHistory,setTreatmentHistory] = useState([]);
  const [currentTreatment,setCurrentTreatment] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [load, setload] = useState(false);

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update current page when page is changed
  };

  // Assuming treatmentPlanHistory and pageSize are defined somewhere in your component's scope
  const pageSize = 2; // Number of items per page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleItems = treatmentHistory.slice(startIndex, endIndex);


  const treatHistory1 = async () => {
    const treatHistory = await dispatch(
      actions.TREATMENTPROCEDURE_READ_HISTORY({
        encounterId:props?.patientgetData?._id?? props?.encData?.encId ?? props?.encounterid,
        patientId:props?.patientgetData?.patient_id ?? props?.patientid ?? location?.state?.patientId,
      })
      );
    
    setTreatmentHistory(treatHistory?.payload?.data);
  }
  const treat1 = async () => {
      const treatHistory = await dispatch(
        actions.TREATMENTPROCEDURE_READ({
          encounterId:props?.patientgetData?._id ?? props?.patientgetData?.encounterId ?? props?.encData?.encId ?? props?.encounterid,
          patientId:props?.patientgetData?.patientId ?? props?.patientgetData?.patient_id ?? props?.patientid ?? location?.state?.patientId,
        })
      );
      setCurrentTreatment(treatHistory?.payload?.data);
  }
    

    useEffect(()=>{
      treatHistory1();
      treat1();
    },[props?.patientgetData,props?.dialysisData])

  React.useEffect(() => {
    dispatch(actions.TREATMENT_PROCEDURE_GETCATEGORY());
    dispatch(actions.TREATMENT_PROCEDURE_PRIORITY());
    dispatch(actions.TREATMENT_PROCEDURE_NATURE());
  }, []);

  React.useEffect(() => {
    setTreatmentProcedureEntry({
      ...treatmentProcedureEntry,
      headerShow: list.length > 0 ? false : true,
      dateTime: props?.patientgetData?.encounterStart ? new Date( props?.patientgetData?.encounterStart * 1000) : ""
    });
  }, [storeData,props?.patientgetData]);
  
  const handleInputChange = (e, name) => {
    if (name === "dateTime") {
      let Data = {
        every: {
          value: "",
          periodicity: {},
        },
        startsOn: {
          dateTime: e,
        },
        endsAfter: {
          endsAfter: "",
          endsBy: "",
        },
      };
      const hours = e.getHours().toString().padStart(2, '0');
      const minutes = e.getMinutes().toString().padStart(2, '0');
      const seconds = e.getSeconds().toString().padStart(2, '0');
      const timePart = `${hours}:${minutes}:${seconds}`;
  
      if (timePart === "00:00:00" || timePart === "0:0:0") {
        const currentDateTime = new Date();
        const currentHours = currentDateTime.getHours().toString().padStart(2, '0');
        const currentMinutes = currentDateTime.getMinutes().toString().padStart(2, '0');
        const currentSeconds = currentDateTime.getSeconds().toString().padStart(2, '0');
        const currentTime = `${currentHours}:${currentMinutes}:${currentSeconds}`;
        
        setTreatmentProcedureEntry({
          ...treatmentProcedureEntry,
          [name]: props?.patientgetData?.encounterStart ? new Date( props?.patientgetData?.encounterStart * 1000) : new Date(e.toDateString() + " " + currentTime),
          recurrentData: Data,
          error: false,
        });
      } else {
        setTreatmentProcedureEntry({
          ...treatmentProcedureEntry,
          [name]: e,
          recurrentData: Data,
          error: false,
        });
      }
      // setIndex(null);
      // handleClose();
    } else {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        [name]: e,
        error: false,
      });
      setIndex(null);
      handleClose();
    }
  };
  
  const closeAlertModal = (data) => {
    setAlertData({
      open: false,
      data: null,
    });

    if (data.type === 1) {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !treatmentProcedureEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
        },
        orders: [],
      });
    } else if (data.type) {
      AddTreatmentProcedure(true, data.type);
    }
  };
  const AddTreatmentProcedure = async (ruleValidated) => {
    if (
      treatmentProcedureEntry.orderCatalog.label &&
      treatmentProcedureEntry.dateTime &&
      treatmentProcedureEntry.nature.label &&
      treatmentProcedureEntry.priority.label &&
      (!treatmentProcedureEntry.recurrent ||
        (treatmentProcedureEntry.recurrentData?.every?.value &&
          treatmentProcedureEntry.recurrentData?.every?.periodicity?.value &&
          (treatmentProcedureEntry.recurrentData?.endsAfter?.endsAfter ||
            treatmentProcedureEntry.recurrentData?.endsAfter?.endsBy) &&
          treatmentProcedureEntry.recurrentData?.startsOn.dateTime))
    ) {
      let datalist = {
        ...treatmentProcedureEntry,
        createdName: info.name,
        createdAt: getData(),
        currentStatus: "New",
      };
      let alertText = null;
      // if (!ruleValidated) {
      //   let alertData = await dispatch(actions.GETALERTWITHLABORDER({
      //     orgId: props.propsData.loggedUserInfo.data.org_id,
      //     rulePayload: { ...datalist, Oltype: "LAB" },
      //     pracId: props.propsData.loggedUserInfo.data.practioner_id,
      //     patId: props.patientgetData.pID
      //   }))
      //   let alertdetails = alertData.payload.data;
      //   if (alertdetails.type && typeof alertdetails.type === "number" && alertdetails.type !== 2) {
      //     setAlertData({
      //       open: true, data: alertdetails
      //     })
      //     return false;
      //   } else if (alertdetails.type === 2) {
      //     alertText = alertdetails.alertText
      //   }
      // }
      datalist.infoAlertText = alertText;
      await props?.saveTreatment([datalist], null);

      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !treatmentProcedureEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
        },
        orders: [],
      });
      treat1();
    } else {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        recurrent: false,
        headerShow: true,
        error: true,
      });
      handleClose();
    }
    
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [index, setIndex] = useState(null);

  const handleClick = (index, event) => {
    setAnchorEl(event.currentTarget);
    setIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setTreatmentProcedureEntry({
      ...treatmentProcedureEntry,
      recurrent: false,
    });
    setAnchorEl(null);
  };

  const saveRecurrentData = (data) => {
    setTreatmentProcedureEntry({
      ...treatmentProcedureEntry,
      recurrentData: data,
      dateTime: data.startsOn.dateTime,
    });
    handleClose();
  };

  const deleteList = async (i, data,isReccDelete) => {
    if (list?.length > 0) {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        headerShow: true,
      });
    }
    dialog.setDialog({
      ...dialog,
      open: false,
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Deleting Treatment & Procedures",
    });
    await dispatch(actions.TREATMENT_PROCEDURE_DELETE({ id: data ,isReccDel:isReccDelete}));
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    list.splice(i, 1);

    setTreatment({
      ...props.treatment,
    });
    treat1();
  };

  const editFun = (i, childIndex, parentOrderId, data, dates) => {
    setTreatment({
      ...props.treatment,
      editIndex: i,
      // editData: data.editData,
      child: {
        index: childIndex,
        parentOrderId: parentOrderId,
        IsChild: !parentOrderId ? true : false,
        dates: dates,
        editData: data?.editData,
      },
    });
  };
  const printChart = async () => {
    let payload = {
      "reportid": "1bd7fc83-63a1-4a58-9757-05bcd12f2824",
      "inputparams": {
          "@patientid": props?.patientid,
          "@encounterid": props?.encounterid
      },
      "result": []
  };
  let JwtToken = localStorage.getItem("JwtToken");
  let header = { JwtToken: JwtToken };
  try{
    const queryDataResp = await axiosCall(payload, urls.jasperReport, header);
      if(queryDataResp?.downloadUrl){
        const response = await axios.get(queryDataResp?.downloadUrl, {
          method: "GET",
          responseType: "blob",
        });
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        const pdfDataUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfDataUrl, "_blank");
      } 
    } catch (error){
      if(error?.response?.status == 400) {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "No Documents !!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please Contact Admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
    }
  };

  const updatefuncy = (val) => {
    setTreatment({
      ...props.treatment,
      ...val,
    });
  };

  const handleCloseForm = () => {
    if (list.length > 0) {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !treatmentProcedureEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
        },
        orders: [],
      });
    } else {
      props.handleClose("treatment_procedure");
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  return (
    <div id={`${parent_id}_treatment_parent_div`} style={{ width: "100%" }}>
    <Row
      id={`${parent_id}_treatment_parent_row`}
      inLineStyles={styles.vitailsCard}
    >
      {/* Add a new treatment procedures data's */}
      <Col
        id={`${parent_id}_treatment_parent_col`}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        {treatmentProcedureEntry.headerShow ? (
          <div>
            <Grid container justifyContent="space-between" direction="column">
              <Grid item container>
                <Grid item style={{ marginTop: "15px" }}>
                  <Text
                    id={`${parent_id}_laborder_headerShow_text`}
                    inLineStyles={{
                      fontWeight: 600,
                      fontSize: "14px",
                      cursor: "pointer",
                      color: UIColor.primaryColor.color,
                      alignItems: "center",
                    }}
                  >
                    Treatment & Procedure
                  </Text>
                </Grid>
              </Grid>
              <Grid item>
                <Divider orientation="horizontal" variant="fullWidth" />
              </Grid>
            </Grid>

            <Grid
              container-
              justifyContent="space-between"
              style={{ display: "flex", gap: "10px", padding: "15px" }}
            >
              <Grid item container style={{ gap: "10px" }}>
                {" "}
                <div id={`${parent_id}_treatment_and_procedures_sub_div`}>
                  {/* <Text
            id={`${parent_id}_treatment_and_procedures_text`}
            className="pc_regular"
            inLineStyles={styles.floatingLable}
          >
            Treatment and procedures &nbsp;
          </Text> */}
                  <Autocomplete
                    id={`${parent_id}_treatment_and_procedures_autocmplete`}
                    className={classes.autocomplete}
                    style={{ width: 220 }}
                    size="small"
                    options={storeData?.teartment_getcategory?.data ?? []}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}_treatment_Search_textfield`}
                        {...params}
                        variant="standard"
                        placeholder="Search Treatement orders"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        id={`${parent_id}_treatment_Search_${option.label.replaceAll(
                          " ",
                          "_"
                        )}_typography`}
                        className="pc_regular"
                        style={{
                          width: "100%",
                          fontSize: 14,
                          color: UIColor.secondaryText.color,
                        }}
                        noWrap
                      >
                        {option.label}
                      </Typography>
                    )}
                    classes={{ popper: "muiPopperDropdown" }}
                    value={treatmentProcedureEntry.orderCatalog}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "orderCatalog")
                    }
                  />
                </div>
                <div id={`${parent_id}_treatment_Priority_div`}>
                  <Autocomplete
                    id={`${parent_id}_treatment_Priority_autocomplete`}
                    style={{ width: "100px" }}
                    className={classes.autocomplete}
                    size="small"
                    options={storeData?.teartment_priority?.data ?? []}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}_treatment_Priority_textfield`}
                        {...params}
                        variant="standard"
                        placeholder="Priority"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        id={`${parent_id}_treatment_Priority_${option.label.replaceAll(
                          " ",
                          "_"
                        )}_typography`}
                        className="pc_regular"
                        style={{
                          width: "100%",
                          fontSize: 14,
                          color: UIColor.secondaryText.color,
                        }}
                        noWrap
                      >
                        {option.label}
                      </Typography>
                    )}
                    // classes={{popper: "muiPopperDropdown"}}
                    value={treatmentProcedureEntry.priority}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "priority")
                    }
                  />
                </div>
                <div id={`${parent_id}_treatment_Nature_div`}>
                  <Autocomplete
                    id={`${parent_id}_treatment_Nature_autocomplete`}
                    className={classes.autocomplete}
                    style={{ width: "100px" }}
                    size="small"
                    options={storeData?.teartment_nature?.data ?? []}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}_treatment_Nature_textField`}
                        {...params}
                        variant="standard"
                        placeholder="Nature"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        id={`${parent_id}_treatment_Nature_${option.label.replaceAll(
                          " ",
                          "_"
                        )}_typography`}
                        className="pc_regular"
                        style={{
                          width: "100%",
                          fontSize: 14,
                          color: UIColor.secondaryText.color,
                        }}
                        noWrap
                      >
                        {option.label}
                      </Typography>
                    )}
                    // classes={{popper: "muiPopperDropdown"}}
                    value={treatmentProcedureEntry.nature}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "nature")
                    }
                  />
                </div>
                <div id={`${parent_id}_treatment_dateTime_div`}>
                  <TextField
                    id={`${parent_id}_treatment_dateTime_textfield`}
                    style={{ width: 170 }}
                    className={classes.autocomplete}
                    value={
                      treatmentProcedureEntry?.dateTime
                        ? moment(treatmentProcedureEntry?.dateTime).format(
                            "YYYY-MM-DD, hh:mm A"
                          )
                        : ""
                    }
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "dateTime")
                    }
                    autoComplete={"off"}
                    placeholder={"Start Date"}
                    onClick={(event) => handleClick(1, event)}
                    InputProps={{
                      classes: { input: classes.input },
                      endAdornment: (
                        <InputAdornment
                          id={`${parent_id}_treatment_CalendarIcon_input`}
                          position="end"
                        >
                          <Div
                            id={`${parent_id}_treatment_CalendarIcon_div`}
                            aria-describedby={id}
                            style={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(1, event)}
                          >
                            <CalendarIcon
                              id={`${parent_id}_treatment_CalendarIcon`}
                              color={UIColor.secondaryText.color}
                              width={"16"}
                              height={"16"}
                            />
                          </Div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div id={`${parent_id}_treatment_Recurrent_div`}>
                  {treatmentProcedureEntry.orderCatalog?.value && (
                    <Div
                      id={`${parent_id}_treatment_Recurrent_sub_div`}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Text
                        id={`${parent_id}_treatment_Recurrent_text`}
                        className="pc_regular"
                        inLineStyles={{
                          color: UIColor.secondaryText.color,
                          fontSize: 12,
                        }}
                      >
                        {"Recurrent"}
                      </Text>
                      <Switch
                        id={`${parent_id}_treatment_Recurrent_switch`}
                        aria-describedby={id}
                        checked={treatmentProcedureEntry.recurrent}
                        onChange={(event) => {
                          handleInputChange(
                            event.target.checked,
                            "recurrent"
                          );
                          event.target.checked && handleClick(0, event);
                        }}
                        className={classes.switch}
                      />
                    </Div>
                  )}
                </div>
              </Grid>
              <Grid item>
                <Button
                  id={`${parent_id}_treatment_Add_button`}
                  onClick={() => AddTreatmentProcedure()}
                  variant={"text"}
                  style={{
                    color: UIColor.primaryColor.color,
                    fontWeight: 600,
                    minWidth: "1%",
                    padding: "5px",
                    marginLeft: "3px",
                  }}
                  size={"small"}
                >
                  <img src={Tick} alt="Add" />
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div
            id={`${parent_id}_treatment_headerShow_div`}
            style={{
              display: "flex",
              width: "99%",
              justifyContent: "space-between",
            }}
          >
            <Text
              id={`${parent_id}_treatment_headerShow_text`}
              onClick={() =>
                handleInputChange(
                  !treatmentProcedureEntry.headerShow,
                  "headerShow"
                )
              }
              inLineStyles={{
                fontWeight: 600,
                fontSize: "14px",
                cursor: "pointer",
                color: UIColor.primaryColor.color,
                padding: "15px 0",
              }}
            >
              {"Treatment & Procedures"}
            </Text>
            {/* <div
              onClick={() =>
                handleInputChange(
                  !treatmentProcedureEntry.headerShow,
                  "headerShow"
                )
              }
              style={{ cursor: "pointer", display: "flex" }}
            >
              <img src={Addicon} alt="Add" />
            </div> */}
            <IconButton onClick={() =>
                handleInputChange(
                  !treatmentProcedureEntry.headerShow,
                  "headerShow"
                )
              } style={{ cursor: "pointer", display: "flex" }}>
                      <img src={Addicon} alt="Add" />
                    </IconButton>
          </div>
        )}{" "}
        <div id={`${parent_id}_treatment_Add_div`} style={styles.flexFlow}>
          {/* <Tooltip
            id={`${parent_id}_treatment_Insights_tooltip`}
            title={"Insights"}
          >
            <div
              id={`${parent_id}_treatment_Insights_div`}
              style={{ ...styles.infoBell, ...styles.icons }}
            >
              <InsightsIcon
                id={`${parent_id}_treatment_Insights_icon`}
                style={{ marginLeft: 5 }}
                // color={UIColor.primaryColor.color}
              />
            </div>
          </Tooltip> */}
        </div>
      </Col>
      {/* Treatment procedure list */}
      {currentTreatment.length > 0 &&
        currentTreatment.map((val, i) => {
          return (
            <Col
              id={`${parent_id}_laborder_${i}_list_col`}
              key={i}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              inLineStyles={{
                padding: "10px 8px",
                borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                overflow: "hidden",
              }}
              className={"allergyListHover_"}
            >
              <TreatmentProcedureList
                parent_id={"treatment_procedure" + i}
                treatment_order={val?.orderCatalog}
                priority={val?.priority?.display}
                nature={val?.nature?.display}
                dateTime={epochToDatetime(val?.orderDateTime)}
                ordersData={treatmentProcedureEntry?.orders}
                onEditChart={props?.onEditChart}
                val={val}
                orderline={val?._id}
                status={
                  val?.childRecurrent?.[0]?.statushistory?.length > 0
                    ? val?.childRecurrent?.[0]?.statushistory?.[
                        val?.childRecurrent?.[0]?.statushistory?.length - 1
                      ]?.old_status ?? "Draft"
                    : "Draft"
                }
                IndexValue={i}
                deleteList={deleteList}
                editFun={editFun}
                data={val}
                updatefuncy={updatefuncy}
                info={info}
                getData={getData}
                patientgetData={props?.patientgetData}
                recurrentData={val?.recurrentData}
                treatmentPlan={props?.treatmentPlan}
                saveTreatment={props?.saveTreatment}
                handleDialog={props?.handleDialog}
                treatmentChartJSON={props?.treatmentChartJSON}
                predialysisJSON={props?.predialysisJSON}
                intradialysisJSON={props?.intradialysisJSON}
                postdialysisJSON={props?.postdialysisJSON}
                dialysisData={val?.treat?.[0]}
                setTreatmentplan={props?.setTreatmentplan}
                location={props?.location}
                _id={val?.treat?.[0]?._id}
                printChart={printChart}
                treat={treat1}
                historyView={false}
              />
            </Col>
          );
        })}
        <HistoryAccordion>
        <Grid container spacing={2} style={{marginBottom:"10px"}}>
       {visibleItems.length ?(
        visibleItems.map((order,i)=>(
           
          <Col
          id={`${parent_id}_laborder_${i}_list_col`}
          key={i}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={{
            padding: "10px 8px",
            borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
            overflow: "hidden",
          }}
          className={"allergyListHover_"}
        >
          <TreatmentProcedureList
            parent_id={"treatment_procedure" + i}
            treatment_order={order?.orderCatalog}
            priority={order?.priority?.display}
            nature={order?.orderMode?.display}
            dateTime={epochToDatetime(order?.orderDateTime)}
            ordersData={treatmentProcedureEntry?.orders}
            onEditChart={props?.onEditChart}
            val={order}
            orderline={order?._id}
            status={
              order?.childRecurrent?.[0]?.statushistory?.length > 0
                ? order?.childRecurrent?.[0]?.statushistory?.[
                  order?.childRecurrent?.[0]?.statushistory?.length - 1
                  ]?.old_status ?? "Draft"
                : "Draft"
            }
            IndexValue={i}
            deleteList={deleteList}
            editFun={editFun}
            data={order}
            updatefuncy={updatefuncy}
            info={info}
            getData={getData}
            patientgetData={props?.patientgetData}
            recurrentData={order?.recurrentData}
            treatmentPlan={props?.treatmentPlan}
            saveTreatment={props?.saveTreatment}
            handleDialog={props?.handleDialog}
            treatmentChartJSON={props?.treatmentChartJSON}
            predialysisJSON={props?.predialysisJSON}
            intradialysisJSON={props?.intradialysisJSON}
            postdialysisJSON={props?.postdialysisJSON}
            dialysisData={order?.treat?.[0]}
            setTreatmentplan={props?.setTreatmentplan}
            location={props?.location}
            historyView={true}
          />
        </Col>
         ))

       ):(

        <Typography
        variant="caption"
        style={{
          width: "100%",
          textAlign: "center",
          margin: "5px",
          display: "block",
        }}
      >
        No data available
      </Typography>
       )}
       </Grid>

       {treatmentHistory.length > 2 && (
        <div style={{ display: "flex", justifyContent: "flex-end" ,marginTop:"10px"}}>
        <Pagination count={Math.ceil(treatmentHistory.length / pageSize)} page={currentPage} onChange={handlePageChange} />
        </div>)}
     
        </HistoryAccordion>
    </Row>
    {treatmentProcedureEntry.error && (
      <span
        id={`${parent_id}_laborder_filled_Recurrent_span`}
        style={styles.vitailsCarderr}
      >
        Please fill all the mandatory fields!
      </span>
    )}
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{ paper: classes.muiPopovers }}
    >
      {index === 0 && (
        <Div
          id={`${parent_id}_treatment_procedure_Recurrent_div`}
          style={{ padding: 15 }}
        >
          <Recurrent
            parent_id={"treatment_procedure"}
            recurrentData={treatmentProcedureEntry?.recurrentData}
            saveButtonAction={saveRecurrentData}
            deleteButtonAction={handleClose1}
            width={500}
          />
        </Div>
      )}
      {index === 1 && (
        // <DatePicker
        //   id={`${parent_id}_treatment_procedure_DatePicker`}
        //   timeInputLabel="Time:"
        //   dateFormat="MM/dd/yyyy h:mm aa"
        //   format="MM/dd/yyyy h:mm aa"
        //   showTimeInput
        //   // selected={this.state.dob.value}
        //   selected={new Date()}
        //   // showPopperArrow={false}
        //   // showYearDropdown
        //   minDate={new Date()}
        //   minTime={new Date()}
        //   inline
        //   onChange={(date) => {
        //     handleInputChange(
        //       moment(date).format("YYYY-MM-DD,hh:mm A"),
        //       "dateTime"
        //     );
        //   }}
        // // placeholderText="DD-MM-YYYY"
        // // maxDate={new Date()}
        // // className={"pc_regular"}
        // />
        <CustomDatePicker
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          selectedDate={
            treatmentProcedureEntry?.dateTime
              ? new Date(treatmentProcedureEntry?.dateTime)
              : null
          }
          //minDate={new Date()}
          minTime={getCurrentMinTime(
            treatmentProcedureEntry?.dateTime
              ? new Date(treatmentProcedureEntry?.dateTime)
              : null
          )}
          maxTime={setHours(setMinutes(new Date(), 59), 23)}
          handleChange={(date, e) => {
            handleInputChange(date, "dateTime");
            if (e?.target === undefined) {
              // setIndex(null)
              handleClose();
            }
          }}
        />
      )}
    </Popover>
    {alertData.open && (
      <AlertsRestriction {...alertData} handleClose={closeAlertModal} />
    )}
  </div>
  );
};

export default withAllContexts(TreatmentProcedure);
