/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import { useLocation } from "react-router-dom";
import { Div, Divider, Text, H6, Iframe } from "qdm-component-library";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Popover, Tooltip, Typography } from "@material-ui/core";
import {
  VitalIcon,
  VitalIcon1,
  VitalIcon2,
  DiagnosisIcon,
  DiagnosisIcon1,
  DiagnosisIcon2,
  CheifComplaintsIcon,
  CheifComplaintsIcon1,
  CheifComplaintsIcon2,
  LabIcon,
  LabIcon1,
  LabIcon2,
  MedicationIcon,
  RadiologyIcon,
  SurgeryIcon,
  ReferUserIcon,
  DocumentIcon,
  NotesIcon,
  OrderIcon,
  ExamineIcon,
  NotificationIcon,
  OrderSetsIcon,
  FollowupIcon1,
  FollowupIcon2,
  DocIcon1,
  DocIcon2,
  MedicationIcon1,
  MedicationIcon2,
  RadiologyIcon1,
  RadiologyIcon2,
} from "../../components";
import { UIColor } from "../../themes/theme";
//import { SurgeryIcon2 } from "../../components/svg/surgeryIcon2";awe
//import { SurgeryIcon1 } from "../../components/svg/surgeryIcon1";

import { OrderSetsIcon1 } from "../../components/svg/components/orderSet1";
import { AllergyIcon1 } from "../../components/svg/components/allergyIcon1";
import { AllergyIcon2 } from "../../components/svg/components/allergyIcon2";
import { OrderSetsIcon2 } from "../../components/svg/components/orderSet2";
import { TreatmentProcedure1 } from "../../components/svg/components/treatmentProcedure1";
import { TreatmentProcedure2 } from "../../components/svg/components/treatmentProcedure2";
import { EcgIcon } from "../../components/svg/components/ecgIcon";
import { History } from "../../components/svg/components/history";
import CloseIcon from "@material-ui/icons/Close";

const menuIcons = (permissions) => {
  let arr = [];
  if (
    permissions?.write?.indexOf("examine") > -1 &&
    permissions?.read?.indexOf("examine") > -1
  ) {
    arr.push({
      id: "stethoscope",
      icon: <ExamineIcon />,
      index: 0,
    });
  }
  if (
    permissions?.write?.indexOf("orders") > -1 &&
    permissions?.read?.indexOf("orders") > -1
  ) {
    arr.push({
      id: "file-word-o",
      icon: <OrderIcon />,
      index: 1,
    });
  }
  if (
    permissions?.write?.indexOf("documentations") > -1 &&
    permissions?.read?.indexOf("documentations") > -1
  ) {
    arr.push({
      id: "file-text-o",
      icon: <DocumentIcon />,
      index: 3,
    });
  }
  if (
    permissions?.write?.indexOf("actions") > -1 &&
    permissions?.read?.indexOf("actions") > -1
  ) {
    arr.push({
      id: "undo",
      icon: <ReferUserIcon />,
      index: 4,
    });
  }
  if(true ){//permissions?.write?.indexOf("ClinicalHistoryPatientDashboard") > -1 &&
    //permissions?.read?.indexOf("ClinicalHistoryPatientDashboard") > -1)
    arr.push({
      id: "history",
      icon: <History />,
      index: 6,
    });
  }
  return arr;
  // {
  //   id: "sticky-note-o",
  //   icon: <NotesIcon />,
  // }
};

const formType = (permissions) => {
  //debugger
  let Examine = [],
    list1 = [
      {
        icon: <VitalIcon1 width={30} height={30} />,
        iconSelected: <VitalIcon2 width={30} height={30} />,
        name: "Vital Signs",
        id: "vital_signs",
      },
      {
        icon: <AllergyIcon1 width={30} height={30} />,
        iconSelected: <AllergyIcon2 width={30} height={30} />,
        name: "Allergy",
        id: "allergy",
      },
      {
        icon: <DiagnosisIcon1 width={35} height={35} />,
        iconSelected: <DiagnosisIcon2 width={35} height={35} />,
        name: "Diagnosis",
        id: "diagnosis",
      },
      {
        icon: <CheifComplaintsIcon1 width={26} />,
        iconSelected: <CheifComplaintsIcon2 width={26} />,
        name: "Chief Complaints",
        id: "cheif_complaints",
      },
      // {
      //   icon: <EcgIcon width={26} height={40} />,
      //   iconSelected: <EcgIcon width={26} height={40} />,
      //   name: "Dialysis",
      //   id: "dialysis",
      // },
    ];
  let exam = ["vitals", "allergy", "diagnosis", "chiefComplaints", "dialysis","progressnotes"];
  exam.map((val, i) => {
    if (
      permissions?.write?.indexOf(val) > -1 &&
      permissions?.read?.indexOf(val) > -1
    ) {
      Examine.push(list1[i]);
    }
    // Examine.push(list1[i]);
  });
  let Orders = [],
    list2 = [
      {
        icon: <LabIcon1 width={26} />,
        iconSelected: <LabIcon2 width={26} />,
        name: "Lab Order",
        id: "lab_order",
      },
      {
        icon: <MedicationIcon1 width={30} />,
        iconSelected: <MedicationIcon2 width={30} color={"#0271f2"} />,
        name: "Medication",
        id: "medication",
      },
      {
        icon: <RadiologyIcon1 width={27} />,
        iconSelected: <RadiologyIcon2 width={27} color={"#0271f2"} />,
        name: "Radiology",
        id: "radiology",
      },
      {
        icon: <TreatmentProcedure1 width={30} />,
        iconSelected: <TreatmentProcedure2 width={30} color={"#0271f2"} />,
        name: "Treatment & Procedures",
        id: "treatment_procedure",
      },
      {
        icon: <OrderSetsIcon1 width={30} />,
        iconSelected: <OrderSetsIcon2 width={30} />,
        name: "Order Sets",
        id: "orders_sets",
      },
      // {
      //   icon: <SurgeryIcon1 width={30} />,
      //   iconSelected: <SurgeryIcon2 width={30} color={"#0271f2"} />,
      //   name: "Surgery",
      //   id: "surgery",
      // },
    ];
  let ord = [
    "labOrder",
    "medication",
    "radiology",
    "treatmentsProcedures",
    "orderSets",
  ];
  ord.map((val, i) => {
    if (
      permissions?.write?.indexOf(val) > -1 &&
      permissions?.read?.indexOf(val) > -1
    ) {
      Orders.push(list2[i]);
    }
  });

  let Actions = [];

  if (permissions?.write?.indexOf("followup") > -1) {
    Actions = [
      {
        icon: <FollowupIcon1 width={26} />,
        iconSelected: <FollowupIcon2 width={26} />,
        name: "Follow-Up",
        id: "follow_up",
      },
    ];
  }
  let Docc = [
    {
      icon: <DocIcon1 width={26} />,
      iconSelected: <DocIcon2 width={26} color={"#0271f2"} />,
      name: "Documentations",
      id: "docc_ui",
    },
  ];
  return {
    Examine,
    Orders,
    Actions,
    Docc,
  };
};

// const permission = {
//   "read": [
//       "examine",
//       "vitals",
//       "allergy",
//       "chiefComplaints",
//       "orders",
//       "labOrder",
//       "medication",
//       "radiology",
//       "treatmentsProcedures",
//       "orderSets",
//       "documentations",
//       "actions",
//       "dialysis",
//       "followup"
//   ],
//   "write": [
//       "examine",
//       "dialysis",
//       "vitals",
//       "allergy",
//       "chiefComplaints",
//       "orders",
//       "labOrder",
//       "medication",
//       "radiology",
//       "treatmentsProcedures",
//       "orderSets",
//       "documentations",
//       "actions",
//       "followup"
//   ]
// }
export const MenuList = (props) => {
  const permission = props?.permissions;
  const location = useLocation()
  let selectedForm = props.selectedForm;
  const [tab, setTab] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const [selectedForm, setSelectedForm] = React.useState([]);

  const handleClick = (event, index) => {
    setTab(index);
    if (getMenuIndex(index)) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setTab(null);
    }, 1000);
  };

  const formTypeSelect = (id) => {
    let selectedFormId = [...selectedForm];

    if (selectedFormId?.includes?.(id)) {
      let filterId = selectedFormId.filter((val) => val !== id);
      props.selectedFormList(filterId);
    } else {
      selectedFormId.push(id);
      //setSelectedForm(selectedFormId);
      props.selectedFormList(selectedFormId);
    }
    handleClose();
  };

  const styles = {
    menuIcon: {
      padding: "4px",
      marginBottom: "6px",
    },
    iconLayer: {
      textAlign: "center",
      margin: "14px 0px",
      cursor: "pointer",
    },
    selectedMenu: {
      color: "#0271F2",
      background: "#E0ECF9",
    },
  };
  const { parent_id } = props;

  const getMenuIndex = (index) => {
    switch (index) {
      case "1":
        return (
          <Div
            id={`${parent_id}_menulist_Examine_parent_div`}
            inLineStyles={{ padding: 12, width: "100px" }}
          >
            <div id={`${parent_id}_menulist_Examine_div`}>
              <Text
                id={`${parent_id}_menulist_Examine_text`}
                inLineStyles={{ fontSize: 14, fontWeight: 600 }}
              >
                Examine
              </Text>
            </div>
            {formType(permission)["Examine"].map((val, i) => {
              let selected =
                selectedForm.length > 0 &&
                selectedForm?.filter((select) => select === val?.id)?.[0] ===
                  val?.id
                  ? true
                  : false;
              return (
                <div
                  id={`${parent_id}_${i}_menulist_Examine_${val?.name.replaceAll(
                    " ",
                    "_"
                  )}_div`}
                  key={i}
                  style={styles.iconLayer}
                  onClick={() => formTypeSelect(val?.id)}
                >
                  {selected ? val?.iconSelected : val?.icon}
                  <H6
                    id={`${parent_id}_${i}_menulist_Examine_${val?.name.replaceAll(
                      " ",
                      "_"
                    )}_h6`}
                    inLineStyles={{
                      fontSize: 12,
                      color: selected
                        ? UIColor.secondaryButtonColor.color
                        : UIColor.primaryColor.color,
                    }}
                  >
                    {val?.name}
                  </H6>
                </div>
              );
            })}
          </Div>
        );
      case "2":
        return (
          <Div
            id={`${parent_id}_menulist_Orders_parent_div`}
            inLineStyles={{ padding: 12, width: "100px" }}
          >
            <div id={`${parent_id}_menulist_Orders_div`}>
              <Text
                id={`${parent_id}_menulist_Orders_text`}
                inLineStyles={{ fontSize: 14, fontWeight: 600 }}
              >
                Orders
              </Text>
            </div>
            {formType(permission)["Orders"].map((val, i) => {
              let selected =
                selectedForm.length > 0 &&
                selectedForm?.filter((select) => select === val?.id)?.[0] ===
                  val?.id
                  ? true
                  : false;
              return (
                <div
                  id={`${parent_id}_${i}_menulist_Orders_${val?.name.replaceAll(
                    " ",
                    "_"
                  )}_h6`}
                  key={i}
                  style={styles.iconLayer}
                  onClick={() => formTypeSelect(val?.id)}
                >
                  {selected ? val?.iconSelected : val?.icon}
                  <H6
                    id={`${parent_id}_${i}_menulist_Orders_${val?.name.replaceAll(
                      " ",
                      "_"
                    )}_h6`}
                    inLineStyles={{
                      fontSize: 12,
                      color: selected
                        ? UIColor.secondaryButtonColor.color
                        : UIColor.primaryColor.color,
                    }}
                  >
                    {val?.name}
                  </H6>
                </div>
              );
            })}
          </Div>
        );
      case "5":
        return (
          <Div
            id={`${parent_id}_menulist_Actions_parent_div`}
            inLineStyles={{ padding: 12, width: "100px" }}
          >
            <div id={`${parent_id}_menulist_Actions_div`}>
              <Text
                id={`${parent_id}_menulist_Actions_text`}
                inLineStyles={{ fontSize: 14, fontWeight: 600 }}
              >
                Actions
              </Text>
            </div>
            {formType(permission)["Actions"].map((val, i) => {
              let selected =
                selectedForm.length > 0 &&
                selectedForm?.filter((select) => select === val?.id)?.[0] ===
                  val?.id
                  ? true
                  : false;
              return (
                <div
                  id={`${parent_id}_${i}_menulist_Actions_${val?.name.replaceAll(
                    " ",
                    "_"
                  )}_h6`}
                  key={i}
                  style={styles.iconLayer}
                  onClick={() => formTypeSelect(val?.id)}
                >
                  {selected ? val?.iconSelected : val?.icon}
                  <H6
                    id={`${parent_id}_${i}_menulist_Actions_${val?.name.replaceAll(
                      " ",
                      "_"
                    )}_h6`}
                    inLineStyles={{
                      fontSize: 12,
                      color: selected
                        ? UIColor.secondaryButtonColor.color
                        : UIColor.primaryColor.color,
                    }}
                  >
                    {val?.name}
                  </H6>
                </div>
              );
            })}
          </Div>
        );
      case "4":
        return (
          <Div
            id={`${parent_id}_menulist_Docc_parent_div`}
            inLineStyles={{ padding: 12, width: "100px" }}
          >
            <div id={`${parent_id}_menulist_Docc_div`}>
              <Text
                id={`${parent_id}_menulist_Docc_text`}
                inLineStyles={{ fontSize: 14, fontWeight: 600 }}
              >
                Docc
              </Text>
            </div>
            {formType(permission)["Docc"].map((val, i) => {
              let selected =
                selectedForm.length > 0 &&
                selectedForm?.filter((select) => select === val?.id)?.[0] ===
                  val?.id
                  ? true
                  : false;
              return (
                <div
                  id={`${parent_id}_${i}_menulist_Docc_${val?.name.replaceAll(
                    " ",
                    "_"
                  )}_h6`}
                  key={i}
                  style={styles.iconLayer}
                  onClick={() => formTypeSelect(val?.id)}
                >
                  {selected ? val?.iconSelected : val?.icon}
                  <H6
                    id={`${parent_id}_${i}_menulist_Docc_${val?.name.replaceAll(
                      " ",
                      "_"
                    )}_h6`}
                    inLineStyles={{
                      fontSize: 12,
                      color: selected
                        ? UIColor.secondaryButtonColor.color
                        : UIColor.primaryColor.color,
                      wordBreak: "break-all",
                    }}
                  >
                    {val?.name}
                  </H6>
                </div>
              );
            })}
          </Div>
        );
      case "7":
        return (
          <Div
            id={`${parent_id}_menulist_Examine_parent_div`}
            inLineStyles={{ padding: 12, width: "100px" }}
          >
          </Div>
        );

      default:
        break;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getTootTipName = (val) => {
    if (val?.id === "stethoscope") {
      return "Examine";
    }
    if (val?.id === "file-word-o") {
      return "Orders";
    }
    if (val?.id === "file-text-o") {
      return "Docc";
    }
    if (val?.id === "undo") {
      return "Actions";
    }
    if (val?.id === "history"){
      return "History"
    }
  };
  const patientId = location?.state?.patientId
  const iframeUrl = `${process.env.REACT_APP_PATIENT_HISTORY_DASHBOARD}=${patientId}`;
  return (
    <>
      <div id={`${parent_id}_menulist_NotificationIcon_parent_div`}>
        <Div
          id={`${parent_id}_menulist_NotificationIcon_sub_div`}
          inLineStyles={{ padding: "4px" }}
          className={"onHoverMenu"}
        >
          <Div inLineStyles={{ padding: "6px", display: "flex" }}>
            <Tooltip title={"Notification"} placement="right">
              <div>
                <NotificationIcon
                  id={`${parent_id}_menulist_NotificationIcon_svg`}
                />
              </div>
            </Tooltip>
          </Div>
        </Div>
        <Div id={`${parent_id}_menulist_NotificationIcon_divider_div`}>
          <Divider
            id={`${parent_id}_menulist_NotificationIcon_divider`}
            variant={"fullWidth"}
            orientation={"horizontal"}
          />
        </Div>
        {menuIcons(permission).map((val, index) => {
          let value = val.index + 1;
          return (
            <Div
              id={`${parent_id}_${index}_menulist_NotificationIcon_div`}
              inLineStyles={styles.menuIcon}
              key={index}
              className={
                `${value}` === tab ? "onHoverMenu selectedMenu" : "onHoverMenu"
              }
              onClick={(e) => handleClick(e, `${value}`)}
            >
              <Tooltip title={getTootTipName(val)} placement="right">
                <div>
                  <Div
                    id={`${parent_id}_${index}_menulist_NotificationIcon_div`}
                    inLineStyles={{ padding: "6px", display: "flex" }}
                  >
                    {val.icon}
                  </Div>
                </div>
              </Tooltip>
            </Div>
          );
        })}
      </div>

      {/* Menus Options list */}
      {tab === "7" ? (
        <>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}   fullScreen 
    style={{ width: '100%', height: '78vh' , paddingLeft:"10vh", marginTop:"20vh", paddingRight:"10px"}} >
        <Grid container justifyContent="space-between" alignItems="center" style={{padding:"5px 25px"}}>
          <Grid item>
            <Typography component="h2" variant="h6" style={{fontWeight:"600",color:"#01205C"}} >Patient History Dashboard</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Iframe src={iframeUrl} allowfullscreen/>
      </Dialog>
        </>
      ) : (getMenuIndex(tab) && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 95, left: 56 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {getMenuIndex(tab)}
        </Popover>
      ))}
    </>
  );
};

MenuList.defaultProps = {
  selectedFormList: () => {},
};
