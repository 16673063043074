import jwtDecode from "jwt-decode";

const casbinjs = require("casbin.js");

const json = {
  AVCLanding: {
    id: "3a8f524a3-a53b-4709-ad34-fe62e35ae70b",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/avc_landing",
  },
  AVCMeeting: {
    id: "d21fb52b-229a-4fe7-a4e3-9f32bafe871e",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/avc_meeting",
  },
  AVCSupport: {
    id: "3a87e75a-279f-46c9-ae30-b302bbfdbcc5",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/avc_support",
  },
  OrganizationConfig: {
    component: {
      careType: {
        id: "9d699c4c-0f1e-4c4c-b29b-99425139de1f",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      healthCareService: {
        id: "389da49b-be1d-44fb-b9b0-4adabfaa121d",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      levelofCare: {
        id: "c3372f92-70ba-4d62-87a5-b8fff0eddbdb",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
    },
    id: "1415e0b8-8e81-440c-a2b1-5fed747a6048",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/organizationConfig",
  },
  admindashboard: {
    id: "3e3f996a-c9b6-480b-bf8d-dd3b078d0b1a",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/dashboard",
  },
  administrations: {
    component: {
      generalMaster: {
        id: "ffc4adda-0992-4f4e-8880-8c3b6b05ace5",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      geographicHierarchy: {
        id: "6d825189-bb8c-4119-9a67-b0ca86636655",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      geographicMaster: {
        id: "bb5cd1b2-fddd-463d-bb31-ba9da9ac8d56",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      location: {
        id: "8073d034-97e2-4260-953e-579aba4ad3af",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      numberSeries: {
        id: "4d9ea622-1fe9-43a9-8081-459296622662",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      patientDemograpics: {
        id: "c9efaf34-7576-42e4-bac4-82510184d2b0",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      pendingRescheduleList: {
        id: "aab61dcf-43f8-40d2-b17c-3852b77b4de7",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
    },
    id: "ffe489e8-9854-4b01-ac7c-75ebf30e1860",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/administration",
  },
  "appointmentconfirmation ": {
    id: "e61e9d08-2212-492f-935b-5b28cf0ccb36",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/appointment_confirmation",
  },
  appointmentlisting: {
    id: "7c1f4efc-7c37-4592-9e51-bdd1c999aca1",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/patient_dashboard",
  },
  appointmentstats: {
    id: "5639ecd0-4cae-4bd2-a22a-2bb193c2c2f4",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/appointmentStats",
  },
  availabilityManagement: {
    component: {
      appointmentTypes: {
        id: "11d7e2ad-d57d-42c1-8b78-5d57ea606e2f",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      holiday: {
        id: "b73c557b-dc77-4ac9-b405-f6ef79fed23e",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      resourcebyAppointmentTypes: {
        id: "5c09394f-50bf-4613-b568-018f51de8819",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      scheduleManagement: {
        id: "5c469fdd-4aaa-46bc-a783-82fc99a58b0f",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      timetable: {
        id: "e01bd855-2be6-496a-b9d4-c1bffd08ed13",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
    },
    id: "ffd1152d-2d1f-4c7e-baec-47315421dc37",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/availabilitymanagement",
  },
  avccall: {
    id: "79b07714-44b2-4446-8356-2753873362eb",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/avc_meeting",
  },
  avclandingpage: {
    id: "84fae12d-b75c-4932-96e1-9caccb79f294",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/avc_landing",
  },
  avclogin: {
    id: "270515bf-b937-4e38-9474-d539912f5a53",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/avc_login",
  },
  sideMenu: {
    id: "718a9537-2fa9-4a8c-a365-4c95bc8b5bc7",
    permission: {
      read: true,
      write: false,
      update: false,
      delete: false,
    },
    component: {
      sideMenuDashboard: {
        id: "6c113535-322d-4082-bbad-dfa0cea40502",
        permission: {
          read: false,
          write: true,
          update: false,
          delete: false,
        },
        actionable: "Yes",
      },
      worklist: {
        id: "6c113535-322d-4082-bbad-dfa0cea40502",
        permission: {
          read: false,
          write: true,
          update: false,
          delete: false,
        },
        actionable: "Yes",
      },
      mainMenu: {
        id: "6c113535-322d-4082-bbad-dfa0cea40502",
        permission: {
          read: false,
          write: true,
          update: false,
          delete: false,
        },
        actionable: "Yes",
      },
      reports: {
        id: "6c113535-322d-4082-bbad-dfa0cea40502",
        permission: {
          read: false,
          write: true,
          update: false,
          delete: false,
        },
        actionable: "Yes",
      },
    },
  },
  avsupportpage: {
    id: "1b4ae6b6-db8b-4e29-89ce-0690993bdc79",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/avc_support",
  },
  cancelvisit: {
    id: "dc3aba36-5968-4f81-b7b8-8ee54a1b5b9c",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/cancel_visit",
  },
  consultMaster: {
    component: {
      DrugMaster: {
        id: "5dc9d277-4210-45bb-99b1-315c2df53524",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      OrderMaster: {
        id: "5eb798fd-5159-4165-9f8f-4a6ee6c1686f",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      ageRangeMaster: {
        id: "06170be2-aef8-4e5d-bccc-c680b08f1fd1",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      vitalsMaster: {
        id: "1b78f9d9-8f38-4751-b524-06f57abff45e",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
    },
    id: "513e8e12-57bf-4918-a735-6d021951b341",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/consultMaster",
  },
  "currentvisitstats ": {
    id: "ea974b2f-b032-473e-9b02-a31956afe965",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/currentVisitStats",
  },
  dashboard: {
    component: {
      HighPriorityPatientsD: {
        id: "e19cc4ad-837b-43eb-b169-f237c5db11bf",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      HighPriorityPatientsF: {
        id: "6cf6b4ef-8e94-40bf-8f7a-9e24d02f2a2b",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      HighPriorityPatientsN: {
        id: "77d515d4-6b22-4963-8560-9044cec33e87",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      PatientStatusN: {
        id: "fc290ad4-7c25-4f3b-81e4-f6d77af9bfad",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      TodayScheduleD: {
        id: "7d1acd29-047b-4737-985d-d645111e848b",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      appointmentQueueDataD: {
        actionable: "Yes",
        id: "9029754c-9a0c-4c94-a8dd-7feff9937f32",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      appointmentQueueDataF: {
        actionable: "Yes",
        id: "bb0ec369-d992-4930-ad79-6065ea3bf2d5",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      appointmentQueueDataN: {
        actionable: "Yes",
        id: "88a9bdb8-9e93-4697-9825-36e74025e01f",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      assignD: {
        id: "afa9e3ef-9258-4e1b-86e2-16a8f491c80f",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      assignReassignPractitionerD: {
        id: "afa9e3ef-9258-4e1b-86e2-16a8f491c80f",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      averagewaitingtimeF: {
        actionable: "No",
        id: "6ff0b49f-39e0-4621-8f90-d0cb4be2d5ce",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      averagewaitingtimeN: {
        id: "d09d618d-a0fc-4ef0-92d3-da819cf40f71",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      billingStatus: {
        actionable: "Yes",
        id: "5b4cea7f-9f99-4eba-8923-ef596676672d",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      cancelVisit: {
        id: "18046d6b-f4e3-460a-aada-4c0d2bbea5c6",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      currentVisitStatsD: {
        actionable: "No",
        id: "fac569ef-ab31-4655-b00b-a11f2cb49016",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      currentVisitStatsF: {
        actionable: "Yes",
        id: "1367dbf9-96d2-4231-8b74-4fbf62c1f739",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      currentVisitStatsN: {
        actionable: "No",
        id: "6ff0b49f-39e0-4621-8f90-d0cb4be2d5ce",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      financialClass: {
        actionable: "No",
        id: "fac569ef-ab31-4655-b00b-a11f2cb49016",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      markHighPriority: {
        id: "e759e03c-3150-45ac-ae73-ec94cb96e724",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      modifyVisit: {
        id: "50f2bf70-8ba7-4b1a-8208-66559fb23f28",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      newPatient: {
        id: "4c52d3e1-75cc-41eb-ad8b-7ffe8dc10a82",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      patientwaitingtimeD: {
        id: "0ada9fd2-9d28-4e2c-9af3-23fa0b78b0fd",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      patientwaitingtimeN: {
        id: "4993cf50-9e4f-4e2e-884b-77d20fd36add",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      pendingTasksD: {
        actionable: "Yes",
        id: "292ccbfd-d288-4bab-8dd1-ee88d83a593a",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      pendingTasksF: {
        actionable: "Yes",
        id: "1080ce49-931c-4dd5-8a23-75d1f99e93a8",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      pendingTasksN: {
        actionable: "Yes",
        id: "367cc56f-5175-43f6-886d-8aaa76c80205",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      practitionerD: {
        id: "561a1e3f-a249-480b-8ee6-89207f1bf35c",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      queue: {
        actionable: "Yes",
        id: "367cc56f-5175-43f6-886d-8aaa76c80205",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      reassignD: {
        id: "630ec77f-25ae-4150-897d-2982f1248863",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      searchPatient: {
        actionable: "Yes",
        id: "292ccbfd-d288-4bab-8dd1-ee88d83a593a",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      statsWidgetD: {
        actionable: "Yes",
        id: "1367dbf9-96d2-4231-8b74-4fbf62c1f739",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      statsWidgetF: {
        actionable: "Yes",
        id: "04116f9d-413a-4964-a796-c8e75fd963e5",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      statsWidgetN: {
        actionable: "Yes",
        id: "04116f9d-413a-4964-a796-c8e75fd963e5",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
    },
    id: "a914a69f-b1ad-4e0e-a045-97f288d7b9ab",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/dashboard",
  },
  doctorlisting: {
    id: "6f801bff-7032-4eae-86ac-d700319cf614",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/appointment_booking",
  },
  doctorsearchpage: {
    id: "30965103-c4e1-493b-b87c-d8887461ae5b",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/doctor_consultation",
  },
  financialRegistration: {
    id: "83b30733-6a1c-4aa0-9b2a-43f3823b0e45",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/financial_registration_form",
  },
  guestuserconfirmation: {
    id: "c199a54a-f05d-4c14-af87-8545046c9ed1",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/appointment_booking",
  },
  modifyvisit: {
    id: "fe0186aa-2c62-4c55-bd85-e280c95596d1",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/modify_visit",
  },
  myappointments: {
    id: "824fa38a-79cd-4f44-b5a8-b59931dd9cbb",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/profile",
  },
  organizationSetup: {
    id: "4dc320e0-bdd6-4196-b7c0-1bffbc3cd016",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/organizationSetup",
  },
  organizations: {
    id: "98994b3c-7a78-4a10-88c6-d1fb72a5d4d8",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/organization",
  },
  patientChart: {
    component: {
      actions: {
        component: {
          followup: {
            id: "9a428982-cc5f-4487-8dc5-f49a44042e91",
            permission: {
              delete: false,
              read: false,
              update: false,
              write: false,
            },
          },
          "signOffdoctor ": {
            id: "e817f8df-4973-4842-999e-97b603b12469",
            permission: {
              delete: false,
              read: false,
              update: false,
              write: false,
            },
            routes: "/doctor_signoff",
          },
          "signOffnurse ": {
            id: "8ec0d8c1-5d98-4fbb-aec1-b4efab297cdf",
            permission: {
              delete: false,
              read: false,
              update: false,
              write: false,
            },
            routes: "/nurse_signoff",
          },
        },
        id: "dcb05b16-0392-4da3-8b57-02a92fde493c",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      documentations: {
        id: "bf4b5688-9ae7-430a-8832-420ff0f1aafc",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      examine: {
        component: {
          allergy: {
            actionable: "Yes",
            id: "e55798fa-f53f-4786-bc17-a9e6acc36769",
            permission: {
              delete: true,
              read: true,
              update: true,
              write: true,
            },
          },
          dialysis: {
            actionable: "Yes",
            id: "8a541edb-32ef-4bd2-a13f-465afacf6798",
            permission: {
              delete: true,
              read: true,
              update: true,
              write: true,
            },
          },
          chiefComplaints: {
            id: "80a48c61-88f5-4117-a6f7-041e8f81de43",
            permission: {
              delete: true,
              read: true,
              update: true,
              write: true,
            },
          },
          diagnosis: {
            actionable: "Yes",
            id: "ae4507bd-edd2-4ae5-80a0-ccbe338eb57c",
            permission: {
              delete: true,
              read: true,
              update: true,
              write: true,
            },
          },
          vitals: {
            id: "a7ed1a67-e1a7-4203-94a4-939f729b0d25",
            permission: {
              delete: true,
              read: true,
              update: true,
              write: true,
            },
          },
        },
        id: "3d265f82-ad86-4c77-9da3-6b2ee32abd32",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      orders: {
        actionable: "Yes",
        component: {
          labOrder: {
            id: "0e6e257e-0a43-4aa7-bd93-fd1bea998957",
            permission: {
              delete: false,
              read: false,
              update: false,
              write: false,
            },
          },
          medication: {
            actionable: "Yes",
            id: "61599d00-2198-414f-8edb-6c56d5858fe0",
            permission: {
              delete: false,
              read: false,
              update: false,
              write: false,
            },
          },
          radiology: {
            id: "0b9c7a0d-554d-4dbf-8d38-2b23e586a9cf",
            permission: {
              delete: false,
              read: false,
              update: false,
              write: false,
            },
          },
          treatmentsProcedures: {
            actionable: "Yes",
            id: "a9e3982d-91be-425f-b01f-5c42a944bc90",
            permission: {
              delete: false,
              read: false,
              update: false,
              write: false,
            },
          },
        },
        id: "88f479af-8d4f-470a-bf59-1679dc919055",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
    },
    id: "77981282-9ad8-41d5-9feb-2dcedb1a3d66",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/patient_detail",
  },
  patient_dashboard: {
    component: {
      bookAppoinment: {
        id: "8370469c-76c5-4167-a9dd-9925652b9b0e",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      cancleAppoinment: {
        id: "a70e9ae3-910f-4ee6-8825-7ad739531746",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      followUpReminder: {
        actionable: "Yes",
        id: "50bd75cd-6a15-49e7-a497-e4dbeb63f0e9",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      modifyAppoinment: {
        id: "1bf1dc7b-bb22-4dad-a3f9-79b96ccbb7da",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      modifyPatient: {
        actionable: "Yes",
        id: "6bcf5cc0-9bc5-4be3-97d1-dc4db0f61625",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      upcoming: {
        actionable: "Yes",
        id: "115f27ae-8417-4c45-abfb-bc94078f6f95",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      visits: {
        id: "b2c1be03-174d-4a97-a5d2-b4b886bc41eb",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
    },
    id: "462d74e3-151b-4733-8e8e-581f55c5ced7",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/patient_dashboard",
  },
  patientrecord: {
    component: {
      modifyPatient: {
        actionable: "Yes",
        id: "2e3e5c2d-149a-41ec-8485-8f4d712b2d18",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      newPatient: {
        actionable: "Yes",
        id: "3d265f82-ad86-4c77-9da3-6b2ee32abd32",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      newPatientRegistration1: {
        actionable: "Yes",
        id: "3d265f82-ad86-4c77-9da3-6b2ee32abd32",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
    },
    id: "988f02f7-845d-4225-b8d6-e4faa29e7d22",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/patient_record",
  },
  pendingtasks: {
    id: "aab9d443-577e-41be-a974-e97f4870b2a9",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/pendingTasks",
  },
  practitionerMaster: {
    component: {
      practitioner: {
        id: "2cb93a3f-f858-4934-a657-50798e49e3d6",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      practitionerRole: {
        id: "fe51f40b-50ee-42e0-8c43-5e5e30f6b76a",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      practitionerType: {
        id: "382917ad-f35e-4a52-9c38-9993e553242d",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      qualification: {
        id: "fbff32c5-5887-460f-a72a-b8c647e7d88d",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
    },
    id: "6985cb38-85a2-4c6a-bd9a-5f7377bbc491",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/practitionermaster",
  },
  queueSideNav: {
    component: {
      appointmentQueueDataD: {
        actionable: "Yes",
        id: "672a84c3-01d3-4beb-b2bd-5cf2e503aae9",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      appointmentQueueDataF: {
        actionable: "Yes",
        id: "d2e47e62-bff6-45c4-b73b-e3a772a31b4a",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      appointmentQueueDataN: {
        actionable: "Yes",
        id: "d6266200-71ee-44b1-a8cf-e90cea9530af",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      assignQ: {
        id: "7507d94e-80cf-48f3-a4d1-ca6df99170fc",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      assignReassignPractitionerQ: {
        id: "7507d94e-80cf-48f3-a4d1-ca6df99170fc",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      cancelVisit: {
        actionable: "Yes",
        id: "ce00a5cd-512a-4d1b-b1ef-b35cb5776198",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      markHighPriority: {
        id: "66cdbd21-11b5-4455-8a36-d09a3c0eb7d5",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      markHighPriorityQ: {
        id: "66cdbd21-11b5-4455-8a36-d09a3c0eb7d5",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      modifyVisit: {
        id: "07ee8501-2840-4635-bc43-8c7eab2e307a",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
      practitionerQ: {
        id: "5ff7027a-8994-4fc6-ae77-17c1c86b62d3",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      reassignQ: {
        id: "7a6d79c7-7a68-4dac-8ec9-5739568d7565",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      viewVisit: {
        id: "721db38f-6724-47e2-a10b-840d86f9a20e",
        permission: {
          delete: true,
          read: true,
          update: true,
          write: true,
        },
      },
    },
    id: "8aab994d-0061-4172-895b-925c62174937",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
  },
  referralregistration: {
    id: "0ab5137e-0292-4628-b8b0-d9e8e008e673",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/referral_registration_form",
  },
  registerNewPatient: {
    id: "10ba051c-e87b-4e84-abf7-4b6440379cff",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/assemble-patient-form",
  },
  ruleBuilder: {
    id: "22395d89-3e35-4ddc-b447-2c2abe49e480",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
  },
  userManagement: {
    component: {
      permissionList: {
        id: "46630609-425c-4d8c-9f8a-c485cb091adc",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      person: {
        id: "f8073255-0cc6-42da-8ca9-116e076247a2",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      repository: {
        id: "b8e38561-c639-437d-a1b3-9e1b0adb9605",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      roles: {
        id: "a3aff367-8da8-478b-924a-8a8a6b93c9f6",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
      users: {
        id: "d974f9d0-a569-43b0-90c9-1316a67048bc",
        permission: {
          delete: false,
          read: false,
          update: false,
          write: false,
        },
      },
    },
    id: "66984e92-ced2-47df-8700-b15d1cdbe9e4",
    permission: {
      delete: false,
      read: false,
      update: false,
      write: false,
    },
    routes: "/repository",
  },
  "visitregistration ": {
    id: "8fb53962-91b9-44fc-b93d-2c8ce00c4919",
    permission: {
      delete: true,
      read: true,
      update: true,
      write: true,
    },
    routes: "/visit_registration_form",
  },
};

export const permissionJson = (funName = []) => {
  try {
    let permissionJson = localStorage.getItem("PermissionData");
    const decodedPermissionJson = jwtDecode(permissionJson);
    //let decodedPJ = atob(permissionJson);
    //permissionJson = JSON.parse(decodedPermissionJson.payload);

    let returnJson = {
      read: [],
      write: [],
    };
    funName.map((sname) => {
      let data = decodedPermissionJson[sname];
      if (!data) {
        return false;
      }
      Object.keys(data.component).map((val) => {
        if (data.component[val].permission.read) {
          returnJson.read.push(val);
        }
        if (
          data.component[val].permission.write ||
          data.component[val].permission.update ||
          data.component[val].permission.delete
        ) {
          returnJson.write.push(val);
        }
        let compt = data.component[val].component;
        let json = getAllComponentData(compt);
        returnJson = {
          read: [...returnJson.read, ...json.read],
          write: [...returnJson.write, ...json.write],
        };
      });
    });

    return returnJson;
  } catch (error) {
    console.error("Error permissionJson:", error);
    return null;
  }
};

const casbinList = {
  appointmentconfirmation: {
    read: [],
    write: [
      "downloadAppointment",
      "downloadInvoice",
      "print",
      "share",
      "callCenter",
    ],
  },
  patient_dashboard: {
    read: ["upcoming", "followUpReminder", "visits", "bookAppointment"],
    write: [
      "reffralRegistration",
      "bookAppoinment",
      "cancleAppoinment",
      "modifyAppoinment",
      "modifyPatient",
      "patientBannerStatus",
      "patientBannerEditIcon",
    ],
  },
  person_dashboard: {
    read: ["upcoming", "followUpReminder", "visits", "bookAppointment"],
    write: [
      "reffralRegistration",
      "bookAppoinment",
      "cancleAppoinment",
      "modifyAppoinment",
      "modifyPatient",
      "patientBannerStatus",
      "patientBannerEditIcon",
    ],
  },
  dashboard: {
    read: [
      "statsWidget",
      "currentStats",
      "queue",
      "doctorQueue",
      "nurseQueue",
      "frontDeskQueue",
      "pendingTasks",
      "averageWaitingTime",
      "financialClass",
      "billingStatus",
      "searchPatient",
      "listingScreenF",
      "listingScreenN",
      "listingScreenD",
      "cashierIcon",
    ],
    write: [
      "appointmentQueueDataN",
      "appointmentQueueDataD",
      "appointmentQueueDataF",
      "newPatient",
      "searchPatient",
      "queue",
      "doctorQueue",
      "nurseQueue",
      "frontDeskQueue",
      "markHighPriority",
      "statsWidgetN",
      "statsWidgetF",
      "currentVisitStatsN",
      "currentVisitStatsD",
      "currentVisitStatsF",
      "pendingTasksN",
      "pendingTasksD",
      "pendingTasksF",
      "averagewaitingtimeC",
      "averagewaitingtimeF",
      "HighPriorityPatientsF",
      "HighPriorityPatientsD",
      "HighPriorityPatientsN",
      "PatientStatusN",
      "patientwaitingtimeN",
      "patientwaitingtimeD",
      "TodayScheduleD",
      "financialClass",
      "billingStatus",
      "statsWidgetD",
      "assignReassignPractitionerD",
      "bookAppointmentIcon",
      "listingScreenF",
      "listingScreenN",
      "listingScreenD",
      "cashierIcon",
    ],
  },
  viewReport: {
    read: ["viewReportFD"],
    write: ["viewReportFD"],
  },
  queueSideNav: {
    read: [],
    write: [
      "appointmentQueueDataN",
      "appointmentQueueDataD",
      "appointmentQueueDataF",
      "modifyVisit",
      "cancelVisit",
      "viewVisit",
      "markHighPriorityQ",
      "assignReassignPractitionerQ",
      "closeConsultationN",
      "closeConsultationD",
    ],
  },
  sideMenu: {
    read: [],
    write: [
      "sideMenuDashboard",
      "worklist",
      "mainMenu",
      "reports",
      "assignPractitionerW",
      "reassignPractitionerW",
      "closeConsultationW",
      "dayEndProcessW",
      "billingCashier",
      "bulkbooking",
      "pricedefinition",
      "HqDashboard",
      "picdashboard",
      "nursedashboard",
      "pbnursedashboard",
      "nephrologistdashboard",
      "Bill Cancel Report",
      "Receipt Cancel Report",
      "Cash Collection Summary report",
      "PIC Review Report",
      "BillRegister",
      "NursingProcedureSummary",
      "Patient Review Chart Report",
      "Claim Summary Report",
      "Patient Report",
      "Monthwise Attendance Report",
      "PatientattendanceSummary",
      "Obervation Report",
      "EPO Consumption Report",
      "Panel Report",
      "Cancelled and No Show Report",
      "HqDashboardv2.0",
    ],
  },
  patientChart: {
    read: [
      "examine",
      "vitals",
      "allergy",
      "chiefComplaints",
      "diagnosis",
      "orders",
      "labOrder",
      "medication",
      "radiology",
      "treatmentsProcedures",
      "surgery",
      "orderSets",
      "documentations",
      "actions",
      "followup",
      "dialysis",
    ],
    write: [
      "examine",
      "dialysis",
      "vitals",
      "allergy",
      "chiefComplaints",
      "diagnosis",
      "orders",
      "labOrder",
      "medication",
      "radiology",
      "treatmentsProcedures",
      "surgery",
      "orderSets",
      "documentations",
      "actions",
      "followup",
    ],
  },
  bookAppointment: {
    read: [],
    write: [
      "additionalServices",
      "reportAndAdditionalServices",
      "walkIn",
      "appointmentBookingConfirmation",
    ],
  },
};

export const checkWithCasbin = async (funName = []) => {
  let permission = permissionJson(funName);
  const authorizer = new casbinjs.Authorizer("manual", permission);
  authorizer.setPermission(permission);
  let data = await Promise.all(
    funName.map(async (sname) => {
      return await new Promise(async (resolve, reject) => {
        let list = casbinList[sname];
        let readList = await Promise.all(
          list.read.map(async (val) => {
            return await new Promise(async (resolve, reject) => {
              let isread = await authorizer.can("read", val);
              resolve({ [val]: isread });
            });
          })
        );
        let readData = [];
        readList.map((val) => {
          let key = Object.keys(val)[0];
          if (val[key]) {
            readData.push(key);
          }
        });

        let writeList = await Promise.all(
          list.write.map(async (val) => {
            return await new Promise(async (resolve, reject) => {
              let isread = await authorizer.can("write", val);
              resolve({ [val]: isread });
            });
          })
        );
        let writeData = [];
        writeList.map((val) => {
          let key = Object.keys(val)[0];
          if (val[key]) {
            writeData.push(key);
          }
        });
        resolve({
          read: readData,
          write: writeData,
        });
      });
    })
  );
  let read = [],
    write = [];
  data.map((val) => {
    read = [...read, ...val.read];
    write = [...write, ...val.write];
  });
  return {
    read: read,
    write: write,
  };
};

const getAllComponentData = (compt) => {
  let returnJson = {
    read: [],
    write: [],
  };
  if (compt) {
    Object.keys(compt).map((val) => {
      if (compt[val].permission.read) {
        returnJson.read.push(val);
      }
      if (
        compt[val].permission.write ||
        compt[val].permission.update ||
        compt[val].permission.delete
      ) {
        returnJson.write.push(val);
      }
      let compt1 = compt[val].component;
      if (compt1) {
        let json = getAllComponentData(compt1);
        returnJson = {
          read: [...returnJson.read, ...json.read],
          write: [...returnJson.write, ...json.write],
        };
      }
    });
  }

  return returnJson;
};
